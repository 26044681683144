// accordion changes
.accordion-section {
  margin-bottom: 10em;
  .MuiPaper-root {
    margin: 30px 0;
    background: #FFFFFF;
    border: 1px solid #EAE9F2;
    border-radius: 10px;
    .MuiButtonBase-root {
      background: #FFFFFF;
      border-bottom: none;
      border-radius: 10px;
    }
    &:before {
      background-color: #FFFFFF;
    }
  }
}
.accordian-radio-container {
  padding: 15px 0;
}
.accordion-Select-option {
  background-color: #EFF0F6;
  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 0 10px;
      font-size: var(--font-size-12);
    }
  }
}
.MuiAccordionSummary-root {
  &:last-child {
    margin-top: 10em;
  }
}
.filter-reset {
  border-bottom: 1px solid var(--gray4);
  .MuiAccordion-root {
    background-color: transparent !important;
    box-shadow: none !important;
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 0px !important;
    }
  }
}
.filter-company {
  .MuiAccordion-root {
    background-color: transparent !important;
    box-shadow: none !important;
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 0px !important;
    }
  }
}
.accordian-profile-alert {
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 45px !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 !important;
  }
}
.shift-filter-align{
  .MuiAccordionSummary-content{
    margin-bottom: 10px !important;
  }
}

// MuiPaper-root changes
.MuiPaper-root.MuiPopover-paper {
  background: #FFFFFF !important;
  box-sizing: border-box !important;
  box-shadow: 0px 12px 20px rgba(216, 216, 216, 0.5) !important;
  border-radius: 6px !important;
}
#demo-positioned-menu {
  .MuiPaper-root {
    background-color: #FFFFFF !important;
    border-radius: 0px !important;
    color: #686871 !important;
  }
}
.menu-list {
  position: fixed;
  top: 76px;
  left: 0px;
  width: 55px;
  /*background: #FFFFFF !important;*/
  box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
  height: 100vh;
  // opacity:1;
  z-index: 99;
  background: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  // background:#7F94B0 !important;
  // background: rgba(0, 41, 98, 1);
  ul,
  .sidebar-list {
    padding-left: 0px !important;
    margin-top: 5px !important;
    padding-top: 0px !important;
    .active-list {
      /*  background: rgba(68, 90, 199, 0.1);*/
      background: rgba(68, 91, 199, 0.2);
      // a label{
      //     color:#445AC7;
      // }
    }
    li {
      list-style: none;
      margin-top: 15px;
      padding: 18px 10px;
      text-align: center !important;
      line-height: 15px !important;
    }
    a {
      text-decoration: none;
      font-size: var(--font-size-14);
      label,
      #demo-positioned-button {
        display: block !important;
        cursor: pointer;
        margin-top: 2px;
        font-size: var(--font-size-12) !important;
      }
    }
    .collapse-label {
      left: 52px;
      .MuiAccordionSummary-content {
        margin: 0px !important;
      }
      svg {
        font-size: 25px !important;
        color: #696871;
        border-radius: 50%;
        border: 1px solid rgba(105, 104, 113, 0.4);
      }
    }
  }
  .menu-details {
    background: #ffffff !important;
    box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08) !important;
  }
  .collapse-navbar {
    background: transparent !important;
    color: #696871;
    top: 68px;
    right: auto !important;
    left: 0px !important;
    width: 40px;
    box-shadow: none !important;
    .MuiToolbar-root {
      padding-left: 0px !important;
    }
  }
  .MuiPaper-root {
    top: 76px !important;
    width: 130px !important;
    border-radius: 0px !important;
    z-index: 999999 !important;
    background: #ffffff;
    border: 1px solid #e4e4e7;
    box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
    // background:#7F94B0;
    // background: rgba(0, 41, 98, 1);
  }
  .menu-sideIcon {
    position: fixed;
    left: 43px;
    font-size: 23px !important;
    background: #ffffff !important;
    box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
  }
}
.analytics-report {
  .MuiPaper-root {
    left: -20px !important;
    .MuiList-root {
      position: fixed;
      left: 129px;
      top: 76px;
      background: #ffffff;
      box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
      // padding: 25px !important;
      height: 100vh;
      width: 210px !important;
    }
  }
}
.analytic-settings-report {
  .MuiPaper-root {
    left: -20px !important;
    .MuiList-root {
      position: fixed;
      left: 56px;
      top: 76px;
      background: #ffffff;
      box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
      // padding: 25px !important;
      height: 100vh;
      width: 210px !important;
    }
  }
}
#profile1 {
  .MuiPaper-root {
    left: 160px !important;
  }
}
#profile2 {
  .MuiPaper-root {
    left: 60px !important;
  }
}
.image-container{
  .MuiPaper-root{
    box-shadow: none !important;
    border: 1px solid #EAE9F2;
    width: 360px;
    &:hover {
      border: 0.5px solid var(--list-hover-color);
      box-shadow: 0px 18px 14px -20px #00005e !important;
      transition: all .3s ease-in-out;
      animation: pulse 1.5s;
    }
  }
}
.header-menu{
  .MuiPaper-root.MuiPopover-paper {
    .MuiMenu-list .MuiButtonBase-root{
      display: flex !important;
      justify-content: flex-start !important;
    }
  }
}
.sidenav-collapse {
  .MuiPaper-root {
    overflow-y: initial !important;
  }
}
.MuiPaper-root {
  &.MuiPaper-elevation {
    &.MuiDialog-paper{
      min-width: 400px;
    }
  }
}
.MuiPaper-root{
  .MuiDialogContent-root{
    // padding: 0px 20px 20px 20px !important;
  }
}
.MuiPaper-root {
  // box-shadow: 0 4px 4px rgba(234, 233, 242, 0.4) !important;
  // border-radius: 8px !important;
  .MuiMenu-list {
    .MuiMenuItem-root {
      font-family: var(--font-family) !important;
      font-size: var(--font-size-14);
      color: var(--gray9);
      background-color: transparent;
      // cursor: context-menu;
      span {
        svg {
          font-size: var(--font-size-16);
        }
      }
    }
  }
}
.MuiPopperUnstyled-root {
  z-index: 9999;
}
.interview-tile{
  .MuiPaper-root{
    box-shadow: none !important;
  }
}
.promoter-shadow {
  .MuiPaper-root.MuiPopover-paper {
    box-shadow: none !important;
  }
}
.joblists-score-align {
  .MuiPaper-root.MuiPopover-paper {
    box-shadow: none !important;
  }
}
.onboarding-fromJob {
  .MuiPaper-root {
    box-shadow: none !important;
  }
}
#simple-popover-list {
  .MuiPaper-root {
    &.MuiPopover-paper {
      filter: drop-shadow(0px 0px 0px rgba(234, 233, 242, 0.4)) !important;
      box-shadow: none !important;
    }
  }
}
.MuiPopover-paper {
  .MuiMenuItem-root {
    &:hover {
      background-color: transparent !important;
    }
  }
}
#menu-gender, #menu-ethnicity, #menu-join-referee, #menu-MilitaryStatus, #menu-WageExpectations, #menu-shift{
  .MuiPaper-root{
    .MuiList-root{
      .MuiMenuItem-root{
        &:hover {
          background-color: rgba(0, 0, 0, 0.04) !important;
        }
      }
    }
  }
}
.navdrop-down {
  .MuiPaper-root {
    left: 1080px !important;
  }
}
.score-width {
  .MuiPaper-root {
    .MuiGrid-root {
      width: 420px !important;
    }
  }
}
.model-popover {
  .MuiPaper-root.MuiPopover-paper {
    top: 170px !important;
    left: 376px !important;
  }
}
.profile-picture {
  background: #fffaeb;
  border-radius: 0px 0px 8px 8px;

  .MuiPaper-root {
    background: transparent !important;
    box-shadow: none !important;
    padding: 0px !important;
    justify-content: space-between !important;
    .MuiButtonBase-root.Mui-disabled {
      color: #667085 !important;
      background: none !important;
      border: none !important;
    }
    .MuiButtonBase-root {
      color: var(--primary-color) !important;
      padding: 0px !important;
      width: 25px !important;
      min-width: 5px;
    }
  }
}
.access-popover{
  z-index: 9999999999 !important;
  .MuiPaper-root {
    &.MuiPopover-paper {
      background: #FFFFFF !important;
          box-shadow: 0px 3px 8px rgba(149, 136, 136, 0.25) !important;
          border-radius: 4px !important;
          width: 300px !important;
    }
  }
}
.access-popover-neuro{
  z-index: 9999999999 !important;
  .MuiPaper-root {
    &.MuiPopover-paper {
      background: #FFFFFF !important;
          box-shadow: 0px 3px 8px rgba(149, 136, 136, 0.25) !important;
          border-radius: 4px !important;
          width: 315px !important;
    }
  }
}
.fixpaddingtop .MuiPaper-root {
  padding-top: 80px !important;
}
.navbar-responsive .MuiPaper-root {
  top: 0 !important;
  right: 0;
  padding: 25px;
  background: #fff !important;
  box-shadow: none !important;
  height: 100vh;
  border-radius: 0 !important;
  left: 32px !important;
}

//Scroll-hide-nav-bar
.sticky {
  display: grid;
  align-content: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  width: 20vw;
  z-index: 99;
}
.hideScrollDown {
  left: 0;
  bottom: 40vh;
}
.displayArea .disableAnimations .fitToPage {
  height: 100vh !important;
}
.explorationContainer .exploreCanvas .canvasFlexBox .displayAreaContainer .displayArea {
  height: 100vh !important;
}
#chat-circle {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #5A5EB9;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
  padding: 28px;
  cursor: pointer;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn#my-btn {
  background: white;
  padding-top: 13px;
  padding-bottom: 12px;
  border-radius: 45px;
  padding-right: 40px;
  padding-left: 40px;
  color: #5865C3;
}
#chat-overlay {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: none;
}
#composition-menu{
  .MuiMenuItem-root{
    &:hover {
      background-color: var(--list-hover-color) !important;
    }
  }
}
#menu-join-referee{
  .MuiPaper-root.MuiPopover-paper{
    box-shadow: none !important;
  }
}
