.enterprise-access-table {
    .MuiTable-root {
        font-family: var(--font-family) !important;

        .MuiTableHead-root {
            tr {

                // border-bottom: 1px solid #696871;
                /*  &:last-child{
                      border-bottom: 0;
                  }*/
                th {
                    // color: #767676;
                    background: #fff;
                    // font-size: 13px;
                    // opacity: 1;
                    // font-weight: 600;
                    padding: 6px 10px;
                    color: var(--gray9) !important;
                    opacity: 1 !important;
                    font-size: var(--font-size-14) !important;
                    font-weight: var(--font-weight-400) !important;
                    // padding:5px 15px !important;
                    font-family: var(--font-family) !important;
                    border-bottom: none !important;
                }
            }
        }

        .MuiTableBody-root {
            background-color: #FFFFFF;

            tr {
                td {
                    color: var(--gray10);
                    font-size: var(--font-size-14);
                    font-weight: normal;
                    padding: 5px 10px 5px 10px;
                    font-weight: var(--font-weight-400) !important;
                    vertical-align: middle;
                }
            }
        }
    }

    .MuiTablePagination-select {
        margin-right: 10px !important;
        margin-bottom: 2px;
    }

    .MuiButtonBase-root {
        margin-right: 3px !important;
    }
}

.interview-tile {
    .MuiPaper-root {
        box-shadow: none !important;
    }
}

.interview-list-table {
    border-collapse: initial !important;
    border-spacing: 0px 3px !important;
    background-color: $bg-grey;

    tbody {
        .interview-card {
            border-radius: 10px;
            border: 1px solid #EEEDFE;
            background: #FFF;
            // display: flex;
            margin-top: 10px !important;
            vertical-align: top;

            &:hover {
                border: 0.5px solid var(--primary-color);
                box-shadow: 0px 18px 14px -22px #00005e !important;
                border-radius: 10px;
                transition: all .3s ease-in-out;
                animation: pulse 1.5s;
            }

            td {
                border-bottom: none !important;
                padding: 18px 15px 15px 15px !important;
            }

            tr {
                &:hover {
                    background-color: transparent !important;
                }
            }
        }
    }
}
.referral-table{
    .MuiTableHead-root{
        background: #F2F4F7;
        border-radius: 8px;
        tr{
            th{
                color: var(--gray9) !important;
                opacity: 1 !important;
                font-size: var(--font-size-12) !important;
                font-weight:var(--font-weight-500) !important;
                padding: 6px 10px !important;
                font-family: var(--font-family) !important;
                border-bottom: none !important;
                .MuiFormGroup-root{
                    .MuiFormControlLabel-root {
                            margin-right: -14px !important;
                    }
                }
            }
        }
    }
    .MuiTableBody-root{
        tr{
            td{
                color: var(--gray10) !important;
                font-weight: var(--font-weight-400) !important;
                font-size: var(--font-size-14) !important;
                vertical-align: middle !important;
                border-bottom: 1px solid rgba(105, 104, 113, 0.2) !important;
                padding: 15px 10px !important;
                font-family: var(--font-family) !important;
            }
        }
    }
}
.policy-table{
    .MuiPaper-root{
        box-shadow: none !important;
        border:1px solid $gray2 !important;
        border-radius: 0px !important;
    }
    .MuiTableHead-root{
        .MuiTableCell-root{
            border:1px solid $gray7 !important;
        }
    }
    .MuiTableBody-root{
        .MuiTableCell-root{
            vertical-align: top !important;
            border:1px solid $gray7 !important;
        }
    }
}