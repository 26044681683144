// padding
.p-0 {
    padding: 0 !important;
}
.p-3px {
    padding: 3px!important;
}
.p-5px {
    padding: 5px !important;
}
.p-7{
    padding: 7px;
}
.p-8 {
    padding: 8px !important;
}
.p-9{
    padding: 9px !important;
}
.p-10 {
    padding: 10px !important;
}
.p-14{
    padding: 14px !important;
}
.p-15 {
    padding: 15px !important;
}
.p-20 {
    padding: 20px !important;
}
.p-25 {
    padding: 25px !important;
}
.p-30 {
    padding: 30px !important;
}

/*padding-top*/
.p-t-0 {
    padding-top: 0px !important;
}
.p-t-2 {
    padding-top: 2px !important;
}
.p-t-3 {
    padding-top: 3px !important;
}
.p-t-5 {
    padding-top: 5px !important;
}
.pt-8{
    padding-top: 8px !important;
}
.pt-10 {
    padding-top: 10px !important;
}
.pt-15{
    padding-top: 15px !important;
}
.pt-18 {
    padding-top: 18px !important;
}
.pt-20 {
    padding-top: 20px !important;
}
.pt-25 {
    padding-top: 25px !important;
}
.pt-30{
    padding-top: 35px !important;
}
.pt-35{
    padding-top: 35px !important;
}
.pt-40 {
    padding-top: 40px !important;
}
.pt-60 {
    padding-top: 60px !important;
}
.pt-80 {
    padding-top: 80px !important;
}
.pt-110 {
    padding-top: 110px !important;
}
.pt-160 {
    padding-top: 160px !important
}
.pt-0-5 {
    padding-top: 0.5px !important;
}

/*padding-bottom*/
.pb-0 {
    padding-bottom: 0px !important;
}
.p-b-5 {
    padding-bottom: 5px !important;
}
.pb-8{
    padding-bottom: 8px !important;
}
.pb-10 {
    padding-bottom: 10px !important;
}
.pb-12 {
    padding-bottom: 12px !important;
}
.pb-15 {
    padding-bottom: 15px !important;
}
.pb-20 {
    padding-bottom: 20px !important;
}
.pb-25 {
    padding-bottom: 25px !important;
}
.pb-30 {
    padding-bottom: 30px !important;
}
.pb-35 {
    padding-bottom: 35px !important;
}
.pb-40 {
    padding-bottom: 40px !important;
}
.pb-45 {
    padding-bottom: 45px !important;
}
.pb-60 {
    padding-bottom: 60px !important;
}
.pb-62 {
    padding-bottom: 62px !important;
}
.pb-80 {
    padding-bottom: 80px;
}
.pb-135 {
    padding-bottom: 135px;
}

/*padding-left*/
.p-l-0 {
    padding-left: 0px !important;
}
.p-l-4 {
    padding-left: 4px !important;
}
.p-l-5 {
    padding-left: 5px !important;
}
.pl-6 {
    padding-left: 6px !important;
}
.pl-7 {
    padding-left: 7px !important;
}
.pl-10 {
    padding-left: 10px !important;
}
.pl-13 {
    padding-left: 13px !important;
}
.pl-15 {
    padding-left: 15px !important;
}
.pl-18 {
    padding-left: 18px !important;
}
.pl-20 {
    padding-left: 20px !important;
}
.pl-25 {
    padding-left: 25px !important;
}
.pl-28 {
    padding-left: 28px !important;
}
.pl-30 {
    padding-left: 30px !important;
}
.pl-31 {
    padding-left: 31px !important;
}
.pl-32 {
    padding-left: 33px !important;
}
.pl-35 {
    padding-left: 35px !important;
}
.pl-37 {
    padding-left: 37px !important;
}
.pl-40 {
    padding-left: 40px !important;
}
.pl-45 {
    padding-left: 45px !important;
}
.pl-50 {
    padding-left: 50px !important;
}
.pl-51 {
    padding-left: 51px !important;
}

/*padding-right*/
.p-r-0 {
    padding-right: 0 !important;
}
.p-r-1 {
    padding-right: 1px;
}
.p-r-5 {
    padding-right: 5px !important;
}
.pr-7{
    padding-right: 7px;
}
.pr-10 {
    padding-right: 10px !important;
}
.pr-15 {
    padding-right: 15px !important;
}
.pr-18 {
    padding-right: 18px !important;
}
.pr-20 {
    padding-right: 20px !important;
}
.pr-25 {
    padding-right: 25px !important;
}
.pr-30 {
    padding-right: 30px !important;
}
.pr-31 {
    padding-right: 31px !important;
}
.pr-32 {
    padding-right: 33px !important;
}
.pr-35 {
    padding-right: 35px !important;
}
.messages-chat-align {
    padding-right: 370px !important;
}
.messages-chat-popper-align {
    padding-right: 395px !important;
}

/*padding -> px & py */
.p-x-3 {
    padding: 0 3px !important;
}
.p-x-5 {
    padding: 0px 5px;
}
.px-10 {
    padding: 0 10px !important;
}
.px-15 {
    padding: 0 15px !important;
}
.px-25 {
    padding: 0 25px !important;
}
.px-30{
    padding-left: 30px;
    padding-right: 30px;
}
.p-y-5 {
    padding: 5px 0 !important;
}
.py-20 {
    padding: 20px 0;
}
.py-25 {
    padding: 25px 0;
}
.pxy-6-16 {
    padding: 6px 16px;
}
.pxy-8 {
    padding: 6px 8px !important;
}
.py-10 {
    padding: 10px 0;
}
.py-15 {
    padding: 15px 0;
}
.pxy-20 {
    padding: 10px 20px;
}
.pxy-6{
    padding:6px !important
}
.px-245 {
    padding-left: 180px;
    padding-right: 180px;
}
.refer-candidate{
    padding: 8.5px 14px !important;
}
.pr-3 {
    .choose-file {
        padding-right: 3px !important;
    }
}