.ccard-main {
  width: 40rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}

.ccard {
  width: 20rem;
  height: 20rem;
  background: rgb(109, 148, 255);
  border-radius: 5px;
  transition: box-shadow 0.5s;
  will-change: transform;
}
.dashboard-card {
  border: 1px solid var(--gray4);
  box-shadow: 0px 8px 20px rgba(242, 242, 242, 0.25) !important;
  border-radius: 8px !important;
  transition: 0.3s ease !important;
  min-width: 100px;
}
.profile-view-card {
  border: 1px dashed var(--gray4);
  box-shadow: 0px 8px 20px rgba(242, 242, 242, 0.25) !important;
  border-radius: 8px !important;
  transition: 0.3s ease !important;
  min-width: 50px;
  margin: 0 5px;
  background-color: #F8F9FC !important;
  height: 100%;
  .MuiCardContent-root {
    padding: 8px !important;
  }
}

// navbar changes
.index-issue {
  // position: absolute;
  z-index: 9 !important;
  // position:sticky
}

.navbar-fixed {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  right: 0;
  background: white;
  padding: 8px 36px;
}

.navbar-new-navbar-fixed {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  right: 0;
  background: white;
  padding: 0px 36px;
}

.navbar-fixed-new {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  right: 0;
  background: white;
  padding: 8px 0px;
  border-bottom: 1px solid var(--gray3);
}

.padding-new-navbar {
  padding: 4px 0px 4px;
}
.navBar ul {
  li {
      list-style: none;
      padding-right: 25px;
  };
  a {
      text-decoration: none;
  }
}
.nav__submenu,
.navBar__submenu {
  display: none;
}

.nav__menu-item:hover {
  .nav__submenu,
  .navBar__submenu {
    display: flex;
  }
}
.nav__menu-item {
  display: flex;
  position: relative;
  &:hover {
    .navBar__submenu {
      display: block;
      // padding:10px !important;
      background-color: FFFFFF !important;
      border-radius: 0px !important;
      // color: #FFFFFF !important;
      margin-top: -18.5px !important;
      left: 125px;
      position: fixed !important;
      border: 1px solid #E4E4E7;
      box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
    }
  }
}

.nav__submenu,
.navBar__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  width: 110px;
  background-color: #FFFFFF;
}

.nav__submenu-item {
  margin: 0px !important;
  padding-left: 0px !important;
  // padding: 12px 0 !important;

  &:hover {
    margin: 0px !important;
    padding-left: 0px !important;
    background: rgba(68, 91, 199, 0.2);
  }
}
.menu-list{
  .navBar__submenu, .nav__menu{
    .nav__submenu-item, .nav__menu-item {
      text-align: left !important;
    }
  }
}
.nav__submenu-item {
  a {
    &:hover {
      color: rgba(68, 91, 199, 1) !important;
    }
  }
}
.navbar-spacing {
  padding: 6px 36px !important;
}
.navbar-dropdown {
  width: 297px;
  background: $bg-white;
  border: 1px solid #eeeeee;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
  border-radius: 5px;
  padding: 10px;
}
.navbar-responsive {
  height: 107vh !important;
  display: initial !important;

  .MuiBox-root {
    /* padding-left: 0;*/
  }
}
// .navbar-fixed{
//     .autoInput{
//         margin-top:8px !important;
//     }
// }

/*css for publish screen*/
.main-publish-container {
  overflow-y: auto;
  height: 100vh;
}
.header-fixed {
  position: sticky;
  top: 0;
  z-index: 9;
}
.main-template-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 85vh;
}
.client-portal-fixed-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
}
.open-button {
  bottom: 25px;
  left: 86em;
  img {
    right: 30px;
    width: 50px;
  }
}
.background-image {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 480px;
  left: 0;
  top: 0;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.testimonial-right-container {
  img {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }
}
.testimonial-profile {
  img {
    width: 50%;
  }
}
.testimonial-img {
  img {
    width: 75%;
  }
}
.who-we-are-container {
  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}
.join-us-container {
  img {
    width: 100%;
    object-fit: cover;
    height: 420px;
  }
}
.meet-the-team {
  img {
    width: 100%;
  }
}

.life-at-company {
  img {
    width: 100%;
    height: 85%;
    object-fit: cover;
  }
}

.ready-to-join-us {
  background-size: cover;
  height: 480px;

  img {
    width: 100%;
  }
}

.who-we-are-container {
  background-size: cover;
  height: 480px;

  img {
    width: 100%;
  }
}

.responsive-img {
  img {
    width: 50%;
  }
}

.background-image-who-we-are {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.background-image-get-ready-to-join {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  img {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.testimonial-profile-card {
  img {
    width: 30%;
  }
}
.header-img {
  img {
    width: 100%;
  }
}
.MuiAppBar-colorPrimary {
  background-color: #FFFFFF !important;
}
.nugget-section {
  padding: 12px 10px;
  .nugget-container {
    display: flow-root;
  }
}
.user-pic-block {
  .profile-pic {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .profile {
      padding: 3px;
      background-color: #fff;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .profile-file-upload {
      position: absolute;
      display: none;
    }
  }
}

.user-pic-block {
  .profile-pic {
    &:hover {
      .profile-file-upload {
        display: block;
        position: absolute;
      }
    }
  }
}

.container-scroll {
  min-height: 100vh;
}

.pdf-icon-section {
  height: 80%;
  width: 15%;
  object-fit: cover;
}

.container {
  position: relative;
  width: 100%;
}

.videoSection {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  video {
    border-radius: 14px !important;
    left: 0px !important;
    position: relative;
    right: 0px !important;
    width: 100% !important;
    height: auto !important;
  }

}

.videoSectionImage {
  height: 100%;
  width: 76%;
  top: 0;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: 52px;

  img {
    position: absolute;
    left: 12px !important;
    border-radius: 14px;
    width: auto;
    height: 280px;
    right: 0px !important;
    display: none !important;
  }
}

.videoMiddleSection {
  transition: .5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container .videoMiddleSection {
  opacity: 1;
}

.overlay-image {

  /*  color: #fff;
  height: 11em;
  width: 11em;
  border-radius: 50%;
  border: 2px dashed;*/
  img {
    position: absolute;
    top: -29px;
    left: -12px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent gray */
  z-index: 9999;
  /* Ensure the overlay appears on top of other elements */
}

.pause-image {
  margin-top: -9px;
  margin-left: -10px;
}

.candidate-draft {
  left: 78px;
  top: 2px;
}

.video-image {
  object-fit: cover;
}

.choose-video {
  background: #F9FAFB;
  border: 1px dashed #98A2B3;
  border-radius: 8px;
}

.record-video {
  border-radius: 14px;
}

.video-upload-shadow {
  position: absolute;
  background-color: var(--gray10);
  opacity: 0.9;
  // width: 373px;
  // left: 66px !important;
  // top:237px !important;
  border-radius: 0px 0px 14px 14px;
  right: 0px !important;
  width: 100% !important;
  bottom: 5px !important;
}

.lazy-loader {
  position: relative;
  -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  border-radius: 6px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  padding: 20px;

  // margin: 40px auto;
  .shimmerBG {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }

    100% {
      background-position: 1200px 0;
    }
  }

  .media {
    height: 200px;
  }

  .p-32 {
    padding: 32px;
  }

  .title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
  }

  .content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .end {
    width: 40%;
  }

}
.lazy-loader-list {
  position: relative;
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  background-color: #FFF;
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  padding-bottom: 20px;
  padding-top: 30px;

.shimmerBG {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}
@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}
.media {
  height: 165px;
}
.p-32 {
  padding: 32px;
}
.mx-8{
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.px-8{
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.title-line {
  height: 20px;
  width: 100%;
  // margin-bottom: 12px;
  border-radius: 20px;
}
.content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
}
.end {
    width: 40%;
  }
.img {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
}
}
.no-box-shadow {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
}
.lazy-loader2 {
  position: relative;
  -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  // box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  padding: 20px;

  // margin: 40px auto;
  .shimmerBG {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }

    100% {
      background-position: 1200px 0;
    }
  }

  .media {
    height: 200px;
  }
  .small-media {
    height: 50px;
  }
  .p-32 {
    padding: 32px;
  }
  .px-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .mx-32 {
    margin-left: 40px;
  }
  .title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
  }
  .title-line-small {
    height: 24px;
    width: 50%;
    margin-bottom: 12px;
    border-radius: 20px;
  }
  .title-line-small2 {
    height: 20px;
    width: 50%;
    margin-bottom: 12px;
    border-radius: 6px;
  }
  .content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
  }
  .content-line2 {
    height: 10px;
    width: 100%;
    margin-bottom: 0px;
    border-radius: 8px;
  }
  .end {
    width: 40%;
  }
}

.lazy-loader-messages {
  position: relative;
  // -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  // box-shadow: 2px 4px 8px 5px rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  background-color: #FFF;
  border-radius: 3px;
  // height: 100vh;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  padding-bottom: 20px;
  padding-top: 30px;

.shimmerBG {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}
@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}
.media {
  height: 165px;
}
.p-32 {
  padding: 32px;
}
.mx-8{
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx-16{
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.px-8{
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.px-16{
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.title-line {
  height: 16px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 20px;
}
.content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
}
.end {
    width: 40%;
  }
.img {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
}
}

.loader-in-header {
  position: relative;
  width: 100%;
  padding: 7px;
  // margin: 40px auto;
  .shimmerBG {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
  }
  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }
  .media {
    height: 200px;
  }
  .p-32 {
    padding: 32px;
  }
  .title-line {
    height: 20px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 20px;
  }
  .content-line {
    height: 6px;
    width: 100%;
    margin-bottom: 14px;
    border-radius: 8px;
  }
  .end {
    width: 40%;
  }
  .img {
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    margin-right: 8px;
  }
}

.card-view-interview {
  border: 1px solid #EAE9F2;
  box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12), 0px 10px 32px -4px rgba(90, 136, 193, 0.12);
  border-radius: 10px;
  min-height: 240px;
}

.card-view-interview-inside {
  background: #FFFFFF;
  box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12), 0px 10px 32px -4px rgba(90, 136, 193, 0.12);
  padding: 22px 15px 25px 25px !important;
  border-radius: 0px 0px 10px 10px;
  min-height: 240px;
  margin-top: 10px;
  overflow: auto;
}

.datebox {
  margin-right: 20px !important;
  min-width: 65px;
  background: rgba(68, 91, 199, 0.1);
  border-radius: 6px;
  padding: 10px
}

.interview-select {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      background-color: transparent !important;
      padding: 12px 12px 12px 4px !important;
      margin-right: 10px !important
    }
  }
  .card-view-interview-inside{
    background: #FFFFFF;
    // border: 1px solid #EAE9F2;
    box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12), 0px 10px 32px -4px rgba(90, 136, 193, 0.12);
    // border-radius: 10px;
    padding: 22px 15px 25px 25px !important;
    border-radius: 0px 0px 10px 10px;
    min-height: 230px;
    margin-top: 10px;
    overflow: auto;
  }

  .MuiSvgIcon-root-MuiSelect-icon {
    right: 20px !important;
  }
}

.interview-filters {
  // position: sticky;
  top: 65px;
  overflow: visible;
  // z-index: 999999;
  background: #F7F7F7;
  left: 0;
  padding: 20px 10px 10px 0px;
}
.survey-row{
  .MuiTableCell-root{
    border-bottom: none !important;
  }
}
.generate-ai{
  height: 230px;
  overflow-y: scroll;
}

// datePicker chnages
.MuiPickersBasePicker-container {
  .MuiToolbar-root {
    .MuiButton-root {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
.MuiPickersDay-current{
  color: initial !important;
}
.MuiPickersDay-daySelected{
  .MuiTypography-colorInherit{
    color: white !important;
  }
}
.interview-list-table{
  tbody{
    .interview-card{
      border-radius: 10px;
      border: 1px solid #EEEDFE;
      background: #FFF;
      // display: flex;
      margin-top: 10px !important;
      vertical-align: top;
      &:hover{
        background-color: #FFF !important;
      }
      td{
        border-bottom: none !important;
        padding: 18px 15px 15px 15px !important;
      }
      tr{
        &:hover{
          background-color: transparent !important;
        }
      }
    }
  }
}

// media queries for card
@media screen and (min-width: 300px) and (max-width: 516px) {
  .videoSectionImage {
    height: 60%;
    width: 89%;
    top: 58px;
    position: absolute;
    background-image: url('/assets/images/videoDefaultSection.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 16px;
  }
  .overlay-image {
    color: #fff;
    height: 6.5em;
    width: 6.5em;
    border-radius: 50%;
    border: 2px dashed;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .responsive-img {
    width: 35% !important;
  }
  .responsive-header {
    margin-left: -7em !important;
    padding-right: 10px !important;
  }
  .responsive-header-text {
    font-size: var(--font-size-12) !important;
  }
  .settings-small-screen-resolution {}
  .settings-big-screen-resolution {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1200px) {
  .settings-big-screen-resolution {}
  .settings-small-screen-resolution {
    display: none;
  }
}

@media (min-width: 1200px) {
  .card-container {
    padding: 0 5em;
  }
  .settings-big-screen-resolution {}
  .settings-small-screen-resolution {
    display: none;
  }
}