// text colors
.gray-text {
    color: #1F1534;
}
.text-info{
    color: #7D7987;
}
.hc-primary-text {
    color: #45AC8B;
}
.green-supporting{
    color:#2D715B;
}

// background colors
.bg-green{
    background-color: #2D715B;
}
// Buttons
.btn {
    &.healthcare-navbar-btn {
        background: #45AC8B;
        color: #FFFFFF;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-400);
        padding: 10px 24px;
        text-transform: none;
        box-shadow: none;
        border-radius: 8px;
        font-family: var(--font-family);
        &:hover {
            background: #45AC8B !important;
            box-shadow: none !important;
            color: #FFFFFF !important;
        }
    }
}
.btn {
    &.healthcare-primary-btn {
        background: #45AC8B;
        color: #FFFFFF;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-600);
        padding: 10px 24px;
        text-transform: none;
        box-shadow: none;
        border-radius: 55px;
        font-family: var(--font-family);
        &:hover {
            background: #45AC8B !important;
            box-shadow: none !important;
            color: #FFFFFF !important;
        }
    }
}
.btn {
    &.healthcare-secondary-btn {
        background: #FFFFFF;
        color: #45AC8B;
        font-size: var(--font-size-18);
        font-weight: 700;
        padding: 10px 24px;
        text-transform: none;
        box-shadow: none;
        border: 1.4px solid #45AC8B;
        border-radius: 55px;
        font-family: var(--font-family);
        &:hover {
            background: #FFFFFF !important;
            box-shadow: none !important;
            color: #45AC8B !important;
        }
    }
}

.btn {
    &.text-header {
        color: #1F1534;
        font-size: var(--font-size-18);
        text-transform: none;
        box-shadow: none;
        font-weight: var(--font-weight-400);
        font-family: var(--font-family);
        opacity: 0.5;
    }
}

.btn {
    &.active-text-header {
        color: #45AC8B;
        font-size: var(--font-size-18);
        text-transform: none;
        box-shadow: none;
        font-weight: 700;
        font-family: var(--font-family);
    }
}

// Styles
.healthcare-navbar{
    position: fixed;
    top: 0;
    z-index: 99;
    width:100%;
    right:0;
    background: white;
    padding: 8px 0px;
  }
.hc-stand-card{
    background: #FFFFFF;
    box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
    border-radius: 20px;
}
.job-everyone-card{
    background: #FFFFFF;
    box-shadow: 0px 10px 40px rgba(18, 18, 18, 0.1);
    border-radius: 12px;
    height: 220px;
}
.hc-card-height{
    height: 130px;
}
.hc-footer{
    li{
        list-style-type: none !important;
        margin-top: 20px;
        a{
            color:#FFFFFF;
            font-size: var(--font-size-18);
            font-weight:300;
        }
    }
}
.hc-carousel {
    .carousel-label {
        background: #45AC8B;
        border-radius: 24px;
        padding: 50px;
    }

    .MuiMobileStepper-root {
        justify-content: center !important;
        margin-top: 20px !important;

        button {
            background: none !important;
            outline: none !important;
        }

        .MuiMobileStepper-dots {
            margin-right: 50px !important;
            margin-left: 50px !important;

            .MuiMobileStepper-dot {
                background-color: #45AC8B !important;
                opacity: 0.3;
            }
            .MuiMobileStepper-dotActive {
                background: #45AC8B !important;
                opacity: 1 !important;
            }
        }
    }
}
.label-3-text{
    color: rgba(255, 255, 255, 0.85) !important;
}
.title-border{
    border-bottom: 2.5px solid #000000;
    border-radius: 5px !important;
    width: 60px;
}
.specailisation-select {
    .MuiFormControlLabel-root{
        margin-left: 0px !important;
        .MuiCheckbox-root {
            margin-right: 10px !important;
        }
        .MuiFormControlLabel-label {
            font-size: var(--font-size-14) !important;
            font-weight: var(--font-weight-400) !important;
            color: #1D2939 !important;
        }
    }
}
.discipline-list {
    background: #ffffff;
    // border: 1px solid #98A2B3;
    border-radius: 6px;
    max-height: 100px !important;
    overflow-y: scroll !important;
    padding:10px 20px 0px 20px;
    .MuiMenuItem-root{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
.discipline-input{
    .MuiSelect-icon{
        top: calc(50% - 10px) !important;
        right: 10px !important;
    }
}
.specialization-icon{
    img{
      background: #FFF7DA;
      padding: 8px;
      width: 34px;
      border-radius: 50%;
    }
}
.hc-startDate-icon{
    img{
      background: #E6F4F9;
      padding: 8px;
      width: 34px;
      border-radius: 50%;
    }
}
.specilization-check{
    .MuiFormControlLabel-root{
        margin-top: 10px !important;
        .MuiFormControlLabel-label{
            color:#1D2939 !important;
            font-size: var(--font-size-14) !important;
            font-weight:var(--font-weight-400) !important;
        }
        .MuiCheckbox-root{
            margin-right: 10px !important;
        }
    }
}