.footer-section{
  background-color: #222222;
  .footer-sub-section{
    ul{
      list-style: none;
      p{
        color: #FFFFFF;
        padding-bottom: 1em;
      }
    }
  }
}

.hiring-talent{
  background-color: var(--primary-color);
  border-radius: 22px;
  box-shadow: 0px 2px 18px 0px #5c5c5c;
}

.book-demo-button{
  border: 1px solid #FFFFFF !important;
  color: #262626 !important;
  background-color: #FFFFFF !important;
  font-size: var(--font-size-16) !important;
  padding:10px 30px !important;
  text-transform: none;
  box-shadow: none;
  font-family: var(--font-family);
}

.circle{
  font-weight: var(--font-weight-600);
  font-size: 32px;
  background-color: #ff4d54;
  border-radius: 50%;
  overflow: hidden;
  height: 70px;
  width: 70px;
  .inner-text{
    align-items: center;
    justify-content: center;
    vertical-align: center;
    display: flex;
    p{
      margin-top: 12px;
      color: #FFFFFF;
    }
  }
}

.learn-how-button{
  border: 1px solid #150349 !important;
  color: #FFFFFF !important;
  background-color: #150349 !important;
  font-size: var(--font-size-16) !important;
  padding:10px 30px !important;
  text-transform: none;
  box-shadow: none;
  font-family: var(--font-family);
  border-radius: 0.375rem;
  &:hover{
    border: 0px solid #150349 !important;
    color: var(--hover-button-text-color) !important;
    background: var(--hover-button-color) !important;
    font-size: var(--font-size-16) !important;
    padding:10px 30px !important;
    text-transform: none;
    box-shadow: none;
    font-family: var(--font-family);
  }
}

.sub-navbar{
  background: #FFFFFF !important;
  box-shadow: inset 0 1px 4px rgb(0 0 0 / 25%);
  position: fixed;
  width: 100%;
  z-index: 1;
  top:67px;
  padding:10px 40px 5px 40px !important;
  // box-shadow: 0 4px 4px rgb(179 179 179 / 15%);
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.launch-page-heading-color{
  color: var(--gray9)!important;
}

.landing-sub-text-color{
  color: var(--gray9);
}
.launch-icon {
  padding: 2px;
  color: $white-color;
  background: var(--primary-color);
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  margin: 5px 10px 0 0;
}
.launchBgImage {
  background-image: url("/assets/images/header-bg.png");
  background-size: cover;
  height: 450px;
  align-items: center;
  display: flex;
  background-position: center;
}

h2 {
  width: 100%;
  text-align: center;
  border-bottom: 3px solid rgba(105, 104, 113, 0.2);
  line-height: 0.1em !important;
  margin: 10px 0 20px;
}

h2 span {
  &.circle-image{
    color: #fff;
    background-color:  var(--primary-color);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 15px 27px;
  }
}

.demo-button{
  border: 1px solid $info-color;
  color: #FFFFFF;
  background-color: $info-color;
  font-size: var(--font-size-16);
  padding:10px 30px;
  text-transform: none;
  box-shadow: none;
  font-family: var(--font-family);
}

.hover-pulse:hover {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.transition-effect:hover {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

card{
  box-shadow: 0px 10px 80px -10px rgb(44 54 92 / 20%);
  background-color: #FFFFFF !important;
  &:hover{
    background-color: #FFFFFF !important;
  }
}

/* [1] The container */
.section-hover-zoom {
  top: 0;
  left: 0;
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.section-hover-zoom {
  transform-origin: 30% 30%;
  transition: transform 1s, filter .5s ease-out;
}

/* [3] Finally, transforming the image when container gets hovered */
.section-hover-zoom:hover {
  transform: scale(1.15);
}
card{
  box-shadow: 0px 10px 80px -10px rgb(44 54 92 / 20%);
}

/*.learn-how-button{
  svg{
    display: none;
  }
  svg &:hover{
    display: contents;
  }

}*/

