/*Background-Colors*/
.bg-white {
  background-color: $bg-white;
}
.bg-input {
  background-color: $bg-input-gray;
}
.bg-primary{
  background-color: var(--primary-color);
}
.secondary-bg-color {
  background-color: var(--secondary-color);
}
.bg-green {
  background-color: $bg-green;
}
.bg-grey {
  background-color: $bg-grey !important;
}
.bg-light-gray {
  background-color: $bg-light-gray;
}
.bg-gray2 {
  background-color: $bg-gray2;
}
.bg-gray3{
  background-color: $gray3 !important;
}
.bg-gray4 {
  background-color: var(--gray4)  !important;
}
.bg-light-blue {
  background-color: var(--secondary-color);
}
.bg-light-orange {
  background-color: $bg-light-orange;
}
.privacy-bg {
  background-color: $privacy-bg;
}
.side-menu-bg {
  background-color: rgba(68, 91, 199, 0.2);
}
.MuiMenu-list .Mui-selected {
  background-color: var(--secondary-color) !important;
}
.secondary-card-bgcolor{
  background-color: var(--secondary-color) !important;
}

// Icon colors
.svg-icons > path {
  stroke: var(--primary-color);
}
.svg-icons-gray7 > path {
  stroke: var(--gray7);
}
.svg-icons-gray8 > path {
  stroke: var(--gray8);
}
.fill-svg-icons > path {
  fill: var(--primary-color);
}
.rect-svg-icons > rect {
  fill: var(--primary-color);
}

/*Text-color*/
.primary-text-color {
  color: $primary-text-color  !important;
}
.primary-btn-text {
  color: var(--primary-color) !important;
}
.color-primary {
  color: $primary-text-color;
}
.heading-text-color{
  color: var(--heading-text-color) !important;
}
.sub-text-color {
  color: var(--gray7)  !important;
}
.light-text {
  color: $light-black  !important;
}
.c-white {
  color: $white-color  !important;
}
.warn-color {
  color: $warning-color  !important;
}
.success-color {
  color: $success-color;
}
.applied-color {
  color: $applied-color;
}
.light-grey {
  color: $light-grey;
}
.danger-color {
  color: $danger-color  !important;
}
.light-black {
  color: $black-light;
}
.gray1 {
  color: var(--gray1);
}
.gray4 {
  color: var(--gray4);
}
.gray6 {
  color: var(--gray6);
}
.gray7 {
  color: var(--gray7)  !important;
}
.gray8 {
  color: var(--gray8);
}
.gray9 {
  color: var(--gray9);
}
.gray10{
  color: $gray10;
}
.gray11 {
  color: var(--gray11);
}
.color-info {
  color: $info-color;
}
.text-black {
  color: $black-color  !important;
}
.side-menu-color {
  color: $side-menu-color;
}
.black-heading-color {
  color: #18181B !important;
}
.tile-blue {
  color: rgb(3, 142, 184);
}
.missing-file-colour {
  color: #BA7000;
}
.main-text-color{
  color: var(--main-text-color) !important;
}
.list-hover-color{
  &:hover{
    background-color: var(--list-hover-color) !important;
  }
}

/*Link color*/
.link-color {
  color: var(--primary-color) !important;
  cursor: pointer;
  text-decoration: none;
}

// Borders
.border-1 {
  border: 1px solid #E4E7EC;
}
.border-bottom-1 {
  border-bottom: 1px solid rgba(105, 104, 113, 0.2);
}
.borderBottom {
  border-bottom: 1px solid #E4E4E7 !important;
}
.border-bottom-gray4 {
  border-bottom: 1px solid var(--gray4)  !important;
}
.border-bottom-gray5{
  border-bottom: 1px solid #D0D5DD !important;
}
.border-right {
  border-right: 1px solid rgba(105, 104, 113, 0.2);
}
.border-right-gray7 {
  border-right: 1.3px solid var(--gray7)  !important;
}
.border-left-line {
  border-left: 1px solid #E4E4E7 !important;
}
.border-left-primary {
  border-left: 3px solid var(--primary-color) !important;
}
.border-left-danger {
  border-left: 3px solid #B54708 !important;
}
.border-top {
  border-top: 1px solid #E4E4E7 !important;
}
.border-top-gray5 {
  border-top: 1px solid #D0D5DD !important;
}
.border-menu-top {
  border-top: 1px solid #EBEBEB !important;
}
.border-menu-bottom {
  border-bottom: 1px solid #EBEBEB !important;
}
.borderLeft {
  border-left: 1px solid #E4E4E7 !important;
}
.border-gray6 {
  border: 1px solid $gray6 !important;
}
.focus-issue:focus {
  outline: 2px solid transparent !important;
}
.transparent-border {
  border: 1px solid transparent !important;
}
.danger-border {
  border: 1px solid $danger-color !important;
  border-radius: 6px;
}
.scheduled-date-border {
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}
.dashed-primary-border{
  border:1px dashed var(--primary-color);
}
.text-decoration-grey {
  text-decoration-color: #1F1F1F !important;
}
.text-decor-color {
  text-decoration-color: var(--primary-color) !important;
}
.decorationcolor {
  &:hover {
    text-decoration: underline !important;
    color: var(--primary-color) !important;
  }
}
.shadow-1 {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.shadow-new {
  border: 1px solid #E4E7EC !important;
  box-shadow: 0px 2px 6px rgba(216, 216, 216, 0.25) !important;
}
.default-img-border {
  background: var(--secondary-color);
  //border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: 1px;
  color: var(--primary-color);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-600);
}
.secondary-bg-color-svg-icons {
  background-color: var(--secondary-color);
  &:hover{
    background-color: var(--hover-button-color) !important;
    color: var(--hover-button-text-color) !important;
    .svg-icons>path {
      stroke: var(--hover-button-text-color) !important;
    }
    .fill-svg-icons>path {
      fill: var(--hover-button-text-color) !important;
    }
  }
}

@keyframes blink {
  0% {
    background-color: var(--secondary-color);
  }
  50% {
    background-color: #ffff;
  }
  100% {
    background-color: var(--secondary-color);
  }
}
.pool-bg {
  animation: blink 2s infinite;
}
.sub-info li a:active {
  color: var(--primary-color) !important;
  font-weight: 700;
}
.resendOTP {
  .link-color {
    cursor: default !important;
  }
}
.home-page-link-color {
  color: #150349 !important;
  cursor: pointer;
  text-decoration: none;
}
.cookie-btn {
  .btn{
    &.cookie-Primary-button {
      color: var(--gray1) !important;
      background-color: var(--primary-color) !important;
    }
  }
  .btn{
    &.cookie-secondary-button {
      color: #150349 !important;
      background-color: #E5f0FC !important;
      &:hover {
        background: var(--hover-button-color) !important;
        box-shadow: none !important;
        color: var(--hover-button-text-color) !important;
      }
    }
  }
}
