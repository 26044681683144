// tab changes
/*material-tabs*/
.material-tab {
  .MuiTabs-root {
    .MuiTabs-indicator {
      height: 7px;
      background: #0071e3;
    }
    .MuiTab-root {
      text-transform: capitalize;
      font-size: var(--font-size-16);
      width: 180px;
      color: #262626;
      font-family:var(--font-family);
      &.Mui-selected {
        color: #0071e3;
      }
      .MuiTouchRipple-root {
        border-bottom: 8px solid #0071e3;
        margin: 0 8px;
        opacity: 20%;
      }
    }
  }
}
.matching-tab {
  .MuiBox-root {
    padding: 0px !important;
  }
  .MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        // background-color: #150349 !important;
        height: 0px;
        // border-radius:3px;
      }
    }
    .MuiTabs-flexContainer {
      justify-content: space-between !important;
      // border-bottom: 5px solid rgba(114, 46, 204, 0.2) !important;
      // border-color: rgba(114, 46, 204, 0.2) !important;
      // border-radius: 3px !important;
    }
    .MuiTab-root {
      background: var(--gray3);
      color: $blue-color;
      text-transform: capitalize;
      font-family: var(--font-family) !important;
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-500);
      padding: 0px 15px !important;
      //border: 1px solid #667085;
      border-radius: 20px;
      color: $blue-color;
      min-height: 30px !important;
      margin: 0px 10px;
      // padding: 0 44px;
      &.Mui-selected {
        color: var(--primary-color);
        background: var(--secondary-color);
        border-radius: 20px;
      }
    }
  }
}
.message-job-list {
  .MuiTabs-root {
    .MuiButtonBase-root.Mui-disabled{
      border-bottom: none !important;
    }
    .MuiTab-root {
      min-height: 33px !important;
    }
    .MuiTabs-flexContainer {
      justify-content: initial !important;
    }
  }
}
// Basic information stepper//
.basic-stepper-align {
  .MuiStepLabel-root {
    align-items: flex-start !important;
    padding-bottom: 5px !important;
  }
  .MuiStepLabel-iconContainer {
    margin-right: 13px !important;
    .MuiSvgIcon-root {
      color: var(--secondary-color) !important;
      width: 20px;
      height: 20px;
      opacity: 0.7;
      .MuiStepIcon-text {
        fill: var(--primary-color) !important;
      }
    }
    .Mui-active {
      color: #ffffff !important;
      opacity: 1;
      .MuiStepIcon-text {
        fill: var(--primary-color) !important;
      }
    }
  }
  .MuiStepConnector-line {
    // right: 129px !important;
    // margin: 11px 15px 10px 0px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 80px !important;
    width: 1.5px !important;
    background-color: #ffffff !important;
  }
  .MuiStepLabel-label {
    font-weight: var(--font-weight-600) !important;
    font-size: var(--font-size-16) !important;
    color: var(--gray1) !important;
    position: relative;
    // margin-top: 20px !important;
  }
  .stepper-detail {
    color: var(--gray4) !important;
    font-size: var(--font-size-14) !important;
    line-height: 20px !important;
    display: flex !important;
    margin-top: 5px !important;
    font-weight: var(--font-weight-400) !important;
    position: absolute;
    margin-right: 30px !important;
  }
}
/* edit profile tabs */
.profile-tabs {
  button {
    // padding-left: 0px !important;
    // padding-right: 0px !important;
    // padding-bottom: 0px !important;
    padding: 0 16px 0 16px !important;
    text-transform: capitalize !important;
    font-size: var(--font-size-14) !important;
    font-weight: var(--font-weight-500);
    margin-right: 30px !important;
    color: var(--gray9) !important;
  }
  .MuiTabs-indicator {
    background: var(--primary-color) !important;
  }
  .MuiTabs-flexContainer {
    border-bottom: 2px solid #ebebeb;
    // justify-content: space-between !important;
    .Mui-selected {
      color: var(--primary-color) !important;
      font-weight: var(--font-weight-600) !important;
      font-family: var(--font-family) !important;
    }
  }
}
.myjob-new-tab {
  .profile-tabs {
    button {
      margin-right: 50px !important;
    }
  }
}
.job-detail-tabs {
  .MuiTab-root {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
    text-transform: initial !important;
    color: var(--gray7);
    font-family: var(--font-family) !important;
    margin-right: 40px;
    .Mui-selected {
      font-weight: var(--font-weight-600) !important;
      color: var(--primary-color) !important;
    }
  }
}
.skill-tab-align {
  .MuiBox-root {
    padding-top: 0px !important;
  }
}
.skill-tab-aligned {
  .MuiBox-root {
    padding-top: 8px !important;
  }
}
.tab-section-detail {
  color: var(--gray9);
  .mui-selected {
    color: var(--primary-color);
    border-bottom: 1.5px solid var(--primary-color);
    font-weight: var(--font-weight-500);
  }
}
.tab-container {
  .MuiGrid-root {
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    display: grid;
  }
}
.tabActive {
  color: var(--primary-color) !important;
  // border-bottom: 2px solid var(--primary-color);
  padding-bottom: 13px;
  text-decoration: underline !important;
}

// chips changes
.chip-dropdown {
  border: 1px solid #667085 !important;
  border-radius: 20px !important;
  padding: 4px 10px 4px 13px !important;
  text-transform: capitalize !important;
  margin: 10px 8px !important;
  font-size: var(--font-size-14);
  color: #1f1f1f !important;
  img {
    padding: 10px;
  }
}
.chip-dropdown-active {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  padding: 4px 10px 4px 13px !important;
  text-transform: capitalize !important;
  margin: 10px 8px !important;
  font-size: var(--font-size-14);

  img {
    padding: 10px;
  }

  &:hover {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
  }
}
.skills {
  background: var(--gray3) !important;
  border-radius: 20px;
  padding: 4px 12px !important;
  color: $blue-color !important;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  display: inline-block;
  margin: 4px 6px 4px 0px;
  text-transform: lowercase;
}
.MuiChip-root {
  background: #eff0f6 !important;
  border-radius: 7px !important;
  padding: 21px 0px !important;
  color: #696871 !important;
  font-weight: var(--font-weight-500) !important;
  font-size: var(--font-size-14) !important;
  // display: inline-block !important;
  margin: 4px 4px 4px 0px !important;
  word-break: break-all;
}
.ethnicity-chip {
  background: var(--secondary-color) !important;
  border-radius: 6px;
  padding: 4px 12px !important;
  color: var(--primary-color) !important;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  display: inline-flex !important;
  align-items: center !important;
  margin: 4px 6px 4px 0px;
  svg {
    fill: var(--primary-color) !important;
  }
}
.ethnicity-checkbox{
  color: #98A2B3 !important;
  background: none;
  padding: 0px !important;
  &.Mui-checked {
    color: var(--primary-color) !important;
    &.hover {
      background: none;
      padding: 0px;
    }
  }
}
.hire-faster {
  background: #fffaeb;
  border-radius: 6px;
  padding: 10px;
}
.exp-fresher {
  background: #ebf9ff;
  border-radius: 6px;
  padding: 10px 20px;
}
.create-profile-step {
  top: 0px;
  width: 30%;
  padding: 30px !important;
  position: fixed;
  bottom: 0px;
}
.job-search-chip {
  background-color: #F2F4F7;
  border-radius: 20px;
  padding: 7px;
  color: #363F72;
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-500) !important;
}
.create-skill-badge-block {
  .badge {
    border: 1px solid #002962;
    border-radius: 10px;
    color: #262626;
    background-color: #F7F7FB;
    padding: .1rem .8rem;
    align-items: center;
    justify-content: center;
    margin: .1em .5em 0 0;
    line-height: 1.9;
    height: auto;
    display: inline-flex;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: .8rem;
    white-space: inherit;
    font-weight: normal;
  }
  .close {
    opacity: 0.6;
    font-size: 1.5em;
    color: #58595b;
    position: relative;
    z-index: 1;
    margin-top: 0;
    margin-left: 7px;
  }
}
.notify-badge{
  .MuiBadge-badge {
    background-color: #FFC6C6 !important;
    color: #1D2939 !important;
    border-radius: 50% !important;
    min-width: 20px !important;
    min-height: 20px !important;
    padding: 2px 2px !important;
    font-size: 8px !important;
    font-weight: var(--font-weight-600);
    cursor: pointer;
  }
}
.MuiBadge-badge {
  background-color: #FFFFFF !important;
  color: #002962 !important;
  border-radius: 50% !important;
  min-width: 20px !important;
  min-height: 20px !important;
  padding: 2px 2px !important;
  font-size: var(--font-size-10) !important;
  cursor: pointer;
}
.notify-align{
  .MuiBadge-badge{
    background-color: rgba(0, 41, 98, 1) !important;
    color: #FFFFFF !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    padding: 2px 2px !important;
    font-size: 8px !important;
    top:3px !important;
    right: 3px !important;
  }
}
.pending-alerts-badge {
  background-color: #FFFAEB;
  color: #B54708;
  padding: 4px 12px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  width: 41px;
  height: 26px;
  margin: 5px;
  border-radius: 20px;
}

// stepper changes
.save-job-stepper {
  display: flex;
  justify-content: end;
  .MuiStepConnector-root {
    .MuiStepConnector-line {
      display: none !important;
    }
  }
  .MuiStepper-root {
    .MuiStep-root {
      .MuiButtonBase-root {
        .MuiStepLabel-root {
          .Mui-active {
            svg {
              color: var(--primary-color) !important;
              font-size: var(--font-size-24) !important;
              .MuiStepIcon-text {
                fill: $white-color !important;
              }
            }
          }
          .Mui-completed {
            svg {
              color: var(--primary-color) !important;
              font-size: var(--font-size-24) !important;
              .MuiStepIcon-text {
                fill: $white-color !important;
              }
            }
          }
          .MuiStepLabel-iconContainer {
            svg {
              color: var(--secondary-color);
              font-size: var(--font-size-24) !important;
              .MuiStepIcon-text {
                fill: var(--gray9);
              }
            }
          }
        }
      }
    }
  }
}
.my-job-connector {
  .MuiStepConnector-root {
    .MuiStepConnector-line {
      height: 2px !important;
      width: 100% !important;
      margin-left: 10px;
      border-radius: 2px;
    }
  }
  .MuiStep-root {
    .MuiStepLabel-label {
      color: var(--gray7) !important;
      font-size: var(--font-size-14) !important;
      font-weight: var(--font-weight-500) !important;
    }
  }
}
.MuiStepConnector-vertical {
  text-align: initial;
  // margin-left: 8px !important;
  left: -3px !important;
}
.stepper-tab-align {
  .MuiBox-root {
    // padding-top: 10px !important;
    padding-right: 0px !important;
  }
}
.stepper-align {
  // margin-top: 20px !important;
}
.stepperUser {
  // top:50%;
  // left:50%;
  position: absolute;
  transform: translate(-50%, -105%);
  width: 105px;
  height: 45px;
}
.horizontal-stepper-align {
  .Mui-active {
    p {
      color: var(--primary-color) !important;
    }
  }
}
.joblist-connector {
  padding-left: 15px !important;
  .MuiStepConnector-root {
    .MuiStepConnector-line {
      height: 6px !important;
      width: 100% !important;
      border-radius: 2px;
    }
  }
  .MuiStep-horizontal{
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}

// drawer changes
.joblist-drawer {
  .MuiDrawer-paper {
    width: 70% !important;
  }
}
.exercise-drawer {
  .MuiDrawer-paper {
    width: 60% !important;
  }
}
.hideScroll {
  .MuiDrawer-paper {
    overflow-y: hidden !important;
  }
}
.notification-drawer{
  z-index: 1300 !important;
}
.notification-drawer .MuiDrawer-paper {
  width: 30% !important;
  overflow-y: hidden;
  //top: 70px;
}
.MuiDrawer-modal{
  z-index: 9999999999;
}

// tab responsive view
@media screen and (min-width: 320px) and (max-width: 767px) {
  .material-tab {
    .MuiTabs-root {
      .MuiTabs-indicator {
        height: 8px;
        background: #0071e3;
        /*  border-radius: 10px;*/
      }
      .MuiTab-root {
        width: 110px;
      }
    }
  }
}
.privacy-tabs{
  padding-left: 48px;
  .profile-tabs{
    .MuiTabs-flexContainer {
      border-bottom: none;
      .Mui-selected {
        color: var(--primary-color) !important;
        font-weight: var(--font-weight-600) !important;
        font-family: var(--font-family) !important;
      }
    }
    button{
      padding: 0px !important;
      color: #000 !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      font-family: var(--font-family) !important;
    }
  }
}
.application-tabs {
  .MuiTabs-root {
    .MuiTabScrollButton-root {
      display: none !important;
    }
  }
  .MuiTabs-flexContainer {
    width: 100%;
    display: flex;
  }
  button {
    text-transform: none !important;
    font-family: var(--font-family);
    min-height: 50px;
    position: relative;
    padding: 5px 20px;
    width: 50%;
    min-width: 50%;
    text-align: left;
    background: #EEEEEE;
    border: 1px solid #D2D6DC;
    border-radius: 4px !important;
    flex-direction: row;
    justify-content: flex-start;
    &.resume-tab {
      clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 50%, calc(100% - 14px) 100%, 0 100%);
      background: var(--primary-color);
      border: none !important;
      z-index: 1;
      .resume-label{
        position: absolute;
        top: 0.8px;
        left: 0.8px;
        right: 0.8px;
        bottom: 0.8px;
        background: var(--secondary-color);
        clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 50%, calc(100% - 14px) 100%, 0 100%);
        padding: 4px 30px;
        border-radius: 4px;
      }
    }
    &.additional-tab {
      clip-path: polygon(0 0, 14px 50%, 0 100%, 100% 100%, 100% 0);
      background: #D2D6DC;
      border: none !important;
      z-index: 1;
      // margin-left: -4px !important;
      .additional-label{
        position: absolute;
        top: 1px;
        left: 1.5px;
        right: 1px;
        bottom: 1px;
        background: #FFFFFF;
        clip-path: polygon(0 0, 14px 50%, 0 100%, 100% 100%, 100% 0);
        padding: 4px 30px;
        border-radius: 4px;
      }
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
  .Mui-selected {
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    .job-flow {
      color: var(--primary-color) !important;
    }
    &.additional-tab {
      clip-path: polygon(0 0, 14px 50%, 0 100%, 100% 100%, 100% 0);
      margin-left: 0px;
      background: var(--primary-color);
      border: none !important;
      z-index: 1;
      .additional-label{
        position: absolute;
        top: 0.8px;
        left: 1px;
        right: 0.8px;
        bottom: 0.8px;
        background: var(--secondary-color);
        clip-path: polygon(0 0, 14px 50%, 0 100%, 100% 100%, 100% 0);
        padding: 4px 30px;
        border-radius: 4px;
      }
    }
  }
}
.active-tab{
  .application-tabs {
    .resume-tab {
      .resume-label{
        background: var(--primary-color) !important;
        border: 1px solid var(--primary-color) !important;
      }
      border: 1px solid var(--primary-color) !important;
      .job-flow{
        color: #FFFFFF !important;
      }
    }
  }
}
.active-tab-two{
  .application-tabs {
    .second-tab {
      background: var(--primary-color) !important;
      border: 1px solid var(--primary-color) !important;
      .job-flow{
        color: #FFFFFF !important;
      }
    }
  }
}


























