[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.MuiPaper-root .MuiMenu-list .all-notify:focus {
  background-color: #FFFFFF !important;
}

.socialLogin {
  padding: 5px 14px;
  cursor: pointer;
  border: 1px solid var(--gray6);
  border-radius: 6px;
}
.info {
  background: rgb(45, 169, 189, 0.2);
  border-radius: 10px;
  color: $info-color;
  font-size: var(--font-size-14);
  padding: 7px 20px;
}
.footerIcon {
  padding: 6px;
  // color: $white-color;
  // background: $grey-color;
  // border-radius: 50%;
  // width: 28px !important;
  // height: 28px !important;
  cursor: pointer;
}
.footerIconTwitter {
  width: 46px !important;
  height: 46px !important;
  margin: -9px;
  cursor: pointer;
}
.verify {
  background: rgba(34, 166, 64, 0.2);
  border-radius: 10px;
  padding: 7px 20px;
}
.policy .info {
  background: $white-color;
  border-radius: 10px;
  // border: 1px solid #EAE9F2;
  margin-top: 70px;
  padding: 20px;
  color: $black-color;
}
.findJobs {
  background: $bg-white;
  box-shadow: inset 0 1px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  padding: 17px 45px;
  margin-top: 74px;
  position: relative;
  width: 100%;
  z-index: 1;
}
.filterJobs {
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: $bg-white;
}
.jobsForm {
  width: 297px;
  background: $bg-white;
  border: 1px solid #eeeeee;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
  border-radius: 5px;
  padding: 10px;
}
.jobTitle {
  background: #ffffff;
  /*  border-radius: 3px 3px 0 0;
      border: 1px solid #EAE9F2;*/
  // align-items:center;
}
.completeScore svg {
  width: 90px !important;
  margin-left: 75px;
}
.contrast-profile {
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
}
.descriptionEllipse {
  /*   overflow: hidden;
       text-overflow: ellipsis;
       display: block;
       white-space: nowrap;*/
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
  height: 40px;
}
.onbording-section {
  height: 100vh;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  display: flex;
}
.file-extension-image {
  width: 15%;
  height: 67px;
  object-fit: cover;
  padding-bottom: 10px;
}
.file-extension-image-new {
  transform: scale(0.75);
  object-fit: cover;
}
.profileBgImage {
  /*  background-image: url('/assets/images/profile-bg.png');*/
  width: 100%;
  background-size: cover;
  height: 100%;
  background-position: top;
  position: relative;

  /* margin-top: 74px;*/
  /*  .bg-image{
          object-fit: cover;
      }*/
  .bg-image {
    border-radius: 16px 16px 0px 0px;
  }
}
.updateProfile {
  position: absolute;
  top: 73px;
  left: 3px;
  width: 95%;
  height: 37px;
}
.updateImage {
  position: absolute;
  top: -42px;
  left: 44px;
  color: #fff;
  cursor: pointer;
  width: 20px;
}
.editIcon {
  border-radius: 50%;
  cursor: pointer;
  margin: 20px 20px 120px 20px;
  padding: 4px 11px;
  width: 35px !important;
  height: 35px !important;
  background-color: #fff;
  // object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.profileImage {
  width: 111px;
  border-radius: 50%;
  height: 111px;
  object-fit: cover;
}
/*.image-cropper-section{
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    .ReactCrop--fixed-aspect{
        max-height: 100%;
        max-width: 100%;
    }
    .ReactCrop--fixed-aspect{
        div{
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            display: flex;
        }
    }
    .ReactCrop__image{
        max-height: 100%;
        max-width: 100%;
    }
}*/
.addprofilepic {
  border-radius: 50%;
  cursor: pointer;
  /* margin: 20px;*/
  padding: 8px;
  width: 45px !important;
  height: 45px !important;
  background-color: #f1f1f1;
  color: #696871;
}
.uploadImage {
  position: absolute;
  top: -46px;
  left: 23px;
  color: #fff;
  cursor: pointer;
  font-size: var(--font-size-20) !important;
}
.publicProfile {
  background: rgba(255, 255, 255, 0.8);
  // background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 12px 16px;
  position: absolute;
  top: 25px;
  right: 30px;
  color: #333333;
}
.suggestion-item {
  padding: 7px 20px 7px 20px !important;
}
.suggestion-item--active {
  padding: 7px 20px 7px 20px !important;
}
.MuiPickersBasePicker-container {
  .MuiPickersToolbar-toolbar {
    background-color: var(--primary-color) !important;
  }
  .MuiTypography-colorPrimary {
    color: var(--primary-color) !important;
  }
}
.MuiPickersModal-dialogRoot {
  .MuiButton-textPrimary {
    color: var(--primary-color) !important;
  }
}
.MuiPickersCalendar-week {
  .MuiPickersDay-daySelected {
    background-color: var(--primary-color) !important;
  }
  .MuiPickersDay-current {
    // color:var(--primary-color);
    &:focus {
      color: #fff !important;
    }
  }
}
.MuiBox-root {
  .resumePrefillProgress {
    &.MuiLinearProgress-determinate {
      background-color: var(--gray4) !important;
      border-radius: 10px;
    }
  }
}

.MuiBox-root {
  .MuiLinearProgress-determinate {
    background-color: var(--secondary-color) !important;
    border-radius: 4px;
    height: 7px;
  }
}

.MuiLinearProgress-root {
  .MuiLinearProgress-bar {
    background-color: var(--primary-color) !important;
  }
}
.round-image {
  border-radius: 50%;
}
.bg-privacy {
  // background: #2DA9BD;
  background: $privacy-bg;
  color: #1f1f1f;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-12);
  padding: 17px 30px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 99;
  .button-white {
    background: #2da9bd !important;
  }
  .accept {
    color: #1f1f1f !important;
    font-weight: bold;
    text-decoration-color: #1f1f1f !important;
  }
}

.MuiBox-root {
  .MuiSlider-sizeMedium {
    height: 2px !important;
    color: var(--primary-color) !important;
    margin-left: 5px !important;
    width: 97% !important;

    .MuiSlider-thumbColorPrimary {
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.acceptStatus,
.declineStatus,
.pendingStatus,
.appliedStatus,
.expiredStatus,
.acceptedStatus,
.priorityStatus {
  // border-radius: 7px;
  border-radius: 20px;
  padding: 4px 15px;
}

.priorityStatus {
  background: #eae6ff;
}

.acceptStatus {
  background: #e3fcef;
  color: #027a48 !important;
}

.acceptedStatus {
  background: #ebf9ff;
  color: #1973B9 !important;
}

.declineStatus {
  color: #b42318 !important;
  background: #fef3f2;
}

.pendingStatus {
  background: rgba(242, 192, 41, 0.2);
  color: #b54708 !important;
}

.expiredStatus {
  background-color: #f8f9fc;
  color: #363f72 !important;
}

.appliedStatus {
  background: #f9f5ff;
  color: #6941c6 !important;
}

.pendingDashedColor {
  color: rgba(242, 192, 41, 0.2) !important;
}
.acceptedDashedColor {
  color: #A3A3A3 !important;
}
.completedDashedColor {
  color: #228B22 !important;
}
.cancelledDashedColor {
  color: #B42318 !important;
}
.toBeScheduledDashedColor {
  color: #E4E7EC !important;
}
.pendingInterviewDescription {
  border: 1px dashed #FFCA0E;
  border-radius: 8px;
  padding: 8px;
}
.upcomingInterviewDescription {
  border: 1px dashed #8CE784;
  border-radius: 8px;
  padding: 8px;
}

.login-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  /* margin: 0 30px !important;*/
  background: #ffffff;
}

/*.rightChevron{
    padding:7px 3px 0 3px;
}*/
.profileButton {
  padding: 5px 30px !important;
}
.aiButton {
  padding: 6px 12px !important;
}
.searchFilter {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 18px 24px !important;
  // border-bottom: 1px solid #E4E4E7;
}

.singleCompanyOption {
  padding: 10px 24px !important;
}

.logoImage-section {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 50px;
}

.registration {
  padding-right: 130px !important;
}

.descriptionEllipse {
  height: 50px;
  width: 100%;
  line-height: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.description-section {
  ul {
    padding-left: 0px !important;

    li {
      list-style-type: none !important;
    }
  }

  p {
    margin: 0px !important;

    span {
      color: #696871 !important;
      font-size: var(--font-size-14) !important;
      word-break: break-all;
    }
  }
}

.editProfileBullet {
  .MuiSvgIcon-root {
    font-size: 7px !important;
    margin: 0 5px;
  }
}

.profileCoverBullet.MuiSvgIcon-root {
  font-size: 5px !important;
}

.policyBullet {
  .MuiSvgIcon-root {
    color: rgba(31, 31, 31, 0.6) !important;
    font-size: 7px !important;
  }
}

.CircularProgressbar {
  .CircularProgressbar-trail {
    // stroke: #d6d6d6;
    stroke: rgba(34, 166, 64, 0.2) !important;
  }
}

.jobProfile {
  img {
    width: 50px;
    height: 50px;
  }
}

.jobsFound {
  .MuiOutlinedInput-root {
    svg {
      right: 0px !important;
      // cursor: pointer !important;
      // pointer-events: all !important;
    }
    .MuiSelect-select {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 0px !important;
      background-color: transparent !important;
      padding-right: 10px !important;
      margin-left: 4px !important;
    }
  }
}
.myjobs {
  img {
    width: 70px;
    height: 70px;
  }
}
.findjobs {
  img {
    width: 50px;
    height: 50px;
  }
}
.workDocument {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    width: 400px !important;
  }
}
.documentAlign {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
}
.logoImg-header {
  width: 140px;
  height: 38px;
  object-fit: contain;
  padding: 0px;
}
.logoImg {
  width: 200px;
  height: 50px;
}
.logoWord {
  height: 70px;
  width: 115px;
  margin-left: 5px;
  padding-bottom: 5px;
}
.notification-body {
  max-height: 75vh;
  overflow-y: hidden;
  overflow-x: hidden;
  cursor: context-menu !important;
  .MuiMenuItem-root {
    cursor: context-menu !important;
  }
  .notification-content {
    max-height: 58vh;
    overflow-y: auto;
  }
}
.notification-body:focus {
  background-color: #FFFFFF !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(-1% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(-1% + 1px) !important;
}
// .react-multi-carousel-item{
// width:255px !important;
// }
.MuiButtonBase-root {
  .MuiChip-label {
    white-space: break-spaces !important;
  }
}
.availability-assess {
  margin-right: 101px !important;
}
.assessment-details {
  margin-left: -7px !important;
  .Mui-checked {
    color: var(--primary-color) !important;
  }
}
.university-name {
  input {
    // padding-left: 10px;
  }
}
.messaging {
  border-left: 1px solid var(--gray4);
}
#demo-simple-select {
  width: 100%;
  padding: 10px 25px 10px 0px !important;
  color: rgba(31, 31, 31, 0.6);
  background: #eff0f6;
  padding: 15px;
  font-size: 13px;
  border: none;
  &:focus-visible {
    border: none;
    outline: none;
  }
}
.message-pdf {
  background: var(--gray3);
  border: 1px solid var(--gray5) !important;
  border-radius: 4px;
}
.message-pdfSend{
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  color: var(--gray1);
  a{
    color: var(--gray1);
  }
}
.message-pdfReply{
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  color: var(--primary-color);
  a{
    color: var(--primary-color);
  }
}
.file-close {
  border-radius: 50%;
  padding: 5px;
  width: 19px;
  height: 19px;
}
.list-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  width: 150px;
}

.job-count {
  border-radius: 50% !important;
  min-width: 17px !important;
  min-height: 17px !important;
  font-size: 9px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  color: white;
  font-weight: var(--font-weight-500) !important;
}

// .container-scroll{
//     height: 100vh !important;
//     overflow-y: auto !important;
// }
.select-date {
  .MuiInputBase-root {
    width: 100% !important;
  }
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: var(--font-size-20);
  dominant-baseline: middle;
  text-anchor: middle;
}
.recommendJob-detail {
  // padding: 80px 100px 0px 100px !important;
  padding: 80px 45px 50px 0px !important;
}
.skills-lowercase {
  span {
    text-transform: lowercase !important;
  }
}
.rating-progress {
  .MuiLinearProgress-determinate {
    height: 15px !important;
    border-radius: 4px !important;
    background: #eff0f6 !important;

    .MuiLinearProgress-bar {
      border-radius: 0px !important;
    }
  }
}
.delete-btn {
  margin-left: 10px;
  background: white;
  padding: 3px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.filter-jobs {
  background: #ffffff !important;
  position: sticky;
  width: 100%;
  top: 76px;
  padding: 13px 13px !important;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%);
}

.sticky-issue {
  position: sticky;
  z-index: 1;
  top: 76px;
  width: 100%;
}

.MuiBox-root {
  .progressbarColor {
    &.MuiLinearProgress-determinate {
      background-color: rgba(25, 115, 185, 0.2) !important;
      border-radius: 4px;
      height: 7px;
    }
  }
}

.MuiLinearProgress-root {
  &.progressbarColor {
    .MuiLinearProgress-bar {
      background-color: rgba(25, 115, 185, 1) !important;
    }
  }
}

.ReactCrop__image {
  min-width: 500px;
}

#scrollableDiv {
  height: 950px !important;
}

#scrollableDiv {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

#scrollableDiv::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.ReactCrop__image {
  min-width: 230px;
  max-width: 230px;
  top: 0px !important;
  left: 0px !important;
  transform: rotate(0deg);
  transform-origin: 115px 115px;
}

/* new cropper plugin css for profile picture circular */
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #fff;
  outline: 1px dashed #fff !important;
  outline-color: #fff !important;
  border: 1px dashed #fff;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.cropper-point.point-se {
  top: calc(85% + 1px);
  right: 14%;
}

.cropper-point.point-sw {
  top: calc(85% + 1px);
  left: 14%;
}

.cropper-point.point-nw {
  top: calc(15% - 5px);
  left: 14%;
}

.cropper-point.point-ne {
  top: calc(15% - 5px);
  right: 14%;
}

.cropper-bg {
  background-image: none !important;
}

.cropper-modal {
  background-color: rgba(0, 0, 0, 0.2) !important;
  opacity: 1 !important;
  border-radius: 4px !important;
}

.cropper-line {
  border: 5px solid red;
  background-color: #696871 !important;
}

// .cropper-modal {
//   background-color: #000;
//   opacity: 0 !important;
// }

.cropper-point {
  background-color: #ffff !important;
  opacity: 0.9 !important;
}

.play-icon {
  position: absolute;
  align-items: center;
  padding: 30px 30px;
  width: 78px;
}

.nps-rating {
  display: flex;
}

.nps-rating-cell {
  width: 40px;
  height: 40px;
  border: 1px solid black;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nps-rating-cell-active {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
}

.nps-rating-cell:hover {
  cursor: pointer;
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
}

.licence-number {
  .form-control:focus {
    box-shadow: none !important;
  }
  ::placeholder {
    color: var(--gray7) !important;
    font-weight: normal !important;
    opacity: 0.6 !important;
  }
}

.phone-code {
  .select-option-section {
    position: relative;
  }

  .MuiList-root {
    position: absolute;
    height: 200px !important;
  }
}

.NPS-status {
  height: 7px;
  width: 7px;
  background-color: red;
  border-radius: 100%;
}

.terms-content {
  ul {
    li {
      margin-top: 10px;
    }
  }
}


.preview-job {
  margin: 60px !important;
  padding: 35px !important;
}

.MuiInputBase-colorPrimary:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  left: 0;
  bottom: 0;
  content: "";
  /* position: absolute; */
  right: 0;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
  border: none;
}

.launch-page-card {
  .MuiCardActionArea-root:hover {
    /* background-color: #FFFFFF !important;*/
    cursor: default;
  }
  span {
    background-color: inherit !important;
  }
}

// .css-6hp17o-MuiList-root-MuiMenu-list li:focus {
//     outline: 2px solid black !important;
// }

.analytics-label {
  display: block !important;
  margin-top: 2px;
  font-size: var(--font-size-12) !important;
}

.et_pb_video_box {
  display: block;
  position: relative;
  z-index: 1;
  line-height: 0;
}

.et_pb_video_box video {
  width: 100% !important;
  height: auto !important;
}

.et_pb_video_overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}

.et_pb_video_overlay_hover {
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  transition: all 0.5s ease-in-out;
}

.et_pb_video_0 .et_pb_video_overlay .et_pb_video_play {
  color: RGBA(0, 0, 0, 0);
}

.et_pb_video_play {
  display: block;
  position: absolute;
  z-index: 100;
  color: #fff;
  left: 50%;
  top: 50%;
}

.et_pb_video_overlay_hover {
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  transition: all 0.5s ease-in-out;
}

.et_pb_video_overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}

.patient-rights {
  border-top: 1px solid rgba(105, 104, 113, 0.2);
  border-bottom: 1px solid rgba(105, 104, 113, 0.2);
  background: rgba(105, 104, 113, 0.07);
  padding: 10px 20px;
}

.edit-bg {
  padding: 6px 8px !important;
}

.position-issue {
  .MuiTouchRipple-root {
    position: initial !important;
  }

  .MuiOutlinedInput-notchedOutline {
    position: initial !important;
  }

  .Clearicon-issue {
    position: relative;
    right: 20px;
  }
}

.dateScreeningQuesClearIcon {
  font-size: 20px !important;
  margin-right: 10px;
  margin-top: 2px;
  cursor: pointer;
}

.Padding-issue {
  padding: 0 60px 0 60px !important;
}

.draft-count {
  background-color: #FFC6C6 !important;
  color: #1D2939 !important;
  border-radius: 50% !important;
  min-width: 20px !important;
  min-height: 20px !important;
  padding: 4px 8px !important;
  font-size: 8px !important;
  font-weight: var(--font-weight-600);
  cursor: pointer;
}
.draft-count-interview{
  background-color: #FFC6C6 !important;
  color: #1D2939 !important;
  border-radius: 50% !important;
  min-width: 20px !important;
  min-height: 20px !important;
  //padding: 4px 8px !important;
  font-size: 8px !important;
  font-weight: var(--font-weight-600);
  cursor: pointer;
  left: 90px;
  top: -4px;
}

.jobDetail-align {
  margin: 100px 80px 40px 40px !important;
}

.jobDetail-image {
  img {
    width: 100px !important;
    height: 100px !important;
  }
}

.public-profile-download {
  border-bottom: 1px solid lightgrey;
  padding: 5px;
  margin-bottom: 10px;
}

#form-response {
  padding: 10px;
}

.signed-document {
  img {
    background: #696871;
    border-radius: 10px;
    padding: 7px;
    width: 40px !important;
    height: 40px !important;
    margin-top: 3px !important;
  }
}

.acceptOffer-status {
  background: #e3fcef;
  border-radius: 6px;
}



.resume-upload {
  background: #f9fafb;
  border-radius: 4px;
  padding: 5px 10px !important;
}

.share_job_image {
  position: relative;
  top: -2px;
}

.job-logo {
  img {
    border: 1px solid #ebebeb;
    border-radius: 4px;
    // padding: 5px;
  }
}

.payrate-count {
  // background: rgba(234, 234, 234, 0.6);
  border-radius: 5px;
  // padding: 10px;
  color: #18181b;
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-600);
}

.message-window {
  height: 330px;
  overflow-y: auto;
}

.message-height {
  height: 600px;
  overflow: auto;
}

.checklist-section {
  //max-width: 170px;
  max-height: 200px;
  overflow-y: auto;
}
.interview-prep{
  max-height: 250px;
  overflow-y: auto;
}
.find-job-search {
  margin: 10px 0 8px 0 !important;
  justify-content: end;
  padding: 0px 5px 0px 10px;
  border-radius: 0 6px 6px 0;
}

.profile-radius {
  background: $bg-white;
  border: 1px solid var(--gray4);
  border-radius: 0px 0px 8px 8px;
}

.portfolio-link {
  li .link-icon {
    background: #ffeeef;
    padding: 6px;
    width: 30px;
    border-radius: 50%;
  }
}

.edu-icon {
  img {
    background: var(--secondary-color);
    padding: 8px;
    width: 34px;
    border-radius: 50%;
  }
}

.edu-documents {
  li {
    list-style: none !important;
    margin-top: 20px !important;

    a {
      border: 1px solid rgba(128, 129, 145, 0.4);
      border-radius: 8px;
      text-decoration: none !important;
      padding: 8px;
      color: var(--gray11);
      font-size: var(--font-size-14) !important;
    }
  }
}

.work-history {
  img {
    background: #f0eeff;
    padding: 8px;
    width: 34px;
    border-radius: 50%;
  }
}



.reference-icon {
  img {
    background: #fdeeff;
    padding: 8px;
    width: 34px;
    border-radius: 50%;
  }
}

.my-search-collapse {
  transition: height 0.25s ease;
  height: 0;
  overflow: hidden;
}

.my-search-collapse.show {
  height: 150px;
}

.active-message {
  background-color: var(--secondary-color);
}

.message-text {
  background-color: var(--primary-color);
  color: var(--gray1);
  border-radius: 6px 0px 6px 6px;
  line-height: normal;
}

.message-text-right {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 0px 6px 6px 6px;
  line-height: normal;
}

.uploaded-video {
  svg {
    background: #f0eeff;
    padding: 5px;
    width: 30px;
    border-radius: 4px;
    height: 30px;
  }
}

.skill-job {
  img {
    background: #d6f6de;
    padding: 7px;
    width: 30px;
    border-radius: 50%;
  }
}

.work-place {
  img {
    background: #fef3f2;
    padding: 7px;
    border-radius: 50%;
  }
}

.work-type {
  img {
    background: #e4ffef;
    padding: 7px;
    border-radius: 50%;
  }
}

.settings-sidebar {
  li {
    list-style: none;

    div {
      padding: 14px;
      display: flex;

      &.mui-selected {
        background-color: var(--secondary-color);

        p {
          color: var(--primary-color);
        }
      }
    }
  }
}

.global-search-input {
  border: 1px solid #98a2b3;
  border-radius: 12px;
  padding: 5px 10px;
}

.horizontal-line {
  height: 0.2px;
  flex: 1;
  background-color: var(--gray4);
}

// .notification-default-icon {
//   background-color: var(--primary-color);
//   border-radius: 50%;
//   width: 35px;
//   height: 35px;
//   padding: 5px;
// }

.radio-button-font {
  .MuiTypography-root {
    font-size: var(--font-size-14) !important;
    padding: 0px 15px 0px 0px;
  }
}

.settings-sidebar-fix {
  position: fixed;
  top: 4.3em;
  width: 40%;
  z-index: 2;
}
#settings-screen{
  padding-top: 85px;
}
.settings-body {
  //padding-top: 50px;
  width: 60%;
  position: absolute;
  right: 0;
  padding-right: 8em;
}

.notification-body-width {
  z-index: 9999999999 !important;
  .MuiPopover-paper {
    top: 25px !important;
    overflow: hidden !important;
    max-height: 95vh;
    .MuiMenu-list{
      padding-bottom: 0px !important;
      max-height: 650px;
    }
  }
}
.notification-body-width{
  .MuiPaper-root{
    .MuiList-root{
      .MuiButtonBase-root:focus{
        background-color: var(--primary-color) !important;
        .focus-text{
          color: #ffffff !important;
        }
      }
      .all-unread-button-selected:hover {
        background-color: var(--hover-button-color) !important;
      }
    }
  }
}
.privacy-mobile {
  position: fixed;
  bottom: 0px;
  width: 100%;
  // margin-bottom: 5px !important;
  background: #ffffff !important;
  left: 0px !important;
}

.certify-public {
  .profile-certification {
    padding-left: 0px !important;
    margin-top: 15px !important;
  }
}

.min-width {
  min-width: 100%;
}

.search-bar-icon {
  .MuiMenu-paper {
    top: 60px !important;
    left: 0 !important;
    border-radius: 0 0 24px 24px !important;
  }
}


.sticky-filter {
  position: absolute;
  z-index: 12;
}

.jobsearch-section {
  padding-top: 35px;
  padding-bottom: 20px;
}
.pagenation-joblist {
  .MuiPagination-root {
    ul {
      li {
        // margin-top: 8px !important;
        .MuiPaginationItem-previousNext {
          .MuiTouchRipple-root {
            background-color: transparent;
          }
        }

        .MuiButtonBase-root {
          border: 0px solid rgba(25, 118, 210, 0.5);

          color: var(--gray7);
        }
      }

      .MuiPaginationItem-root {
        border-radius: 8px;

        &.Mui-selected {
          background: var(--secondary-color) !important;

          color: var(--primary-color);
        }
      }

      .MuiPaginationItem-page {
        border: 0px solid rgba(25, 118, 210, 0.5) !important;
      }

      .MuiPaginationItem-icon {
        display: flex;
        align-items: center;
        margin: 0 !important;
      }
    }
  }
}
.count-jobs {
    background: var(--secondary-color);
    border-radius: 8px;
    padding: 7px 8px 5px 8px;
    color: var(--primary-color);
}
.jobsearch-section{
    padding-top: 35px;
    padding-bottom: 20px;
}
.feedback-width {
  width: 600px !important;
}
.MuiStepLabel-iconContainer .Mui-active {
  color: var(--primary-color) !important;
}
.Mui-active .MuiStepConnector-line {
  background-color: var(--primary-color);
}
.basic-stepper-align{
  .Mui-active .MuiStepConnector-line {
    background-color: #FFFFFF !important;
  }
}
.section1 {
  border-radius: 1.875rem;
  background-color: var(--secondary-color);
  padding: 5rem 0.9rem;
}
.section2 {
    content: "";
    height: 5px;
    width: 4.3rem;
    border-radius: 1.8rem;
    background-color: var(--primary-color);
    margin-top: 10px;
}
// pagination hover css
.css-188v5gy-MuiPaginationItem-icon {
   margin: 0 !important;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
  // padding: 0 14px;
}
.profile-complection-section{
  background-color: #FFFAEB;
  padding: 20px;
}
.btn-accessibility {
  position: relative;
  right: 0;
  z-index: 10;
  .crossIcon-bionic {
    padding: 6px !important;
    width: 40px;
    height: 40px;
    background-color: $gray11;
    border-radius: 50%;
    cursor: pointer;
  }
}
.btn-accessibility:hover {
  // content: url("/assets/images/accessiblity-onhover.svg");
  position: fixed;
  top: 50%;
  right: 0px;
  z-index: 2;
  // .icon-display {
  //   display: none;
  // }
  // .icon-display1{
  //   display: block;
  //   position: fixed;
  //   top: 51.1%;
  //   right: 8px;
  //   z-index: 2;
  // }
}
.accessibility-new {
  position: fixed;
  top: 50%;
  right: 19px;
  width: 45px;
  z-index: 2;
  // z-index: 999999999;
  //z-index: 999999999999 !important;
}
.icon-display {
  display: block;
}
.icon-display1 {
  display: none;
}
.bionic-popover{
//   background: #FFFFFF;
// box-shadow: 0px 3px 8px rgba(149, 136, 136, 0.25);
// border-radius: 4px;
padding: 5px 15px 5px 15px;
}
.privacy-neuro {
  position: fixed;
  bottom: 20px;
  width: 100%;
  // margin-bottom: 5px !important;
  //background: #ffffff !important;
  left: 0px !important;
}
.neuro-diversity-background{
  background-image: url('/assets/images/Neurodiversity-background.svg');
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: top left;
// background-position: center;
// background-repeat: no-repeat;
// background-size: cover;
// top: 0;
// bottom: 0;
// //position: absolute;
// width: 100%;
}
// .login-bg-neuro {
//   position: relative;
//     height: 100%;
//     width: 100%;
// }
 .neuro-section{
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // z-index: 99;
  height: 100vh;
    max-width: 100wh !important;
    align-items: center;
    vertical-align: middle;
    display: flex;
 }
.neuro-logo-image{
  position: absolute;
  top: 0;
  left: 30px;
}

/* Style for Custom Tooltip */
div.tooltip {
  position: absolute;
  text-align: center;
  width: 60px;
  height: 26px;
  padding: 2px;
  font-size: var(--font-size-12);
  font-family: var(--font-family);
  background: #4b4b4bde;
  border: 0px;
  border-radius: 6px;
  pointer-events: none;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
}
.MuiTooltip-popper{
  .MuiTooltip-tooltip {
    font-size: var(--font-size-12);
  }
}
.notification-hover {
  z-index: 1300 !important;
  .MuiPopover-paper .notifymenu-item:hover {
    background-color: #f7f7fb !important;
}
}
.highlight-list-container {
  border: 2px solid red !important;
}
.unread-dot{
  width: 8px;
    height: 8px;
    border-radius: 50%;
    display: flex;
    background-color: var(--primary-color) !important;
}
.notification-hover1 {
  z-index: 99 !important;
}
.interviewStatusPadding {
  padding: 4px 15px !important;
}
.notes-radio {
  margin-right: 0px !important;
}


.acceptedcolor {
  color: #1973B9 !important;
}

.border-left-interview-pending {
  position: relative;
}
.border-left-interview-pending::before {
  content: '';
  position: absolute;
  top: 25%;
  left: 0;
  width: 10%;
  height: 50%;
  border-left: 5px solid #b54708;
}
.border-left-interview-declined {
  position: relative;
}

.border-left-interview-declined::before {
  content: '';
  position: absolute;
  top: 25%;
  left: 0;
  width: 10%;
  height: 50%;
  border-left: 5px solid #B42318;
}
.border-left-interview-completed {
  position: relative;
}

.border-left-interview-completed::before {
  content: '';
  position: absolute;
  top: 25%;
  left: 0;
  width: 10%;
  height: 50%;
  border-left: 5px solid #027a48;
}
.border-left-interview-accepted {
  position: relative;
}

.border-left-interview-accepted::before {
  content: '';
  position: absolute;
  top: 25%;
  left: 0;
  width: 10%;
  height: 50%;
  border-left: 5px solid #1973B9;
}
.interview-link:hover{
  text-decoration: underline !important;
}
.border-left-interview-expired {
  position: relative;
}
.border-left-interview-expired::before {
  content: '';
  position: absolute;
  top: 25%;
  left: 0;
  width: 10%;
  height: 50%;
  border-left: 5px solid #363f72;
}
.border-left-interview-details-pending {
  border-left: 3px solid #b54708 !important;
}
.border-left-interview-details-declined {
  border-left: 3px solid #B42318 !important;
}
.border-left-interview-details-completed {
  border-left: 3px solid #027a48 !important;
}
.border-left-interview-details-accepted {
  border-left: 3px solid #1973B9 !important;
}
.border-left-interview-details-expired {
  border-left: 3px solid #363f72 !important;
}
.assessment-options {
  border: 1.5px solid var(--primary-color);
  padding: 7px 7px;
  width: 35%;
  display: flex;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
}
.options:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
  color: var(--primary-color) !important;
}
.assessment-options-alpha {
  border: 1px solid var(--primary-color);
  padding: 3px;
  border-radius: 3px;
  background-color: #fff !important;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary-color-survey {
  background-color: var(--primary-color);
  color: #fff !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
  color: var(--primary-color)  !important;
}
.btn-position {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
.share-button {
  .amplify-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.responsive-share {
  width: 225px;
 }
 .responsive-qr {
   width: 130px;
   height: 125px;
 }
 .priority-image-status {
  color: #3d3db9 !important;
  background: #e8e8ff !important;
  font-size: 11px !important;
  font-weight: var(--font-weight-600) !important;
  padding: 4px 16px !important;
  border-radius: 20px;
  padding: 8px;
}

.private-image-status {
  padding: 4px 16px;
  border-radius: 24px;
  background-color: #fff4e8;
  color: #b16007 !important;
  font-size: 11px;
  font-weight: var(--font-weight-600);
}
.input-phone-email {
.MuiFormControl-root .MuiOutlinedInput-root {
  padding: 1.8px !important;
}}
.missing-phone-email {
  .MuiFormControl-root .MuiOutlinedInput-root {
    padding: 1.2px !important;
  }}
.analytic-align{
  .button-header{
    padding-left: 0px !important;
  }
}
.form-control{
  &.input-group{
    &.in-valid{
      border: 1px solid $danger-color !important;
    }
  }
}
.border-red-upload-resume {
  border: 1px dashed $danger-color !important;
}
.timer {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    font-size: 12px;
    background: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: 600;
}
.preferred-communication-label {
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: 500;
  .MuiFormControlLabel-label {
    font-weight: 500;
    color: var(--gray7) !important;
    margin-left: 2px;
  }
  .Mui-checked {
    color: var(--primary-color) !important;
  }
  .Mui-checked + .MuiFormControlLabel-label {
    color: var(--primary-color) !important;
  }
}
.pointer-events-none {
  pointer-events: none;
}
.chat-textarea {
  .MuiInputBase-input {
    height: 30px !important;
  }
}
.message-list-unread-count {
  font-size: 10px !important;
  font-weight: 600 !important;
  padding: 5px;
  border-radius: 50px;
  color: white;
  background-color: var(--primary-color);
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.unread-message-primary-color {
  color: var(--primary-color) !important;
}
.messages-file-padding {
  .form-control.input-group {
    padding: 4px !important;
  }
}