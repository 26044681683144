// checkbox changes
.radio-check {
  .MuiRadio-root {
    &.Mui-checked {
      color: var(--primary-color);
      font-size: var(--font-size-12);
    }
    .MuiSvgIcon-root {
      font-size: var(--font-size-18) !important;
    }
  }
  span {
    &.MuiFormControlLabel-label {
      font-size: var(--font-size-14) !important;
      color: var(--gray9)  !important;
      font-weight: var(--font-weight-500) !important;
      padding-bottom: 2px;
    }
  }
}
.assessmentDetails {
  margin-left: -7px !important;

  .MuiCheckbox-root {
    color: #696871 !important;
  }
}
.question-asses {
  .MuiFormControlLabel-root {
    .MuiCheckbox-root.Mui-checked {
      color: var(--primary-color) !important;
    }
  }
}
.safeAge-answer {
  .MuiCheckbox-colorPrimary {
    &.Mui-checked {
      color: var(--primary-color) !important;
    }
  }
}
.assess-question {
  .MuiFormControlLabel-root {
    margin-bottom: 0;
    align-items: flex-start !important;
    .MuiButtonBase-root {
      padding-top: 3px !important;
    }
    .MuiCheckbox-root.Mui-checked {
      color: var(--primary-color) !important;
    }
  }
}

// radioButton changes
.radio-button-class {
  border-top: 1px solid #EAE9F2;
  margin-right: 35px !important;
  .MuiRadio-root {
    &.MuiButtonBase-root {
      display: none;
    }
  }
}
.radio-button {
  .MuiRadio-root {
    color: #667085;
  }
  .MuiFormControlLabel-label {
    font-size: var(--font-size-16) !important;
    color: #1F1F1F;
    padding-left: 10px;
  }
}
.checklist-modal {
  .MuiRadio-root {
    &.Mui-checked {
      color: var(--primary-color) !important;
    }
  }
}
.licencure {
  .MuiGrid-root {
    .MuiFormControl-root {
      .MuiFormGroup-root {
        .MuiFormControlLabel-root {
          .MuiRadio-root {
            padding: 9px !important;
          }
        }
      }
    }
  }
}
.assessment-calender {
  .MuiBox-root {
    .MuiFormControlLabel-root {
      .MuiRadio-root {
        padding: 9px !important;
        color: #667085;
        .Mui-checked {
          color: var(--primary-color) !important;
        }
      }
      .MuiCheckbox-root {
        padding: 9px !important;
      }
    }
  }
}

/*switch-case-toggle*/
.switch-case-section {
  .MuiFormControlLabel-root {
    margin-right: 0px !important;
  }
  .MuiSwitch-root {
    width: 40px;
    height: 37px;
    overflow: hidden;
    padding: 8px 0px;
    .Mui-checked {
      color: #FFFFFF;
      padding: 10px 0px 0px 2px !important;
    }
    .MuiSwitch-track {
      /*  background-color: #002962;
        opacity: 1;*/
      background-color: var(--gray7) !important;
      border-radius: 20px;
    }
    .MuiSwitch-thumb {
      width: 17px;
      height: 17px;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    }
  }

  .MuiSwitch-switchBase {
    padding: 10px 0 0 2px !important;
  }

  .MuiSwitch-switchBase:hover {
    background-color: transparent !important;
  }

  .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: var(--primary-color)  !important;
    opacity: 1;
  }

  .home-page-switch-color {
    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
      background-color: #150349 !important;
      opacity: 1;
    }
  }

  .MuiFormControlLabel-label {
    color: var(--primary-color);
    font-size: var(--font-size-14);
  }
}
.switch-position{
  .MuiSwitch-switchBase{
    position: absolute !important;
    color: #FFFFFF !important;
  }
}