@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// // @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

// @import url('https://fonts.cdnfonts.com/css/amazon-ember');

// // @font-face {
// //   font-family: regularFont;
// //   src: url('../src/Fonts/AmazonEmber_Rg.ttf');
// // }

// // @font-face {
// //   font-family: italicFont;
// //   src: url('../src/Fonts/AmazonEmber_RgIt.ttf');
// // }

// // @font-face {
// //   font-family: BoldFont;
// //   src: url('../src/Fonts/AmazonEmber_Bd.ttf');
// // }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: italic;
//   font-weight: 400;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: normal;
//   font-weight: 250;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Thin.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: italic;
//   font-weight: 250;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Thin Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Light.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: italic;
//   font-weight: 300;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Light Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: normal;
//   font-weight: 500;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Medium.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: italic;
//   font-weight: 500;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Medium Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Bold.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: italic;
//   font-weight: 700;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Bold Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: normal;
//   font-weight: 800;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Heavy.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember';
//   font-style: italic;
//   font-weight: 800;
//   src: local('Amazon Ember'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Heavy Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Cd RC';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Amazon Ember Cd RC'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Cd RC.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Display';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Amazon Ember Display'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Display.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Duospace';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Amazon Ember Duospace'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Duospace.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Mono';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Amazon Ember Mono'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Mono.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember V2';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Amazon Ember V2'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember V2.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Cd RC';
//   font-style: italic;
//   font-weight: 400;
//   src: local('Amazon Ember Cd RC'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Cd RC Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Cd RC';
//   font-style: normal;
//   font-weight: 250;
//   src: local('Amazon Ember Cd RC'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Cd RC Thin.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Cd RC';
//   font-style: italic;
//   font-weight: 250;
//   src: local('Amazon Ember Cd RC'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Cd RC Thin Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Cd RC';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Amazon Ember Cd RC'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Cd RC Light.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Display';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Amazon Ember Display'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Display Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Display';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Amazon Ember Display'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Display Light.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Cd RC';
//   font-style: italic;
//   font-weight: 300;
//   src: local('Amazon Ember Cd RC'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Cd RC Light Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Display';
//   font-style: normal;
//   font-weight: 500;
//   src: local('Amazon Ember Display'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Display Medium.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Display';
//   font-style: italic;
//   font-weight: 500;
//   src: local('Amazon Ember Display'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Display Medium Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Cd RC';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Amazon Ember Cd RC'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Cd RC Bold.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Duospace';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Amazon Ember Duospace'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Duospace Bold.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Mono';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Amazon Ember Mono'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Mono Bold.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember V2';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Amazon Ember V2'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember V2 Bold.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Cd RC';
//   font-style: italic;
//   font-weight: 700;
//   src: local('Amazon Ember Cd RC'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Cd RC Bold Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Display';
//   font-style: italic;
//   font-weight: 700;
//   src: local('Amazon Ember Display'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Display Bold Italic.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Display';
//   font-style: normal;
//   font-weight: 800;
//   src: local('Amazon Ember Display'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Display Heavy.woff') format('woff');
// }

// @font-face {
//   font-family: 'Amazon Ember Display';
//   font-style: italic;
//   font-weight: 800;
//   src: local('Amazon Ember Display'), url('https://fonts.cdnfonts.com/s/67521/Amazon Ember Display Heavy Italic.woff') format('woff');
// }

/**{
  font-family: 'regularFont'!important;
}*/
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: var(--font-family) !important;
  font-size: var(--font-size-16);
  line-height: 1.5;
  background-color: #FFFFFF !important;
  padding: 0;
  overflow-x: hidden;
  padding-right: 0 !important;
  overflow: auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

code {
  font-family: var(--font-family) !important;
}

.header-transition {
  background-color: pink;
  transition-delay: 2s;
  transition-property: height;
  transition-duration: 2s;
  transition-timing-function: linear;
  height: 65px;
  position: absolute;
  width: 100%;
  z-index: 999999;
}