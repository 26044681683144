.css-eg0mwd-MuiSlider-thumb:hover,
.css-eg0mwd-MuiSlider-thumb:focus,
.css-eg0mwd-MuiSlider-thumb:active {
  box-shadow: none !important;
}

.autoSearch .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0px 2px !important;
}

.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  display: none !important;
}
.searchicon {
  width: 40px;
  height: 40px;
  transition: width 1s ease-in, height 1s ease-in;
}
.myCandidate-Ongoing {
  border-radius: 7px;
  padding: 4px 7px;
  background-color: #DEEBFF;
}
.sub-info li a:active {
  color: var(--primary-color) !important;
  font-weight: 700;
}
.rating .MuiRating-icon {
  color: $warning-color  !important;
}
.text-decor {
  text-decoration: underline !important;
  text-decoration-color: #1973B9 !important;
}
.talent-pool-grey {
  color: rgba(18, 18, 18, 0.6);
}

iframe {
  border: none;
  z-index: 9 !important;
  // position: inherit !important;
}
.ReactCrop__crop-selection
{
  border-image-source: none !important;
}
.interviewscount {
    font-size: 14px;
    font-weight: 400;
    color: grey;
  }
  
  .MuiInputBase-inputTypeSearch {
    // border: 1px solid white !important;
  }
  .experience-cont {
    filter: invert(30%) !important;
  }
  .profileName {
    margin-left: 8em !important;
  }
  .hybrid-info {
    color: #6941C6;
    background: #F9F5FF;
    // color: var(--primary-color);
    // background: var(--secondary-color);
    border-radius: 20px;
    padding: 5px;
  }
  
  .contract-info {
    color: #1A7FAE;
    background: #EBF9FF;
    ;
    border-radius: 20px;
    padding: 5px;
  }
  
  .exp-info {
    color: #B54708;
    background: #FFFAEB;
    border-radius: 20px;
    padding: 5px;
  }
  .tile-interview-text {
    color: #6941C6;
    font-weight: 900;
    font-size: 12px;
  }
  .search-background {
    background-color: var(--primary-color);
    svg {
      stroke: #fff;
    }
  }
  .change-password-icon {
    svg {
      font-size: var(--font-size-16);
    }
  }
  .profile-icon {
    img {
      /* height: 70px;
       width: 70px;*/
      color: #2E47CC;
      background: rgba(46, 71, 204, 0.2);
      padding: 16px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  
  .upload-resume-section {
    svg {
      height: 18px;
      width: 18px;
      color: #696871;
      background: #FFFFFF;
      padding: 1px;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 4px 4px rgb(179 179 179 / 15%);
      margin-left: -14px;
      margin-bottom: 55px;
    }
  }
  
  .upload-resume-container {
    border: 1px dashed #696871;
    /*  width: 19em;*/
    padding: 20px;
    height: 9em;
    margin: 25px;
    border-radius: 8px;
  }
  
  .upload-resume-preview {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 590px;
    /* top: 250px;*/
    border: 1px solid #EAE9F2;
    box-sizing: border-box;
    border-radius: 50%;
  
    img {
      margin-top: 10px;
    }
  
    svg {
      position: absolute;
      left: 65.46%;
      right: 26.47%;
      top: -5.03%;
      bottom: 23.97%;
      background-color: #FFFFFF;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
  .back-button {
    svg {
      font-size: 13px;
    }
  }
  .settings-layout {
    margin: 0 30px;
  }
  .analytics-active-color{
    font-size: var(--font-size-14)px;
    padding:7px 20px;
    text-transform: none;
    font-weight: var(--font-weight-500) !important;
    font-family: var(--font-family);
    background-color: var(--secondary-color) !important;
    box-shadow: none;
    color: var(--primary-color) !important;
  }
  .notification-icon{
    .MuiSvgIcon-root{
      font-size: 25px !important;
    }
  }
  .apply-confirm{
    padding-top:20px !important;
  }
  .MuiListItemButton-root {
    &.Mui-selected {
      background: rgba(0, 41, 98, 0.1) !important;
    }
  
    &.Mui-selected a {
      color: #0071e3 !important;
    }
  
    a {
      text-decoration: none !important;
    }
  }
  
  .dashboard-section {
    a {
      text-decoration: none !important;
  
      &.Mui-selected {
        color: #0071e3 !important;
      }
  
      &:active {
        color: #667085 !important;
      }
  
      /*  &:hover{
          text-decoration: underline;
        }*/
    }
  }
  button {
    &.job-apply-btn {
      border: 1px solid var(--primary-color) !important;
      color: var(--primary-color) !important;
    }
  }
  button{
    &.job-apply-btn:hover {
      color: #ffffff !important;
      background-color: #722ecc !important;
    }
  }
  .recommended-job-card {
    .recommended-detail-icon {
      display: none;
    }
    &:hover {
      .recommended-detail-icon {
        display: block !important;
      }
    }
  }
  .job-cards {
    border: 1px solid var(--gray4);
    border-radius: 8px;
   overflow: auto;
    .job-detail-icon {
      display: none;
    }
    &:hover {
      border: 0.5px solid var(--primary-color);
      box-shadow: 0px 18px 14px -20px #00005e;
      border-radius: 8px;
      transition: all .3s ease-in-out;
      animation: pulse 1.5s;
      .job-detail-icon {
        display: block !important;
      }
    }
  }
  .file-cards {
    border: 1px solid var(--gray4);
    border-radius: 8px;
  }
  .job-share {
    svg {
      background: var(--secondary-color);
      border-radius: 6px;
      padding: 12px;
    }
    &:hover {
      svg {
        background: var(--hover-button-color);
      }
      svg>path {
        stroke: var(--hover-button-text-color);
      }
      .fill-svg-icons>path {
        fill: var(--hover-button-text-color);
      }
    }
  }
  .pending-action-section {
    list-style: none;
    padding: 0 11px;
  
    li {
      padding: 5px;
      vertical-align: middle;
      display: flex;
    }
  }
  .pending-action-section li::before {
    //content: "\2022";
    content: "•"; /* HTML entity representation */
    color: var(--primary-color);
    font-weight: bold;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-left: -1em;
    margin-right: 3px;
    margin-top: -4px;
  }
.delete-sub-text {
  margin-top: -8px !important;
  font-size: var(--font-size-14) !important;
  // margin-left: 25px !important;
  color: var(--gray7);
}
.Filter-body {
  max-height: 15em;
  overflow-y: auto;
}
.video-record-image {
  position: absolute;
  width: 45px;
  height: 45px;
  border: 1px solid #667085;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  span {
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: #D04B4B;
    border-radius: 50%;
  }
}
.video-record-stop {
  position: absolute;
  width: 45px;
  height: 45px;
  border: 1px solid #667085;
  padding: 4px;
  cursor: pointer;
  span {
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: #D04B4B;
  }
}
.skills-footer {
  position: fixed;
  bottom: 30px;
}
.menu-container {
  text-align: center;
}
/*Checklist job title background effect for selected*/
.MuiMenuItem-root {
  &.Mui-selected {
    background-color: rgba(25, 118, 210, 0.08) !important;
    :hover {
      background-color: rgba(25, 118, 210, 0.08) !important;
    }
  }
}
.analytic-res-align {
  &:hover {
    background: rgba(68, 91, 199, 0.2) !important;
  }

}

.videoSection-image{
  height: 100%;
  width: 76%;
  top: 0;
  position: absolute;
  /*background-image: url('/assets/images/videoDefaultSection.svg');*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: 52px;
  img{
    position: absolute;
    left: 38px !important;
    border-radius: 14px;
    width: auto;
    height: 250px;
    right:0px !important;
    display:none !important;
  }
}
.video-section {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  /*background: rgba(0, 0, 0, .1);*/
  video{
    width: auto !important;
    height:250px !important;
    border-radius: 14px !important;
    left: 90px !important;
    position: absolute;
    right:0px !important;
  }

}
.profile-video-shadow{
  position: absolute;
  background-color: #1D2939;
  opacity: 0.9;
  width: 332px;
  left: 174px !important;
  top: 309px !important;
  border-radius: 0px 0px 14px 14px;
  right: 0px !important;
}
.overlayImage{
  img{
    position: absolute;
    top: 108px;
    left: -25px;
  }
}
.profileMissingDetails{
  padding-left: 0px !important;
}
.error-msg {
  font-size: var(--font-size-12) !important;
  line-height: 15px;
  display: block;
}
.sub-info li {
  list-style: none;
  padding: 7px 0;
}
.footerNav ul {
  li {
      list-style: none;
      padding-top: 10px;
  }
  a {
      color: #262626;
      text-decoration: none;
      font-size: var(--font-size-14);
  }
}
.upload-resume {
  border: 1px dashed #696871;
  width: 17em;
  padding: 30px;
  height: 10em;
  margin: 25px;
  border-radius: 8px;
}
.candidate-name-width {
  min-width: 86px !important;
  max-width: 150px;
}
.joblist-rating {
  .MuiTypography-root {
      .link-color {
          cursor: default !important;
      }
  }
}
.chatbot-space {
  padding-bottom: 3em !important;
}
.interview-chatbot {
  padding-bottom: 4em !important;
}
.educationBullet .MuiSvgIcon-root {
  font-size: 7px !important;
  margin: 0px 2px 4px 0px;
  color: var(--gray7)
}
.logo-bg-neuro {
  background: #EEEDFE;
  padding:30px !important;
}
.login-bg {
  //background: linear-gradient(135.83deg, var(--primary-color) 44.94%, rgba(0, 113, 227, 0.25) 157.15%);
  background: var(--dashboard-color);
  padding: 30px !important;
  // position: fixed;
  // bottom: 0px;
  z-index: 9;
}
.login-onboard-bg {
  //background: linear-gradient(135.83deg, var(--primary-color) 44.94%, rgba(0, 113, 227, 0.25) 157.15%);
  background: linear-gradient(92.14deg, var(--primary-color) 44.94%, var(--secondary-color) 157.15%);
  padding: 30px !important;
  // position: fixed;
  // bottom: 0px;
  z-index: 9;
}
.login-bg-neuro {
  //padding: 30px !important;
  // position: fixed;
  // bottom: 0px;
  z-index: 9;
}
.latest-job {
  // background: linear-gradient(90.22deg, var(--primary-color) 0.19%, var(--secondary-color) 108.33%);
  background: var(--dashboard-color) !important;
}
.latest-job1 { // for neuro
  background-image: var(--background-image) !important;
  background-repeat: no-repeat !important;
  background-position-x: right !important;
}
.job-list-header {
  // background: linear-gradient(92.14deg, var(--primary-color) 0.47%, var(--secondary-color) 102.41%);
  background: var(--dashboard-color) !important;
}
.job-list-header1 { //for neuro
  background-image: var(--background-image) !important;
  background-repeat: no-repeat !important;
  background-position-x: right !important;
}
.content-right-padding {
  // padding-left: 20px !important;
  // padding-right: 56px !important;
  justify-content: flex-end;
  display: flex;
}
.content-left-padding {
  // padding-left: 56px !important;
  // padding-right: 20px !important;
  justify-content: flex-start;
  display: flex;
}
.icon-container {
  flex-shrink: 0;
  margin-right: 5px;
}
.name-wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 180px;
}
.industry-chips {
    background-color: var(--secondary-color);
    border-radius: 20px;
    padding: 4px 12px;
    margin: 7px 4px 4px 0px;
    color: var(--primary-color);
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
}
.industry-chips:hover {
    background-color: var(--primary-color);
    border-radius: 20px;
    padding: 4px 12px;
    margin: 7px 4px 4px 0px;
    color: #fff;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
}
.selected-industry {
  background-color: var(--primary-color);
  border-radius: 20px;
  padding: 4px 12px;
  margin: 7px 4px 4px 0px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
}
.MuiInputBase-root.Mui-disabled {
  background-color: var(--gray4) !important;
}
.input-align-fix{
  .form-control.input-group{
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 6px 0 7px !important;
      }
    }
  }
}
.input-align-fix-certificate {
  .form-control.input-group{
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 6px 30px 7px 0px !important;
      }
    }
  }
}
.profile-input{
  .form-control.input-group{
    .MuiSelect-select{
      padding: 6px 0 7px !important;
    }
    .MuiSelect-icon{
      top: auto !important;
      margin-right: 5px !important;
    }
  }
}
.industry-input{
  .form-control.input-group{
    .MuiSelect-select{
      padding: 4px 0 2px !important;
    }
    .MuiSelect-icon{
      top: auto !important;
      margin-right: 5px !important;
    }
  }
}
.date-align-profile{
  .lastUsedDateField{
    padding: 12.3px 12px !important;
    margin-top: 7px !important;
  }
}
.date-align-expire{
  .lastUsedDateField{
    padding: 12px 12px 13px 12px !important;
    margin-top: 7px !important;
  }
}
.MuiSelect-select:focus {
  background-color: #fff !important;
}
.license_status .form-control.input-group .MuiSelect-select {
  color: #b6b9be !important;
}
.cursor-default {
  .MuiStepLabel-labelContainer {
    .MuiStepLabel-label {
      cursor: default;
    }
  }
  .MuiStepLabel-iconContainer {
    cursor: default !important;
  }
}
.circle-svg > circle {
  fill: var(--secondary-color);
}
.circle-svg > path {
  fill: var(--primary-color);
}
// .industry {
  // .MuiPaper-root.MuiPopover-paper {
  //   margin-top: 8px;
  //   max-height: calc(100% - 200px);
  //   min-width: 484px !important;
  //   // left: 490px !important;
  // }
 .my-custom-popper {
    width: 31.7% !important;
    margin-top: 8px !important;
  }
// }
.industry-disable {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.loader-login {
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid var(--primary-color);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
.input-cursor {
  input[type="text"] {
    cursor: default !important;
  }
}
.input-height {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        height: 30px;
      }
    }
  }
}
#menu-dropdown{
  .MuiPaper-root.MuiPopover-paper{
    width: 59.6% !important;
    margin-left: 13px;
  }
}
.location-disable {
  .MuiInputBase-root.Mui-disabled {
    background-color: var(--gray2) !important;
  }
}
.arrow-icon-id {
  opacity: 0.4;
  padding: 0.5px;
  background: var(--primary-color);
  height: 37px;
}
.video-job {
  .videoSection {
    video {
      height: 270px !important;
      object-fit: cover;
    }
  }
}
.resume-upload-job{
  border: 1px dotted $gray6;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 10px !important;
}
.resume-select-job{
  border: 1px solid var(--primary-color);
  background: var(--secondary-color);
  border-radius: 8px;
  padding: 10px !important;
}
.video-preview{
  .video-job{
    .videoSection video{
      width:280px !important;
      height:180px !important;
    }
  }
}
.video-delete{
  background-color:#FEF3F2;
  padding: 6px 5px 6px 7px;
  border: 1px solid #FEF3F2;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  right: -13px;
  top: -7px;
  svg>path{
    stroke: #B42318;
  }
}
.job-profile{
  .job-info{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.url-link-video{
  iframe{
    border-radius: 14px !important;
  }
}