input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
}
.MuiFormControl-root {
  .MuiOutlinedInput-root {
    padding: 0px !important;
  }
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}
textarea {
  &:focus {
    outline: none !important;
    border: none !important;
  }
  &:focus-visible {
    outline: none !important;
    border: none !important;
  }
}
input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  &:focus {
    outline: none !important;
  }
}
::placeholder {
  color: var(--gray7)  !important;
  font-weight: normal !important;
  opacity: 0.6 !important;
}
.placeholder{
  color: var(--gray7)  !important;
  font-weight: normal !important;
  opacity: 0.6 !important;
}
// form-control changes
.form-control {
  &.input-group {
    font-family: var(--font-family) !important;
    color: var(--gray9);
    font-size: var(--font-size-14) !important;
    background: $bg-white;
    border: 1px solid var(--gray6) !important;
    border-radius: 6px !important;
    padding: 6px 12px !important;
    width: 100%;
    margin-top: 7px !important;
    font-weight: var(--font-weight-400) !important;
    .MuiSelect-select {
      padding: 4px 4px;
    }
    &.danger-border {
      border: 1px solid $danger-color !important;
      border-radius: 6px !important;
      padding: 6px 12px !important;
      &.Mui-focused {
        border: 1px solid $danger-color !important;
      }
    }
    &.danger-border-profile {
      border: 1px solid $danger-color !important;
      border-radius: 8px !important;
      padding: 0px 0px !important;
      &.Mui-focused {
        border: 1px solid $danger-color !important;
      }
    }
    // input focus css
    &.Mui-focused {
      border: 1px solid var(--primary-color) !important;
    }
    &.modal-border1 {
      border: 1px solid var(--gray6) !important;
      border-radius: 8px !important;
      padding: 0px 0px !important;
    }
    &.modal-border {
      border: 0px solid var(--gray6) !important;
      max-height: 200px !important;
      max-width: 643px !important;
    }
    &.summar-border {
      border: 0px solid var(--gray6) !important;
    }
    &.missingDetailsBrief {
      max-height: 200px !important;
      max-width: 626px !important;
    }
    &.onboardingBrief {
      max-height: 200px !important;
    }
    &.cover-letter {
      border: 0px solid var(--gray6) !important;
    }
    // .MuiInputBase-root {
    //   .MuiInputBase-input {
    //     padding: 0px !important;
    //     background-color: transparent !important;
    //   }
    // }
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 4px !important;
        color: var(--gray9) !important;
        font-size: var(--font-size-14) !important;
        font-weight: var(--font-weight-400) !important;
        //background-color: transparent !important;
      }
    }
  }
}
.missingDetailsZipCodeAlign {
  .citySection {
    margin-top: 0px !important;
  }
  .form-control.input-group {
    margin-top: 0px !important;
  }
}
.missingDetailsPaddingFix {
  .MuiInputBase-input {
    padding: 4px 0 4px !important;
  }
}
.date-of-birth {
  .form-control {
    &.input-group {
      margin-top: 5px !important;
    }
  }
}
.MuiAutocomplete-root {
  &.MuiAutocomplete-hasPopupIcon {
    &.Mui-focused {
      .form-control {
        border: 1px solid var(--primary-color) !important;
      }
    }
  }
}

.no-focus {
  .MuiAutocomplete-root {
    &.MuiAutocomplete-hasPopupIcon {
      &.Mui-focused {
        .form-control {
          border: none !important;
        }
      }
    }
  }
}

.no-focus-search {
  .MuiAutocomplete-root {
    &.MuiAutocomplete-hasPopupIcon {
      &.Mui-focused {
        .form-control {
          border: 1px solid var(--gray6)  !important;
        }
      }
    }
  }
}

.input-focus-none {
  .form-control:focus {
    box-shadow: none !important;
  }
}

.form-control2 {
  &.input-group2 {
    background: none !important;
    width: 100%;
    font-size: 14px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-family: $font-family !important;
    color: $gray9 !important;
    font-weight: 400px !important;
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 0px !important;
        color: $gray9 !important;
        font-size: 14px !important;
        font-weight: 400px !important;
        background: none !important;
      }
    }
    .MuiOutlinedInput-root {
      padding: 10px 14px !important;
    }
  }
}

.no-outline:focus {
  outline: none;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #EFF0F6 !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.inputGroup {
  input {
    color: #696871 !important;
    font-weight: normal !important;
    // background-color: #aeb2cc !important;
  }
}
.autoInput {
  background: rgba(239, 240, 246, 1);
  border-radius: 4px;
}

.checklist-input{
  .MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: var(--gray7) !important;
  }
}
.lastUsedDateField {
  background-color: $bg-white  !important;
  border: 1px solid var(--gray6)  !important;
  width: 100%;
  border-radius: 6px !important;
  font-size: var(--font-size-14) !important;
  margin-top: 5px !important;
  border: none;
  // height: 44px;
  padding: 10px 12px !important;
  background-image: none !important;

  .MuiInputBase-input {
    padding: 0px !important;
  }
}

.select-option-section {
  margin-top: 8px !important;
  height: calc(1.5em + 0.75rem + 8px);
  padding-bottom: 7px !important;
}


.input-otp {
  margin-top: 40px !important;
  input {
    width: 44px !important;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #98A2B3;
    padding: 8px 0px !important;
    margin-right: 32px;
    font-size: var(--font-size-24) !important;
    font-weight: var(--font-weight-500) !important;
  }
}

.jobsForm input {
  background: $bg-white;
  border: 1px solid #D4D4D8;
  border-radius: 4px;
  height: 12px;
}

.search input {
  background: $bg-white;
  border: 1px solid #D4D4D8;
  border-radius: 4px;
}

/*Custamization of scroll*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.MuiInput-root {
  &.input-group {
    &.is-invalid {
      border: 1px solid #B42318;
      background-image: none;
    }
  }
}

.MuiInput-root {
  &.is-invalid {
    border: 1px solid #B42318 !important;
    border-radius: 6px !important;
  }
}
.MuiAutocomplete-root {
  &.is-invalid {
    border: 1px solid #B42318 !important;
  }
}

.MuiOutlinedInput-root {
  &.is-invalid {
    border: 1px solid #B42318;
    border-radius: 6px !important;
  }
}

.MuiTextField-root {
  &.is-invalid {
    border: 1px solid #B42318 !important;
    border-radius: 6px !important;
  }
}

.job-type {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      background-color: transparent !important;
      color: #696871 !important;
    }
  }
}

.MuiFormControl-root {
  .MuiOutlinedInput-root {
    padding: 0px !important;
  }
}

.sortByDropdown {
  .MuiSelect-select {
    background-color: #F7F7FB;
  }
}

form {
  margin-top: 16px;
}

.password-icon {
  button {
    position: absolute !important;
    right: 10px !important;
    top: 5px !important;
  }
}

.textarea-description {
  min-height: 150px;
  max-height: 235em !important;
  overflow-y: auto !important;
}

.message-text {
  .MuiTextField-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .MuiFormControl-root {
    background: transparent !important;
    width: 480px;

    .MuiOutlinedInput-root {
      input {
        background-color: transparent !important;
      }
    }
  }
}

.description-ellipses {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.MuiRating-root {
  .MuiRating-icon {
    padding: 0 5px 0 0;
  }
}

.select-option-section {
  .MuiSelect-select {
    padding: 9px 8px !important;
  }
}

.select-option-section {
  .MuiSvgIcon-root {
    font-size: 13px !important;
  }
}

.label-name {
  .MuiFormControlLabel-label {
    font-size: 14px !important;
  }
}

.companyFilterLabelAlign {
  .MuiFormControlLabel-label {
    margin-top: 2px !important;
  }
}

.form-input {
  padding: 12px 8px !important;
}

.insights-issue {
  p {
    margin-bottom: 0px !important;
  }
}

.fastpass-issue:focus-visible {
  outline: 1px solid grey !important;
}

.MuiSvgIcon-root {
  &:focus {
    outline: none !important;
    border: none !important;
  }

  &:focus-visible {
    outline: none !important;
    border: none !important;
  }
}

.exp-skills {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      position: relative !important;
      margin: -8px !important;
    }
  }
}

.onboard-skills {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      padding: 10px 12px !important;
      background: $bg-white  !important;
      border: 1px solid var(--gray6)  !important;
      border-radius: 6px !important;
      width: 100%;
      font-size: var(--font-size-14) !important;
      // margin-top: 7px !important;
    }
  }
}

.is-invalid-parsed-skill {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      border: 1px solid #B42318 !important;
    }
  }
}

// safari certification datepicker issue fix
.MuiFormHelperText-root.Mui-error {
  display: none !important;
}

.skill-checklist-autocomplete {
  .MuiAutocomplete-endAdornment {
    // background: #EFF0F6;
    // padding: 2px;
    right: 5px !important;
  }
}

.skill-checklist-autocomplete {
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    padding-right: 30px !important
  }
}

.edu-certificate-year {
  .MuiFormControl-root {
    padding: 7px 12px !important;
  }
}

.certificate-date {
  .MuiInputBase-root {
    input {
      padding-left: 5px !important;
    }
  }
}

.basicCityAlign {
  .MuiInputBase-root {
    input {
      padding: 14px 8px !important;
      width: 100% !important;
    }
    ::placeholder {
      // opacity: 1 !important;
    }
    .MuiAutocomplete-endAdornment,
    fieldset {
      display: none !important;
    }
  }
}

.left-filter-input {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      border: 1px solid var(--gray5)  !important;
      border-radius: 6px !important;
      padding: 9px 12px !important;
      margin-top: 5px !important;
    }
  }
}

.onboard-drawer-location {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
    background: $bg-white  !important;
    border: 1px solid var(--gray6)  !important;
    border-radius: 6px !important;
    padding: 12px !important;
    width: 100%;
    font-size: var(--font-size-14) !important;
    margin-top: 7px !important;
    font-family: var(--font-family)  !important;
    color: var(--gray9) !important;
    font-weight: var(--font-weight-400) !important;
    }
  }
}
.location-shorten {
  .MuiFormControl-root {
    padding: 12px !important;
    border-radius: 0px !important;
    margin-top: 10px !important;
    border: none !important;

    .MuiInputBase-root {
      flex-wrap: nowrap !important;
    }
  }

  .search-job {
    .MuiInputBase-root {
      .MuiInputBase-input {
        background: $bg-white  !important;
        padding: 1px !important;
        font-size: var(--font-size-14) !important;
        font-family: var(--font-family)  !important;
        color: var(--gray9);
        font-weight: var(--font-weight-400) !important;
      }
    }
  }
}

.search-job {
  .MuiInputBase-root {
    .MuiInputBase-input {
      background: $bg-white  !important;
      padding: 12px 14px 14px 0px !important;
      font-size: var(--font-size-14) !important;
      font-family: var(--font-family)  !important;
      color: var(--gray9);
      font-weight: var(--font-weight-400) !important;
    }
  }
}

.search-header-input {
  border: 1px solid #98A2B3 !important;

  .MuiInputBase-root {
    .MuiInputBase-input {
      background-color: #FFFFFF !important;
    }
  }
}

.find-job-soryby {
  background: $bg-gray2;
  border-radius: 6px;
  padding: 5px 0px 5px 15px;

  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 1px 16px !important;
      background: none !important;
    }
  }
}

.addree-profile {
  .MuiFormControl-root {
    // padding: 7px !important;
  }
}

.skill-width {
  .MuiInputBase-root {
    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        padding: 0px !important;
      }
      top: calc(50% - 9px) !important;
      svg {
        right: 0px !important;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      display: none !important;
    }
  }
}

.pay-rate-job {
  background: rgba(234, 234, 234, 0.6);
  border-radius: 5px;

  .MuiInputBase-input {
    color: var(--gray9);
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-600);
    padding: 5px !important;
    text-align: center;
  }
}

.nationality-align {
  .MuiAutocomplete-root {
    padding: 6px 12px !important;
  }
}

.worked-from {
  .MuiFormControl-root {
    padding: 8px 12px !important;
  }
}

.create-file {
  .choose-file {
    width: 73% !important;
    padding: 3px 4px 3px 12px !important;
  }
}

.share-job-align {
  .MuiFormControl-root {
    .MuiInputBase-root {
      .MuiInputBase-input {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        width: 190px;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding: 10px 0px !important;
      }
    }
  }
}

.myjob-share {
  .job-share {
    width: 30px !important;
    height: 30px !important;
    // margin-right: 15px !important;
  }
}
.relevance-align, .latest-align{
  font-size:var(--font-size-14) !important;
  &.Mui-selected{
    color:var(--primary-color) !important;
    background-color: var(--secondary-color) !important;
    font-weight:var(--font-weight-600) !important;
    font-size:var(--font-size-14) !important;
  }
}

.sortby-align {
  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 0px 20px 0px 5px !important;
      align-items: center;
      display: flex;
      margin-top: 2px;
      font-size: var(--font-size-14);
      color: var(--gray9)  !important;
      font-weight: var(--font-weight-400);
    }
    .MuiSvgIcon-root {
      right: 0px !important;
    }
  }
}

.sortby-bg {
  // background: $gray3;
  padding: 3px 10px;
  border-radius: 6px;
}

.reference-input {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      background: $bg-white  !important;
      border: 1px solid var(--gray6)  !important;
      border-radius: 6px !important;
    }
}
}
.createProfile-code {
    .MuiFormControl-root {
      padding: 3px !important;
  }
}
.createProfile-code-referral {
  .MuiFormControl-root {
    padding: 1px !important;
}
}

.worked-from {
  .work-input {
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 2px !important;
      }
    }
  }
}

.state-input {
  .input-align {
    // padding: 5px 10px !important;
  }
}
.nationality-input{
  .input-nationality{
    // padding: 2px 0px !important;
  }
}
.cityName{
  .basic-info-city{
    // padding: 8px 10px !important;
  }
}
.workedto-align {
  .present-input {
    .MuiInputBase-input {
      padding: 5.5px !important;
    }
  }
}
.filter-location{
  .MuiInputBase-root{
    ::placeholder{
      color: var(--gray7)  !important;
      font-weight: var(--font-weight-400) !important;
      font-size:$font-size-14;
      font-family: var(--font-family) !important;
      // opacity: 1 !important;
    }
  }
}
.body-container{
  max-width: 1400px !important;
  margin: 0 auto !important;
}
.resume-section{
  input{
    &.resume-input{
      font-family: var(--font-family) !important;
      font-size: var(--font-size-14);
      color: var(--gray9);
    }
  }
}
.bionic-count {
  border: 1px solid #D0D5DD;
    border-radius: 2px;
    background: none !important;
    padding: 5px;
    color: #344054;
    font-weight: var(--font-weight-400) !important;
    font-size: var(--font-size-12) !important;
}
.bionic-rate {
  background: none !important;
  .MuiInputBase-input {
    color: #344054;
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-400);
    padding: 5px !important;
    text-align: center;
  }
  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: black !important;
  }
}
.pay-rate-input{
  .MuiSelect-select{
    min-width: 70px !important;
    padding-right: 16px !important;
  }
}
.pay-rate-input-profile{
  .MuiSelect-select{
    min-width: 30px !important;
    padding-right: 16px !important;
  }
}
.link-copy{
  .MuiInputBase-root{
    .MuiInputBase-input{
      text-overflow: ellipsis;
      cursor: default !important;
    }
  }
}
.create-profile-location{
  .MuiAutocomplete-root{
    .MuiFormControl-root{
      padding: 8px 12px !important;
    }
  }
}
.interview-sort{
  .MuiInputBase-root{
    .MuiSelect-select{
      color: $gray7 !important;
      font-size: 14px !important;
    }
  }
}
.interview-questions {
  .MuiOutlinedInput-root {
    display: flex;
    align-items: center;
    padding: 0px !important;
  }
}
.exercise-input {
  .MuiFormControl-root {
    padding:10px 12px !important;
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 0px 4px !important;
        overflow: auto !important;
        max-height: 100px;
      }
    }
  }
}
.send-questions{
  .MuiInputBase-root{
    .MuiOutlinedInput-notchedOutline{
      padding: 0px !important;
    }
  }
}
.autoSearch {
  .MuiFormControl-root {
    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        svg {
          display: none;
        }
      }
    }
  }
}
.refer-upload-field{
  .form-control {
    &.input-group{
        padding-right: 0px !important;
    }
    .MuiInputBase-root{
      .MuiInputBase-input{
        cursor: pointer !important;
      }
    }
  }
}
.referral-inputGroup {
  .MuiOutlinedInput-input {
    &.MuiInputBase-input {
      color: var(--gray9)  !important;
      font-weight: normal !important;
    }
  }
}
.referral-inputGroup input {
  background: $bg-input-gray;
  border-radius: 4px;
}
.new-search {
  margin: 0px !important;
  .MuiOutlinedInput-root {
    .MuiInputBase-input{
      padding: 10px!important;
      background-color: #FFFFFF !important;
      border-radius: 6px !important;
    }
    .MuiOutlinedInput-notchedOutline {
      padding: 0px !important;
    }
    .Mui-disabled {
      background-color: #F2F4F7 !important;
    }
  }
}
.search-id {
  border: 1px solid var(--gray6)  !important;
  border-radius: 7px;
  .MuiInputBase-colorPrimary {
    padding-right: 0 !important;
  }
  .MuiOutlinedInput-notchedOutline {
    padding: 3px !important;
  }
}
.candidate-list-align {
  .MuiSelect-select {
    display: flex !important;
  }
  .MuiSelect-icon {
    font-size: 23px !important;
  }
}
.companiesFilter {
  background: #F2F4F7;
  border-radius: 20px;
  padding: 4px 14px;
  margin: 4px 4px 4px 0px;
  color: #363F72;
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  display: inline-block !important;
}
.filter-date{
  .form-control.input-group{
    padding: 12px 16px !important;
  }
}
.clear-date{
  position: absolute;
  right: 42px;
  bottom: 15px;
}
.daterangepicker{
  position: absolute;
  top:113px !important;
}
.daterangepicker::before{
  border-top: 7px solid #ccc !important;
  top: 279px !important;
  border-bottom: transparent !important;
}
.daterangepicker::after{
  border-bottom: transparent !important;
}
.daterangepicker .drp-calendar.left{
  padding-left: 0px !important;
  min-width: 240px !important;
}
.daterangepicker .drp-calendar.right{
  padding-right: 0px !important;
  min-width: 240px !important;
}
.daterangepicker td.start-date, .daterangepicker td.end-date{
  border-radius: 4px !important;
}
.daterangepicker td.in-range.active{
  background-color: var(--primary-color) !important;
}
.custom-no-options{
    background-color: #ffffff !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
    z-index: 999999999 !important;
    position: absolute !important;
    top: -10px !important;
    left: 0px !important;
    right: 0px !important;
    margin: 0px 20px !important;
    width: auto !important;
    border-radius: 4px;
}
.refer-filter-reset{
  position: fixed;
    bottom: 0px;
    right: 0px;
    // width: 55%;
    background-color: white;
    padding-bottom: 20px !important;
    // right: calc(42px) !important;
    transform: translateZ(0);
    margin-right: 44px;
}
.job-log-shadow{
  .MuiPaper-root{
    box-shadow:0px 3px 8px rgba(149, 136, 136, 0.25) !important;
  }
}
.referral-number {
  .form-control {
    &.input-group {
      margin-bottom: 0px !important;
    }
  }
}
.MuiGrid-root {
  form {
    .MuiGrid-root {
      .MuiFormControl-root {
        .radio-check {
          display: block !important;
        }
      }
    }
  }
}
.skill-add {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      margin-top: 5px !important;
    }
  }
}
.MuiFormControlLabel-label {
  font-size: var(--font-size-14) !important;
  color: var(--gray10)  !important;
  font-weight: var(--font-weight-400) !important;
}
.exp-level {
  .Mui-checked {
    color: var(--primary-color)  !important;
  }
  .MuiFormControlLabel-label {
    color: var(--gray10)  !important;
    font-weight: var(--font-weight-400) !important;
    font-size: var(--font-size-14) !important;
  }
  .MuiCheckbox-root {
    padding: 9px !important;
  }
}
.assess-tools {
  .MuiFormControlLabel-root {
    margin-right: 50px !important;
    .MuiFormControlLabel-label {
      color: var(--gray10)  !important;
      font-size: var(--font-size-14) !important;
      font-weight: var(--font-weight-400) !important;
    }
  }
}
.input-pair {
  .border-right-0 {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
  }
}
.location-input {
  .MuiAutocomplete-root {
      .location-search-input {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-top-right-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
          border: 1px solid var(--gray5)  !important;
          padding: 9px 12px !important;
          margin-top: 7px !important;
      }
  }
}
.cityName {
  .location-search-input {
    font-weight: var(--font-weight-500) !important;
  }
  input {
    // font-size: 14px !important;
    // font-weight: normal !important;
    // color: #1F1F1F !important;
    // background: $bg-white !important;
    // border: 1px solid $gray5 !important;
    // width: 100%;
    // border-radius: 6px !important;
    // margin-top: 7px !important;
    // border: none;
    // outline: none;
    // padding: 10px !important;
  }
  .autocomplete-dropdown-container {
    // border-radius: 3px;
    // box-shadow: 0px 4px 4px rgba(234, 233, 242, 0.4);
    // background: #FFF;
    // padding: 0 20px;
    position: absolute;
    overflow: auto;
    /* max-height: 50%; */
    z-index: 100;
    /* bottom: 100px; */
    /* width: 315px;*/
    text-align: left !important;
    background: #ffffff !important;
    box-shadow: 0px 6px 14px -6px rgba(0, 0, 0, 0.12),
      0px 10px 32px -4px rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px !important;
  }
}
.rate-dollar {
  .form-control {
    &.input-group {
      //padding: 7px 12px !important;
    }
  }
}
// .city-placeholder {
//   ::placeholder {
//     font-weight: normal !important;
//     opacity: 0.6 !important;
//   }
// }
// .find-job-place {
//   .MuiInputBase-root {
//     ::placeholder {
//       opacity: 1 !important;
//     }
//   }
// }
/*.MuiBox-root{
  p{
    margin-top: 15px;
  }
}*/
.skill-margin {
  .MuiAutocomplete-root {
    // margin-right: 10px !important;
  }
}
// .select-option-section{
//   .MuiOutlinedInput-input.MuiInputBase-input {
//     padding: 10px 8px !important;
//   }
// }
// .MuiAutocomplete-endAdornment {
//   background-color: white !important;
// }
// .MuiInput-input{
//   font-size: 14px !important;
//   font-family: $font-family !important;
//   font-weight: 500 !important;
//   color: #1F1F1F !important;
//   border-radius: 4px !important;
//   background-color: rgba(239, 240, 246, 1) !important;
//   /*padding: 8px 10px !important;*/
// }
// .MuiOutlinedInput-input{
//   &.MuiInputBase-input{
//     font-size: 14px !important;
//     font-family: $font-family !important;
//     font-weight: 500 !important;
//     color: #1F1F1F !important;
//     border-radius: 6px !important;
//     background: $bg-white !important;
//     border: 1px solid $gray5 !important;
//     padding: 10px !important;
//   }
// }
/*.MuiSvgIcon-root {
  font-size:18px !important;
}*/

// .sortby-bg{
//   .Mui-focused{
//     outline: none !important;
//   }
// }
// .form-control.Mui-focused{
//   outline: 2px solid var(--primary-color) !important;
// }
// .is-invalid.form-control.Mui-focused{
//   border: 1px solid #B42318 !important;
//   outline: none !important;
// }
// .Mui-focused{
//   outline: 2px solid var(--primary-color) !important;
// }

// input:focus {
//   outline: none;
//   border-color: var(--primary-color) !important;
//   box-shadow: 0 0 2px var(--primary-color); /* add a subtle box shadow */
// }

/*
.form-control{
  &.input-group{
    input{
      padding:12px 8px !important;
      // height:28px !important;
      // padding-left:10px !important;
    }
  }
}*/

// .css-1rv3ei8-MuiFormControl-root-MuiTextField-root {
//   background:$bg-input-gray;
//   border-radius:4px;
// }

.inputGroup input {
  // background:rgba(239, 240, 246, 1);
  // border-radius:4px;
}
/*.MuiTextField-root{
  &.skills-exp{
    width: 61% !important;
  }
}*/
.icon-positioning-onboard {
  .MuiFormControl-root{
    .MuiAutocomplete-endAdornment{
      margin-top: 3px !important;
    }
  }
}
.MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.MuiAutocomplete-listbox .MuiAutocomplete-option{
  background: #FFFFFF !important;
}
.header-search{
  .form-control.input-group {
    border: 1px solid var(--gray5) !important;
  }
}
.account-menu-checklist{
  .MuiPaper-root{
    left: 70px !important;
    top: 350px !important;
  }
}
.form-control {
  &.input-group {
    &.danger-border {
      border: 1px solid $danger-color !important;
      border-radius: 6px;
      &.Mui-focused {
        border: 1px solid $danger-color !important;
      }
    }
  }
}
.hide-select-icon .MuiSelect-icon {
  display: none;
}
.summary-view{
  .p-0{
    padding: 0px !important;
  }
}
.summary-view{
  .p-0{
    padding: 0px !important;
  }
}
.missing-phone-email .MuiFormControl-root .MuiOutlinedInput-root #country-select-demo {
  background-color: #fff !important;
  padding: 9px 8px !important;
  font-size: 14px;
}
.form-control.input-group{
  &.asses-text{
    padding-top: 10px !important;
  }
}
.screening-radio{
  .MuiFormControlLabel-root{
    display: flex !important;
    align-items: flex-start;
  .MuiFormControlLabel-label{
    word-break: break-word;
    margin-top: 6px;
  }
  }
}
#menu-ethnicity{
  .MuiPaper-root.MuiPopover-paper{
    width: 45%;
    .preview-dropdown {
      display: block !important;
      white-space: break-spaces !important;
      word-break: break-word !important;
    }
  }
}
.input-height {
  .MuiInputBase-root {
    height: 30px;
  }
}
.share-icon-job:focus{
  outline:none !important;
}
.message-search-input {
  .MuiInputBase-root {
    padding-left: 0px !important;
    border-radius: 7px;
  }
  .MuiInputBase-root.Mui-disabled {
    background-color: #F2F4F7 !important;
  }
}
.icon-min-width3{
  min-width: 44px !important;
}