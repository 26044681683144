.toastBody {
  font-family: var(--font-family) !important;
  color: #10171D; /* #10171D */
  font-size: var(--font-size-14) !important;

}

.Toastify__toast-container{
  z-index: 99999 !important;
}

.Toastify__toast--warning {
  background: #FEF3F2 !important;
}

.Toastify__toast--error {
  background: #FEF3F2!important;
  color: #B42318 !important;
}

.Toastify__toast--success{
  .Toastify__toast-icon {
    width: 20px;
    height: var(--toast-image-height);
    background: url('/assets/images/toaster-icons/success-icon.svg') center/contain no-repeat !important;
    svg{
      display: none;
    }
  }
}

.Toastify__toast--warning{
  .Toastify__toast-icon {
    width: 20px;
    height: 20px;
    background: url('/assets/images/toaster-icons/warning-icon.svg') center/contain no-repeat !important;
    svg{
      display: none;
    }
  }
}

.Toastify{
  .Toastify__toast {
    font-family: var(--font-family) !important;
    &.Toastify__toast-theme--light{
      &.Toastify__toast--success{
       background-color: #E3FCEF;
        color: #1F1F1F;
        font-size: var(--font-size-14);
       /* z-index: 9999;*/
       /* opacity: 1 !important;
        z-index: 999999;*/
      }
    }
  }
}

.Toastify{
  .Toastify__toast {
    &.Toastify__toast-theme--light{
      &.Toastify__toast--warning{
        background-color: #FEF3F2;
        color: #B42318 !important;
        font-size: var(--font-size-14);
        /* opacity: 1 !important;
         z-index: 999999;*/
      }
    }
  }
}

.Toastify__close-button--light{
  display: none;
}
.Toastify__toast-container {
  .Toastify__toast {
    .Toastify__toast-body {
      align-items: flex-start;

      .Toastify__toast-icon {
        padding-top: 1px;
      }
    }
  }
}
.Toastify__toast-body{
  .Toastify__toast-icon {
    /*width: 0;*/
   /* background: url('/assets/images/clock.svg') center/contain no-repeat !important;*/
    /*svg{
      display: none;
    }*/
  }
}
.Toastify__toast-container--top-right {
  top: 5em !important;
  right: 1em;
}

#alert-dialog-title{
  font-size: var(--font-size-20) !important;
  padding: 25px 20px 0 20px!important;
  color:  var(--gray9);
  font-weight: var(--font-weight-600);
  text-align: left;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#alert-dialog-description{
  font-size: var(--font-size-14)!important;
  padding: 28px 0 0px 0px;
  color: var(--gray9);
}

.confirm-link{
  &.dialog-primary{
    font-size: var(--font-size-16);
    padding:4px;
    color: var(--primary-color);
    text-transform: none;
    box-shadow: none;
    font-weight: var(--font-weight-600);
    border-radius: 8px;
    font-family: var(--font-family);
    height: 2em;
  }
  &.dialog-primary:hover{
    background: transparent !important;
  }
}

.confirm-link{
  &.dialog-secondary{
    color: var(--gray7);
    font-size: var(--font-size-16);
    padding:4px;
    text-transform: none;
    box-shadow: none;
    font-family: var(--font-family);
    height: 2em;
  }
  &.dialog-secondary:hover{
    background: transparent !important;
  }
}

/*loader-css*/
.background-blur{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.loader{
  div{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    background-color: var(--loader-bg);
   /* z-index:999999;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9;*/
    svg{
      width: 15% !important;
      height: 15% !important;
    }

  }
}

// haptik-css
.haptik-xdk-container {
  position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
}
.assess-update {
  .MuiPaper-elevation {
    min-width: fit-content !important;
    max-width: auto !important;
    left: 1109p !important;
  }
}
.MuiTooltip-tooltip{
  background-color: var(--gray10) !important;
  opacity: .92 !important;
}

// checklist css
.container .checkmark:after {
  background: var(--primary-color) !important;
}
.container input:checked ~ .checkmark {
  background-color: #ffffff;
  border: 1px solid var(--primary-color) !important;
}