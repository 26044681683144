.MuiTypography-root {
    margin: 0;
    font-family: var(--font-family) !important;
}
.display-flex {
    display: flex !important;
}
.d-block {
    display: block !important;
}
.d-grid {
    display: grid !important;
}
.d-content {
    display: contents !important;
}
.d-inherit {
    display: inherit;
}
.d-inline {
    display: inline;
}
.d-inline-flex {
    display: inline-flex;
}
.d-none {
    display: none;
}
.cursor-default {
    cursor: default !important;
}
.cursor-context {
    cursor: context-menu !important;
}
.cursor-hover{
    cursor: pointer !important;
}
.cursor-auto{
    cursor: auto;
}
.cursor-default{
    cursor: default !important;
}
.ReactCrop {
    cursor: not-allowed !important;
}
.objectFit {
    object-fit: cover;
}
.break-word {
    word-wrap: break-word !important;
}
.flex-wrap {
    flex-wrap: wrap;
}
.no-wrap {
    flex-wrap: nowrap !important;
}
.word-break {
    word-break: break-word !important;
}
.break-all {
    word-break: break-all !important;
}
.break-space{
    white-space: break-spaces;
}
.float-right {
    float: right;
}
.f-left {
    float: left;
}

// alignments
.align-center {
    align-items: center !important;
}
.align-baseline {
    align-items: baseline;
}
.align-end {
    align-items: end;
}
.flex-start {
    align-items: flex-start !important;
}
.align-left {
    text-align: left !important;
}
.align-right {
    text-align: right !important;
}
.text-center {
    text-align: center !important;
}
.text-justify {
    text-align: justify !important;
}
.vertical-inherit {
    vertical-align: inherit !important;
}
.vertical-align-top {
    vertical-align: top;
}
.vertical-middle {
    vertical-align: middle;
}
.justify-flex-start {
    justify-content: flex-start !important;
}
.justify-center {
    justify-content: center !important;
}
.justify-end {
    justify-content: end !important;
}
.justify-flex-end {
    justify-content: flex-end !important;
}
.justify-between {
    justify-content: space-between !important;
    display: flex !important;
}
.text-lowercase{
    text-transform: lowercase !important;
  }
.text-capitalize {
    text-transform: capitalize !important;
}
.text-transform-none {
    text-transform: none !important;
}
.list-none {
    list-style: none;
}
.outline-none {
    outline: none !important;
}
.border-none{
    border: none !important;
}
.h-auto {
    height: auto !important;
}
.w-max-content {
    width: max-content !important;
}
.w-fit-content{
    width: fit-content;
}
.max-w-content{
    max-width: fit-content !important;
}

// border-radius changes
.border-radius-4 {
    border-radius: 4px !important;
}
.border-radius-5 {
    border-radius: 5px;
}
.border-radius-6 {
    border-radius: 6px !important;
}
.border-radius-7 {
    border-radius: 7px !important;
}
.border-radius-8 {
    border-radius: 8px !important;
}
.border-radius-10{
    border-radius:10px !important;
}
.border-radius-20 {
    border-radius: 20px !important;
}
.border-radius-50 {
    border-radius: 50%;
}
.border-radius-6-t-l {
    border-radius: 6px 0px 0px 6px !important;
    border-right: 1px solid #EBEBEB !important;
}
.border-radius-6-r-b {
    border-radius: 0px 6px 6px 0px !important;
}
.border-radius-bottom {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.word-space {
    word-spacing: -1px !important;
}
.opacity-08 {
    opacity: 0.8;
}
.text-decoration-none {
    text-decoration: none !important;
}
.text-decor-underline {
    text-decoration: underline;
  }
.text-decor-hover {
    &.hover {
      text-decoration: none !important;
    }
}
.shadow-0 {
    box-shadow: none !important;
}
.checklist-shadow {
    box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12), 0px 10px 32px -4px rgba(90, 136, 193, 0.12);
}
.resendOTP {
    .link-color {
        cursor: default !important;
    }
}
.textEllipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
}
/*positions*/
.p-relative{
    position: relative !important;
}
.p-absolute{
    position: absolute;
}
.z-index-99{
    z-index: 99;
}
.top-0{
    top: 0 !important;
}
.filtersContainer:not(.sticky-filter) {
    position: relative;
}
.min-w-35{
    min-width:35px !important;
}
.min-w-20{
    min-width: 20px;
}
.w-30{
    width: 30px !important;
  }
  