// margin
.m-auto{
    margin:0px auto !important;
}
.m-0{
    margin:0px !important;
}
.m-10{
    margin: 10px;
}
.m-15{
    margin: 15px !important;
}
.m-20{
    margin:20px !important;
}

/*margin-top*/
.m-t-0{
    margin-top:0px !important;
}
.m-t-2{
    margin-top:2px !important;
}
.m-t-3{
    margin-top: 3px !important;
}
.m-t-4{
    margin-top: 4px !important;
}
.m-t-5{
    margin-top: 5px !important;
}
.m-t-6{
    margin-top: 6px !important;
}
.mt-7{
    margin-top:7px !important;
}
.mt-8{
    margin-top: 8px !important;
}
.mt-10{
    margin-top:10px !important;
}
.mt-12{
    margin-top:12px !important;
}
.mt-15{
    margin-top: 15px !important;
}
.mt-16{
    margin-top: 16px !important;
}
.mt-20{
    margin-top: 20px !important;
}
.mt-24 {
    margin-top: 24px;
}
.mt-25{
    margin-top: 25px !important;
}
.mt-30{
    margin-top:30px !important;
}
.mt-35{
    margin-top:35px !important;
}
.mt-36{
    margin-top:60px !important;
}
.mt-40{
    margin-top:40px !important;
}
.mt-45{
    margin-top:45px !important;
}
.mt-50{
    margin-top: 50px !important;
}
.mt-55{
    margin-top: 55px !important;
}
.mt-80{
    margin-top: 80px !important;
}
.mt-60{
    margin-top:60px !important;
}
.mt-70{
    margin-top:70px !important;
}
.m-t-m-3{
    margin-top: -3px;
}
.m-t-m-10{
    margin-top: -10px;
}

/*margin-bottom*/
.m-b-0{
    margin-bottom:0 !important;
}
.m-b-2{
    margin-bottom:2px !important;
}
.m-b-5{
    margin-bottom: 5px !important;
}
.mb-10{
    margin-bottom:10px !important;
}
.mb-12{
    margin-bottom: 12px !important;
}
.mb-13{
    margin-bottom: 13px !important;
}
.mb-15{
    margin-bottom: 15px !important;
}
.mb-20{
    margin-bottom:20px !important;
}
.mb-30{
    margin-bottom:30px !important;
}
.mb-35{
    margin-bottom:35px !important;
}
.mb-40{
    margin-bottom: 40px !important;
}
.mb-50{
    margin-bottom: 50px !important;
}
.mb-70{
    margin-bottom: 50px !important;
}
.mb-80{
    margin-bottom: 80px !important;
}

/*margin-right*/
.mr-auto{
    margin-right:auto !important;
}
.m-r-0 {
    margin-right:0px !important;
}
.m-r-2 {
    margin-right: 2px !important;
}
.m-r-5{
    margin-right:5px !important;
}
.mr-6{
    margin-right:6px;
}
.mr-8{
    margin-right:8px !important;
}
.mr-9{
    margin-right: 9px !important;
}
.mr-10{
    margin-right: 10px !important;
}
.mr-15{
    margin-right: 15px !important;
}
.mr-17{
    margin-right:17px !important;
}
.mr-20{
    margin-right:20px !important;
}
.mr-25{
    margin-right: 25px !important;
}
.mr-30{
    margin-right: 30px !important;
}
.mr-35{
    margin-right: 35px !important;
}
.mr-40{
    margin-right: 40px !important;
}
.mr-60{
    margin-right: 60px !important;
}
.mr-65{
    margin-right: 65px !important;
}
.mr-25{
    margin-right:25px !important
}
.nm-10 {
    margin-right: -15px !important;
}

/*margin-left*/
.ml-auto{
    margin-left:auto !important;
}
.m-l-0{
    margin-left:0px !important;
}
.m-l-2{
    margin-left: 2px;
}
.m-l-5{
    margin-left:5px !important;
}
.ml-8{
    margin-left: 8px !important;
}
.ml-10{
    margin-left:10px !important;
}
.ml-12{
    margin-left: 12px !important;
}
.ml-15{
    margin-left:15px !important;
}
.ml-16{
    margin-left: 16px !important;
}
.ml-20{
    margin-left: 20px !important;
}
.ml-25{
    margin-left: 25px !important;
}
.ml-30{
    margin-left: 25px !important;
}
.ml-31{
    margin-left: 31px !important;
}

/*margin -> mx & my*/
.mx-20{
    margin:0 20px;
}
.m-x-5{
    margin:0 5px !important;
}
.mx-6{
    margin:0 6px !important;
}
.mx-10{
    margin:0 10px !important;
}
.m-y-5{
    margin:5px 0 !important;
}
.my-5{
    margin-top:5px !important;
    margin-bottom: 5px !important;
}
.my-10{
    margin: 10px 0 !important;
}
.my-15{
    margin: 15px 0 !important
}
.my-20{
    margin: 20px 0 !important;
}
.mlr-20{
    margin-left: 20px;
    margin-right: 20px;
}
.mr-32 {
    margin-right: 16px;
    margin-left: 3px;
}