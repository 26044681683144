input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 2px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 5px;
  width: 15%;
  border-radius: 0%;
  background: #000;
  margin-top: -4px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
.carousel-container-with-scrollbar {
  padding-left: 0;
  padding-bottom: 20px;
  /*overflow: visible !important;*/
}
.custom-slider {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
}
.custom-slider__input {
  width: 100%;
}
.slider-image-item {
  padding: 0 0 20px 10px;
}
.carousel-container-with-scrollbar{
  .react-multi-carousel-track {
    display: flex;
  }
}
.react-multiple-carousel__arrow--left{
  left: -3px!important;
  top: 118px !important;
}
.react-multiple-carousel__arrow--right{
  right: -10px !important;
  top: 118px !important;
}
.react-multiple-carousel__arrow{
  background: none !important;
  min-height: 20px !important;
  min-width: 20px !important;
}
.react-multiple-carousel__arrow--right::before {
  content: url('/assets/images/right-arrow.svg') !important;
}

.react-multiple-carousel__arrow--left::before {
  content: url('/assets/images/left-arrow.svg') !important;
}
.pay-rate-search {
  .MuiSlider-thumb {
    color: var(--primary-color) !important
  }
  .MuiSlider-track {
    color: var(--primary-color) !important;
    opacity: 0.2;
    height: 4px !important;
  }
  .MuiBox-root.MuiSlider-sizeMedium {
    color: #EAEAEA !important;
  }
}
