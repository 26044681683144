/*Font-size*/

.sub-text-font{
  font-size: var(--font-size-16) !important;
}

.sub-heading-font{
  font-size: var(--font-size-24) !important;
}

.small-text-font{
  font-size:var(--font-size-14) !important;
}

::placeholder{
  font-size:var(--font-size-14);
  font-family: var(--font-family) !important;
  color: var(--gray7);
  font-weight: normal !important;
  opacity: 0.6 !important;
  // padding-left:10px !important;

}
.f-5{
  font-size:5px !important;
}
.f-10{
  font-size:var(--font-size-10);
}
.f-11{
  font-size:11px !important;
}
.f-12{
  font-size: var(--font-size-12) !important;
}
.f-13{
  font-size: $font-size-13 !important;
}
.f-14{
  font-size: var(--font-size-14) !important;
}
.f-16{
  font-size: var(--font-size-16) !important;
}
.f-17{
  font-size: 17px !important;
}
.f-18{
  font-size: var(--font-size-18) !important;
}
.f-19{
  font-size: 19px !important;
}
.f-20{
  font-size: var(--font-size-20) !important;
}
.f-21{
  font-size: 21px !important;
}
.f-22{
  font-size: var(--font-size-22) !important;
}
.f-24{
  font-size: var(--font-size-24) !important;
}
.f-25{
  font-size: $font-size-25 !important;
}
.f-30{
  font-size: 30px !important;
}
.f-32{
  font-size:32px !important;
}
.f-35{
  font-size: 35px !important;
}
.f-36{
  font-size: var(--font-size-36) !important;
}
.f-40{
  font-size:40px !important;
}
.f-48{
  font-size: 48px !important;
}
.f-44{
  font-size: 44px !important;
}
.f-47{
  font-size:47px !important;
}
.font-47{
  font-size:47px !important;
}
.f-50{
  font-size:50px !important;
}
.font-weight-300{
  font-weight: 300 !important;
}
.font-weight-400{
  font-weight:var(--font-weight-400) !important;
}
.font-weight-500{
  font-weight: var(--font-weight-500) !important;
}
.font-weight-600{
  font-weight:var(--font-weight-600) !important;
}
.font-weight-700{
  font-weight:700 !important;
}
.font-weight-800{
  font-weight: 800 !important;
}

.border-bottom{
  border-bottom: 1px solid #EBEBEB
}
.border-bottom:last-child { 
  border-bottom: none; 
}

.align-item-center{
  align-items: center !important;
  justify-content: center !important;
  vertical-align: middle !important;
  display: flex !important;
}

.align-item-left{
  align-items: left;
  justify-content: left;
  vertical-align: middle;
  display: flex !important;
}

.align-item-right{
  align-items: right;
  justify-content: right;
  vertical-align: middle;
  display: flex !important;
}
// width & height
.w-17 {
  width: 17% !important;
}
.w-18 {
  width: 18px !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.height-100 {
  height: 100vh !important;
}
.w-20 {
  width: 20px;
}
.max-w-200{
  max-width: 200px;
}
.max-w-340{
  max-width: 340px !important;
}
.max-w-83{
  max-width: 83% !important;
}
.max-w-500{
  max-width: 500px;
}
.w-140{
  width: 140px !important;
}
.max-w-100{
  max-width: 100% !important;
}
.w-160 {
  width: 160px;
}
.w-180{
  width: 180px !important;
}
.w-190{
  width: 190px !important;
}
.min-max-w{
  min-width: 150px !important;
  max-width: 270px !important;
}
.width-580{
  width: 580px !important;
}
.min-h-100 {
  min-height: 100% !important;
}
.min-w-70 {
  min-width: 70px !important;
}
.screen-height {
  height: 100% !important;
}
.h-200 {
  height: 200px;
}
.w-70{
  width: 70%;
}
.w-100{
  width:100% !important;
}
.w-100px{
  width: 100px !important;
}
.w-150{
  width:150px;
}
.w-200{
  width:200px;
}
.w-280{
  width: 280px;
}
.w-300{
  width:300px;
}
.w-330{
  width:330px;
}
.w-400{
  width: 400px !important;
}
.w-450{
  width:450px;
}
.w-600{
  width:600px !important
}
.w-1000{
  width:1000px !important;
}
.font-weight-normal{
  font-weight:normal !important;
}
.wth-50{
  width:50px !important;
}
.l-h-1{
  line-height: 1 !important;
}.l-h-40{
  line-height: 40px !important;
}.l-h-18{
  line-height: 18px !important;
}.l-h-20{
  line-height: 20px !important;
}
.w-85{
  width:85px !important;
}
.h-40 {
  height: 40px;
}
.min-w-55 {
  min-width: 55px !important;
}
.fix-width {
  width: 325px;
}
.report-style-class {
  height: 100vh;
}
.border-radius-100 {
  border-radius: 100px !important;
}
.h1-text{
  font-size: var(--font-size-36);
  font-weight: var(--font-weight-600);
  color: var(--gray9);
}
.h2-text{
  font-size: var(--font-size-28);
  font-weight: var(--font-weight-600);
  color: var(--gray9);
}
.h3-text{
  font-size: var(--font-size-20) !important;
  font-weight: var(--font-weight-600) !important;
  color: var(--gray9);
}
.h4-text{
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-600) !important;
  color: var(--gray9);
}
.body-large-text {
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9);
}

.body-medium-text {
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-500) !important;
  color: var(--gray9);
}
.body-medium-normal {
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9) !important;
}
.prof-summary-text{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: $gray8 !important;
}
.label-large-text{
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-500) !important;
  color: var(--gray9);
}
.label-medium-text{
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9);
}
.supporting-text-dark{
  font-size: var(--font-size-12) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray9);
}
.supporting-text-light{
  font-size: var(--font-size-12) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray7);
}
.supporying-text-medium{
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-400) !important;
  color: var(--gray7) !important;
}
.supporting-text-large{
  font-size: var(--font-size-14) !important;
  font-weight: var(--font-weight-500) !important;
  color: var(--gray7) !important;
}
.launch-page-font {
  font-size: 15px !important;
  font-weight: var(--font-weight-400) !important;
  // color: #676767 !important;
  color:var(--gray9) !important;
}
.launch-page-title{
  font-size: 34px !important;
  color: #222222 !important;
  font-weight: var(--font-weight-600) !important;
}
.font-family{
  font-family:  var(--font-family) !important;
}
.MuiSvgIcon-root {
  font-size: var(--font-size-18) !important;
}
.already-referred{
  font-size: 11.8px;
}
.dot-icon{
  .f-5{
    font-size: 5px !important;
  }
}