button {
  cursor: pointer;
}
button:disabled {
  cursor: default;
}
button:focus {
  outline: none !important;
}
.btn:focus {
  box-shadow: none !important;
}
button[type="button"]:disabled {
  color:var(--gray7) !important;
  background-color: var(--gray4) !important;
  font-weight: var(--font-weight-600) !important;
  // border-color: #FFFFFF;
}
button[type="submit"]:disabled {
  color:var(--gray7)  !important;
  background-color: var(--gray4) !important;
  font-weight: var(--font-weight-600) !important;
  // border-color: #FFFFFF;
}
.MuiButton-root{
  &.Mui-disabled{
    color:var(--gray7)  !important;
    background-color: var(--gray4) !important;
    font-weight: var(--font-weight-600) !important;
  }
}
.MuiButton-root:hover {
  background-color: transparent !important;
}

.btn {
  &.primary-button {
    background: var(--primary-color) !important;
    color:var(--gray1) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover{
      background: var(--hover-button-color) !important;
      box-shadow: none !important;
      color: var(--hover-button-text-color) !important;
      .arrow-icon-send-message {
        background: var(--hover-button-text-color);
      }
    }
  }
}
.btn {
  &.secondary-button {
    background: var(--secondary-color) !important;
    color: var(--primary-color) !important;
    font-size: var(--font-size-14);
    font-weight:var(--font-weight-600);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover{
      background: var(--hover-button-color) !important;
      box-shadow: none !important;
      color: var(--hover-button-text-color) !important;
      .fill-svg-icons>path {
        fill: var(--hover-button-text-color) !important;
      }
    }
  }
}
.btn {
  &.send-msg-disable-btn {
    color:var(--gray7)  !important;
    background-color: var(--gray4) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
  }
}
.btn{
  &.create-video-btn{
    background: var(--primary-color) !important;
    color:var(--gray1) !important;
    font-size: var(--font-size-14);
    font-weight:var(--font-weight-500);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 24px;
    font-family: var(--font-family);
    &:hover{
      background: var(--hover-button-color) !important;
      box-shadow: none !important;
      color: var(--gray1) !important;
    }
  }
}

.btn{
  &.start-recording-btn{
    background: var(--primary-color) !important;
    color:var(--gray1) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover{
      background: var(--hover-button-color) !important;
      box-shadow: none !important;
      color: var(--gray1) !important;
    }
  }
}

.btn{
  &.watch-example-btn{
    background: var(--secondary-color) !important;
    color:var(--primary-color) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover{
      background: var(--secondary-color) !important;
      box-shadow: none !important;
      color: var(--primary-color) !important;
    }
  }
}

.btn {
  &.button-white {
    border: 1px solid $white-color;
    color: $white-color;
    background-color: $bg-info;
    font-size: var(--font-size-16);
    padding:5px 20px;
    text-transform: none;
    box-shadow: none;
    font-weight: var(--font-weight-600);
    border-radius: 8px;
    font-family: var(--font-family);
    &:hover{
      background-color: $bg-info;
      box-shadow: none;
      color: $white-color !important;
    }
  }
}
.btn{
  &.profile-list-btn{
    &.MuiButtonBase-root{
    color: var(--gray9);
    font-size: var(--font-size-14);
    padding:7px 20px;
    text-transform: none;
    box-shadow: none;
    font-weight: var(--font-weight-500);
    font-family: var(--font-family);
    &:hover{
      background-color: var(--secondary-color) !important;
      box-shadow: none;
      color: var(--primary-color) !important;
    }
  }
}
}
.btn{
  &.profile-list-btn-active{
    &.MuiButtonBase-root{
    font-size: var(--font-size-14);
    padding:7px 20px;
    text-transform: none;
    font-weight: var(--font-weight-500);
    font-family: var(--font-family);
    background-color: var(--secondary-color) !important;
    box-shadow: none;
    color: var(--primary-color) !important;
  }
}
}
.btn{
  &.disabled-button{
    color:var(--gray7)  !important;
    background-color: var(--gray4) !important;
    font-weight: var(--font-weight-600) !important;
    padding:5px 20px;
    text-transform: none;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);
    width:100%;
    &:hover{
      background-color: $bg-disable;
      box-shadow: none;
      color: $white-color !important;
    }
  }
}
.btn{
  &.button-header{
    color: var(--gray9);
    //font-size: var(--font-size-header);
    font-size: var(--font-size-14);
    padding:5px 12px;
    text-transform: none;
    box-shadow: none;
    font-weight: var(--font-weight-500);
    border-radius: 6px;
    font-family: var(--font-family);
    line-height: 20px;
    &:hover{
      background-color: var(--secondary-color) !important;
      box-shadow: none;
      color: var(--primary-color) !important;
    }
  }
}
.btn {
  &.activebutton-header {
    color: var(--primary-color);
    font-size: var(--font-size-14);
    padding:5px 12px;
    text-transform: none;
    box-shadow: none;
    font-weight: var(--font-weight-500);
    border-radius: 6px;
    font-family: var(--font-family);
    line-height: 20px;
    background-color: var(--secondary-color) !important;
  }
}
.btn {
  &.save-button {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: #FFFFFF;
    font-size: var(--font-size-16);
    padding: 5px 20px;
    text-transform: none;
    box-shadow: none;
    border-radius: 8px;
    font-family: var(--font-family);
  }

  &.all-unread-button {
    border: 1px solid var(--primary-color);
    background-color: $bg-white;
    color: var(--primary-color)  !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: var(--hover-button-color)  !important;
      box-shadow: none !important;
      color: var(--hover-button-text-color)  !important;
      .arrow-icon-id1 {
        background: var(--hover-button-text-color);
        color: var(--hover-button-text-color);
        opacity: 1;
      }
      .fill-svg-icons > path {
        fill: var(--hover-button-text-color) !important;
      }
    }
  }
  &.all-unread-button-selected {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: $bg-white;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: var(--hover-button-color)  !important;
      box-shadow: none !important;
      color: var(--hover-button-text-color)  !important;
      .arrow-icon-id1 {
        background: var(--hover-button-text-color);
        color: var(--hover-button-text-color);
        opacity: 1;
      }
      .fill-svg-icons > path {
        fill: var(--hover-button-text-color) !important;
      }
    }
  }
}
.btn {
  &.home-page-button {
    background: var(--primary-color) !important;
    color:var(--gray1) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover{
      background: var(--hover-button-color) !important;
      box-shadow: none !important;
      color: var(--gray1) !important;
    }
  }
}
.checklist-btn {
  .btn {
    width: 155px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.active-list {
  #demo-positioned-button {
    color: var(--primary-color) !important;
  }
}
#demo-positioned-button {
  color: #0050aa !important;
  display: block !important;
  cursor: pointer !important;
  font-size: var(--font-size-12) !important;
  text-transform: capitalize;
  margin-left: 33px !important;
  margin-top: 0px !important;
}
.sidebar-editDropdown {
  #demo-positioned-button {
    margin-left: 0px !important;
  }
}
.icon-button {
  :focus {
    outline: none !important;
    border: none !important;
  }
}
.from-job-btn {
  width: 20% !important;
}
.non-login-align {
  #demo-positioned-button {
    margin-left: 20px !important;
  }
}
.fill-data-btn {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}
.analytics-menu .analytics-active-color{
  font-size: var(--font-size-14)px;
  padding:7px 20px;
  text-transform: none;
  font-weight: var(--font-weight-500) !important;
  font-family: var(--font-family);
  background-color: var(--secondary-color) !important;
  box-shadow: none;
  color: var(--primary-color) !important;
  &.MuiMenuItem-root:hover {
    background-color: var(--secondary-color) !important;
  }
}

// bionic changes
.bionic-btn {
  button {
    min-width: 40px !important;
    margin-right: 5px !important;
    height: 30px !important;
    border-radius: 4px !important;
    &.Mui-disabled {
      color:var(--gray7)  !important;
      background-color: var(--gray4) !important;
      font-weight: var(--font-weight-600) !important;
      border-color: var(--gray7)  !important;
    }
    &:hover {
      background-color: none !important;
    }
  }
  .bionic-black {
    background-color: #000000 !important;
    &:hover {
      background-color: #000000 !important;
    }
  }
  .bionic-pink {
    background-color: #6941c6 !important;
    &:hover {
      background-color: #6941c6 !important;
    }
  }
  .bionic-blue {
    background-color: #1976d2 !important;
    &:hover {
      background-color: #1976d2 !important;
    }
  }
  .bionic-snow {
    background-color: #1a7fae !important;
    &:hover {
      background-color: #1a7fae !important;
    }
  }
  .bionic-green {
    background-color: #027a48 !important;
    &:hover {
      background-color: #027a48 !important;
    }
  }
  .bionic-maroon {
    background-color: #b42318 !important;
    &:hover {
      background-color: #b42318 !important;
    }
  }
}
.active-black-button {
  border: 2px solid #000000 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #000000 !important;
}

.active-pink-button {
  border: 2px solid #6941c6 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #6941c6 !important;
}

.active-blue-button {
  border: 2px solid #0071e3 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #0071e3 !important;
}

.active-snow-button {
  border: 2px solid #1a7fae !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #1a7fae !important;
}

.active-green-button {
  border: 2px solid #027a48 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #027a48 !important;
}

.active-maroon-button {
  border: 2px solid #b42318 !important;
  box-shadow: 0 0 0 3px #f3f5f6 inset;
  color: rgba(0, 0, 0, 0.87);
  background-color: #b42318 !important;
}

.active-gray-button {
  border: 2px solid #e4e7ec !important;
  color: #000000 !important;
  background-color: #e4e7ec !important;
}
.bionic-bold {
  button {
    min-width: 40px !important;
    height: 30px !important;
    margin-right: 5px !important;
    border-color: #ffffff !important;
    .MuiToggleButton-root {
      min-width: 25px !important;
      margin-right: 5px !important;
      height: 25px !important;
      text-align: center;
    }
  }
}
.bionic-save {
  button {
    border-radius: 4px !important;
    &:hover {
      background-color: none !important;
    }
    &.Mui-disabled {
      height: 42px !important;
    }
  }
  .bionic-blue {
    background-color: var(--primary-color) !important;
    &:hover {
      background-color: var(--primary-color) !important;
    }
  }
}
.border-none-bionic {
  border: none !important;
  &:hover {
    background-color: transparent !important;
  }
}
.text-transform {
  button {
    text-transform: none !important;
  }
}
.level-of-education-active {
  color: var(--primary-color) !important;
  background: rgba(0, 41, 98, 0.2) !important;
  border: 1px solid transparent !important;
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 5px 15px !important;
  margin-top: 5px !important;
  font-size: var(--font-size-16) !important;
}
.level-of-education {
  color: $grey-color !important;
  border: 1px solid var(--gray7) !important;
  border-radius: 8px !important;
  text-transform: none !important;
  padding: 5px 15px !important;
  margin-top: 5px !important;
  font-size: var(--font-size-16) !important;
}
.active-button-list {
  .MuiButton-root:hover {
    background-color: var(--secondary-color) !important;
  }
}
.non-signin-btn {
  width: 25% !important;
}
.daterangepicker {
  .drp-buttons {
    .applyBtn {
      border: 1px solid var(--primary-color)  !important;
      color: $white-color  !important;
      background-color: var(--primary-color)  !important;
      font-size: var(--font-size-16) !important;
      padding: 5px 20px !important;
      text-transform: none !important;
      box-shadow: none !important;
      border-radius: 8px !important;
      font-family: var(--font-family)  !important;

      &:hover {
        border: 1px solid var(--hover-button-color)  !important;
        background-color: var(--hover-button-color)  !important;
        color: var(--hover-button-text-color)  !important;
        box-shadow: none !important;
      }
    }
  }
}

.daterangepicker {
  .drp-buttons {
    .cancelBtn {
      border: 1px solid var(--primary-color)  !important;
      color: var(--primary-color)  !important;
      background-color: transparent !important;
      font-size: var(--font-size-16) !important;
      padding: 5px 20px !important;
      text-transform: none !important;
      box-shadow: none !important;
      border-radius: 8px !important;
      font-family: var(--font-family)  !important;

      &:hover {
        border: 1px solid var(--hover-button-color)  !important;
        background-color: var(--hover-button-color)  !important;
        color: var(--hover-button-text-color)  !important;
        box-shadow: none !important;
      }
    }
  }
}
.daterangepicker .ranges li.active {
  background-color: var(--primary-color) !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--primary-color) !important;
}

.daterangepicker td.in-range {
  background-color: var(--secondary-color) !important;
}

.daterangepicker td.off.in-range {
  background-color: #fff !important;
}
.btn { 
  &.preq {
    color: black !important;
    background-color: var(--gray1) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      color: black !important;
      background-color: var(--gray1) !important;
      box-shadow: none;
      cursor: default !important;
    }
  }
}
.MuiPickersBasePicker-container {
  .MuiToolbar-root {
    .MuiButton-root {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
.btn {
  &.danger-button {
    background: #FEF3F2;
    color: #B42318 !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding: 8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover {
      background: #FEF3F2  !important;
      box-shadow: none !important;
      color: #B42318  !important;
    }
  }
}
.arrow-icon-send-message {
  opacity: 1;
  padding: 0.5px;
  background: white;
  height: 43px;
}
.btn {
  &.parsing-button {
    background: var(--primary-color) !important;
    color:var(--gray1) !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding:8px 28px;
    text-transform: none;
    box-shadow: none;
    border-radius: 6px;
    font-family: var(--font-family);
    &:hover{
      background: var(--primary-color) !important;
      box-shadow: none !important;
      color: var(--gray1) !important;
    }
  }
}