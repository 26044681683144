.svmschat-btn-chat{
  background: var(--primary-color) !important;
}
.minimized-view-v2{
  margin-right: 8px !important;
}
.svmschat-popup-head {
  background: var(--primary-color) !important;
}
.svmschat-popup-head img{
  background: var(--primary-color) !important;
}
.svmschat-auto-popup-button a {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}
.svmschat-auto-popup-button a:hover {
  background-color: var(--primary-color) !important;
  color: #FFFFFF !important;
}
.svmschat-active-chat-head{
  background: var(--primary-color) !important;
}
.svmschat-btn-send-message span {
  color: var(--primary-color) !important;
}
.svms-close-chat:hover{
  background-color: var(--primary-color) !important;
}
.svms-close-chat-btn:hover{
  background-color: var(--primary-color) !important;
}
.connection-close h2{
  border-bottom: 1px solid var(--primary-color) !important;
}
.svmschat-avatar-wrap img {
  background: var(--primary-color) !important;
}
.msg-outgoing two_button.svmschat-message-action button {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}
.msg-outgoing two_button.svmschat-message-action button:hover {
  background: var(--primary-color) !important;
}
.msgs-outgoing-wrap ul li {
  background: var(--primary-color) !important;
}
.chatbot-container {
  padding: 15px 123px;
  border: 1px solid #EAE9F2;
}
// Haptic-bot css
.haptik-xdk{
  z-index: 999 !important;
  right: 5px !important;
}
.unauthorise-modal{
  width: 315px !important;
}
// ndccbot
.dsaas-chatbot-container{
  z-index: 9;
}
.minimized-view{
  color: #ffffff !important;
}
.minimized-view-text {
    font-size: 14px;
    font-weight: 800;
}
@media screen and (min-width: 200px) and (max-width: 600px){
  .svmschat-auto-popup{
    right: 0 !important;
  }
}

// Engagement Chatbot CSS
@media screen and (min-width: 200px) and (max-width: 800px) {
  .chatbot-main-container {
    width: 80vw !important;
  }
  .chatbot-expanded {
    width: 95vw !important;
  }
}
@media screen and (min-width: 800px) {
  .chatbot-main-container {
    width: 28vw !important;
  }
  .chatbot-expanded {
    width: 50vw !important;
  }
}
.chatbotNew {
  position: fixed;
  right: 1%;
  bottom: 10px;
  z-index: 9;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-4px);
  }
}
.chatbotNew-align {
  position: fixed;
  right: 1%;
  bottom: 55px !important;
  z-index: 9;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-4px);
  }
}
.chatbotNew-msg-align {
  position: fixed;
  right: 1%;
  bottom: 60px !important;
  z-index: 9;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-4px);
  }
}
.chatbotNew-msg-align-privacy {
  position: fixed;
  right: 1%;
  bottom: 115px !important;
  z-index: 9;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-4px);
  }
}
.chatbot-popper {
  position: fixed !important;
  right: 6.8% !important;
  bottom: 18px !important;
  transform: none !important;
  width: 308px !important;
  left: auto !important;
  z-index: 999;
}
.chatbot-popper-align {
  position: fixed !important;
  right: 6.8% !important;
  bottom: 133px !important;
  transform: none !important;
  width: 308px !important;
  left: auto !important;
  z-index: 999;
}
.chatbot-popper-msg {
  position: fixed !important;
  right: 6.8% !important;
  bottom: 71px !important;
  transform: none !important;
  width: 308px !important;
  left: auto !important;
  z-index: 999;
}
.chat-svg > g {
  fill: var(--secondary-color);
}
.chat-cross-svg {
    background-color: #ffffff;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0px 10px 6px;
    filter: drop-shadow(0px 2px 16px rgba(0,0,0,0.24));
    bottom: 10px;
    right: 1%;
}
.chatbot-outer-close-icon {
  width: 25px !important;
  height: auto !important;
  fill: var(--gray9) !important;
}
.new-chatbot-main-container {
  border-radius: 5px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: solid 1px #cccccc;
  position: fixed;
  bottom: 3%;
  right: 0.9%;
  left: auto;
  z-index: 1040; 
}
.react-chatbot-kit-chat-container {
  width: 360px !important;
}
.react-chatbot-kit-chat-btn-send {
  background-color: #121827 !important;
}
@keyframes chatbot-open {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  50% {
    width: 360px;
    height: calc(100vh - 120px);
    opacity: 1;
  }
  100% {
    width: 360px;
    height: calc(100vh - 120px);
    opacity: 1;
  }
}
.chatbot-main-container {
  background-color: #ffffff;
  border-radius: 10px;
  position: fixed;
  bottom: 10px;
  right: 0.9%;
  left: auto;
  height: calc(100vh - 120px);
  width: 360px;
  animation: chatbot-open 0.5s ease-out forwards;
  letter-spacing: 1.5px;
  line-height: 25px;
  border: solid 1px #cccccc;
  z-index: 1040; 
}
.chatbot-message {
  background-color: #F0F2F6;
  color: var(--gray11);
  border-radius: 10px;
  line-height: 1;
  height: max-content;
  width: max-content;
  max-width: 80%;
}
.chatbot-user-message {
  background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%);
  color: var(--gray1);
  border-radius: 10px;
  line-height: 1;
  height: max-content;
  width: max-content;
  max-width: 80%;
}
.header-wrapper {
  width: 100%;
  height: 64px;
  z-index: 20;
}
.cursor-grab {
  cursor: grab;
}
.cursor-grabbing {
  cursor: grabbing;
}
.header-chat {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 64px;
  background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%);
  top: 0px;
  right: 0;
  border-radius: 10px 10px 0px 0px;
}
.header-top {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px 10px 12px;
  box-sizing: border-box;
}
.header-top-left {
    display: flex;
    align-items: center;
    opacity: 1;
    transition: 0.1s ease-out;
    transition-delay: 0.2s;
}
.header-top-left-text {
  color: #595959;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.header-top-left-text-title {
  color: #FAFAFA;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.header-top-left-text-sub-title {
  color: #FAFAFA;
  font-size: 10px !important;
  font-weight: 500 !important;
}
.chatbot-close-icon {
  fill: #FFFFFF !important;
}
.text-wrapper {
  position: absolute;
  z-index: 4;
  width: 100%;
  right: 0;
  border-radius: 10px;
}
.messages-wrapper {
  height: calc(100vh - 241px);
  overflow-y: auto;
  overscroll-behavior-y: contain;
}
.messages-wrapper-reduced-height {
  height: calc(100vh - 261px) !important;
}
.chatbot-err-message-alignment {
  width: 100%;
  height: 20px;
  padding-left: 15px;
}
.chatbot-footer-wrapper {
  width: 100%;
  height: 64px;
}
.chatbot-input-field {
  .MuiInputBase-input {
    margin-bottom: 5px !important;
  }
  &.MuiTextField-root {
    margin-top: 12px !important;
  }
}
.chatbot-invalid-input {
  animation: invalidInput .2s ease;
  color: $danger-color;
  border: 1px solid $danger-color;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
@keyframes invalidInput{
  25% { transform: rotate(-1deg); }
  100% { transform: rotate(1deg); }
}
.chatbot-password-box {
  width: 100%;
  border: 1px solid var(--gray4);
  border-radius: 10px;
  padding: 20px;
}
.chatbot-feedback-box {
  width: 100%;
  padding: 20px;
}
.chatbot-jobcard-box {
  width: 100%;
  border: 1px solid var(--gray4);
  border-radius: 10px;
  // max-height: 300px;
  // overflow-y: auto;
  // overscroll-behavior-y: contain;
}
.chat-options {
  border: 1px solid #121827;
  color: #121827;
  padding: 6px 12px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 16px 11px -17px #121827;
    transition: all .3s ease-in-out;
    animation: pulse 1.5s;
  }
}
.chatbot-typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.chatbot-typing .chatbot-typing-dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: var(--gray11) ;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.chatbot-typing .chatbot-typing-dot:nth-child(1) {
  animation-delay: 200ms;
}
.chatbot-typing .chatbot-typing-dot:nth-child(2) {
  animation-delay: 300ms;
}
.chatbot-typing .chatbot-typing-dot:nth-child(3) {
  animation-delay: 400ms;
}
.chatbot-typing .chatbot-typing-dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:rgba(16, 24, 40, .7);
  }
  28% {
    transform: translateY(-7px);
    background-color:rgba(16, 24, 40, .4);
  }
  44% {
    transform: translateY(0px);
    background-color: rgba(16, 24, 40, .2);
  }
}
.new-chatbot-svg > g {
  > path {
    fill : #121827 !important;
  }
  > circle {
    fill: #121827 !important;
  }
}
.chatbot-header-svg > g > path {
  fill: #121827 !important;
}
.chatbot-send-icon {
  stop {
    stop-color: var(--primary-color) !important;
  }
  // &:hover {
  //   stop {
  //     stop-color: var(--hover-button-color) !important;
  //   }
  //   path {
  //     fill: var(--hover-button-text-color) !important;
  //   }
  // }
}
.home-page-chatbot-send-icon {
  stop {
    stop-color: #150349 !important;
  }
}
.chatbot-stop-icon {
  color: var(--primary-color);
  height: 25px !important;
  width: 25px !important;
}
.home-page-chatbot-stop-icon {
  color: #150349;
}
.btn {
  &.black-primary-button {
    background: var(--gray1) !important;
    color:var(--gray9) !important;
    border: 1px solid var(--gray9);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    padding:5px 10px 5px 10px;
    text-transform: none;
    box-shadow: none;
    border-radius: 5px;
    font-family: var(--font-family);
    &:hover{
      background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%) !important;
      box-shadow: none;
      color:var(--gray1) !important;
    }
  }
}
.chatbot-expanded {
  width: 660px;
  height: calc(100vh - 100px) !important;
  .messages-wrapper {
    height: calc(100vh - 221px) !important;
  }
  .messages-wrapper-reduced-height {
    height: calc(100vh - 241px) !important;
  }
}
.chatbot-card-hover-color {
  &:hover{
    background-color: #F9FAFB !important;
    .btn {
      &.black-primary-button {
        background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%) !important;
        box-shadow: none;
        color:var(--gray1) !important;
      }
    }
  }
}
.chatbot-card-hover-color-with-top-border-radius {
  &:hover{
    background-color: #F9FAFB !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .btn {
      &.black-primary-button {
        background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%) !important;
        box-shadow: none;
        color:var(--gray1) !important;
      }
    }
  }
}
.chatbot-card-hover-color-with-bottom-border-radius {
  &:hover{
    background-color: #F9FAFB !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .btn {
      &.black-primary-button {
        background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%) !important;
        box-shadow: none;
        color:var(--gray1) !important;
      }
    }
  }
}
.chatbot-card-hover-color-with-border-radius {
  &:hover{
    background-color: #F9FAFB !important;
    border-radius: 10px;
    .btn {
      &.black-primary-button {
        background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%) !important;
        box-shadow: none;
        color:var(--gray1) !important;
      }
    }
  }
}
.vertical-line {
  opacity: 0.4;
  padding: 0.5px;
  background: var(--gray6);
  height: 18px;
}
.chat-feedback-icon {
  circle {
    fill: #F2F2F2;
  }
  path{
    fill: #C7C7C7;
  }
}
.chat-feedback-selected-icon {
  circle {
    fill: #FFEEB3;
  }
  path{
    fill: #FFCA0E;
  }
}
.chatbot-feedback-button {
  width: fit-content;
  background: var(--gray1) !important;
  color: #121827 !important;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
  padding: 8px 28px;
  text-transform: none;
  box-shadow: none;
  border: 1px solid #121827;
  border-radius: 6px;
  font-family: var(--font-family);
  &:hover{
    background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%) !important;
    box-shadow: none !important;
    color: var(--gray1) !important;
  }
}
.chatbot-restart-conersation-button {
    margin-top: 10px !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    height: 36px;
    background: var(--gray1) !important;
    color: #121827 !important;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    text-transform: none;
    box-shadow: none;
    border: 1px solid #121827;
    border-radius: 8px;
    font-family: var(--font-family);
    &:hover{
      background: linear-gradient(180deg, #121827 0%, rgba(18, 24, 39, 0.81) 100%) !important;
      box-shadow: none !important;
      color: var(--gray1) !important;
    }
}
.chatbot-feedback-disabled-button {
  width: fit-content;
  color: var(--gray7)  !important;
  background-color: var(--gray4) !important;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600) !important;
  padding: 8px 28px;
  text-transform: none;
  box-shadow: none;
  border-radius: 6px;
  font-family: var(--font-family);
  &:hover{
    background-color: var(--gray4) !important;
    box-shadow: none;
    color: var(--gray7) !important;
  }
}
.chatbot-link {
  color: #0000FF !important;
  text-decoration: underline;
}
.chatbot-mic {
  width: 20px !important;
  height: 20px !important;
}
.chatbot-mic-off {
  width: 30px !important;
}
.chatbot-mic-on {
  border: none;
  padding: 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 50px;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  margin: 0;
  background: #fff;
	position: relative;
	z-index: 999;
  display: inline-block;
  line-height: 15px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
}
.chatbot-mic-pulse-ring {
  content: '';
  width: 28px;
  height: 28px;
	background: transparent;
  border: 5px solid rgba(0, 0, 0, 0.54);
  border-radius: 50%;
  position: absolute;
  left: 1px;
  animation: pulsate infinite 2s;
}
@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
  }
}
.white-info-icon {
  fill: #FFFFFF !important;
}
.chatbot-info-card-border {
  border: 1px solid #E4E4E4;
  border-radius: 10px;
  padding: 10px 10px 10px 0px;
}
