// modal title
#modal-title {
  /* position: fixed;*/
  width: 100%;
  /* padding: 10px;*/
  color: #1F1F1F;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-600);
}

// modal description
#modal-description {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
  padding: 5px;
}
.modal-content-survey {
  #modal-description {
    max-height: 400px;
    overflow-y: auto;
    margin: 10px 0;
    padding: 5px;
  }
}
.non-signin-modal {
  #modal-description {
    max-height: 365px !important;
    padding-left: 7px !important;
    padding-right: 20px !important;
    min-height: 100%;
  }
}
.video-record{
  #modal-description{
    height:270px !important;
  }
}
.feebackModal-height {
  max-height: 350px !important;
}

// modal content changes
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: $bg-white;
  padding: 25px;
  border-radius: 8px;
  &:focus-visible {
    outline: none;
  }
}
.modal-neuro {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 570px !important;
  background-color: $bg-white;
  padding: 25px;
  border-radius: 8px;
  &:focus-visible {
    outline: none;
  }
}
.modal-content-survey {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px !important;
  background-color: $bg-white;
  padding: 25px;
  border-radius: 8px;
  &:focus-visible {
    outline: none;
  }
}
.modal-content-feedback {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px !important;
  background-color: $bg-white;
  padding: 25px;
  border-radius: 8px;
  &:focus-visible {
    outline: none;
  }
}
.modal-content-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px !important;
  background-color: $bg-white;
  padding: 25px;
  border-radius: 8px;
  &:focus-visible {
    outline: none;
  }
}
.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  width: 410px;
  border-radius: 8px;
  text-align: center;
  background-color: white;
  &:focus-visible {
    outline: none !important;
  }
}
.modal-content.work-exclusivity-modal-content {
  width: 550px !important;
}
.modal-content {
  &.checklist-modal {
    width: 900px !important;
  }
}
.modal-content {
  &.stepperview-modal {
    width: 1024px !important;
  }
}

// modal footer changes
#modal-footer {
  /* position: fixed;*/
  /* bottom: 12px;*/
}

// @media screen and (min-width: 320px) and (max-width: 767px) {
//     .modal-content{
//         width: 380px;
//     }
// }
@media screen and (min-width: 320px) and (max-width: 767px) {
  .modal-content {
    &.checklist-modal &.responsive-checklist-modal {
      width: 380px !important;
    }
  }
}



$color-blue: #00aeef;
$color-blue-dark: #0d2035;
$submenu-width: 180px;
nav {
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.nav {
display: inline-block !important;
a {
  display: block;
  line-height: inherit;
  cursor: pointer;
  //color:#FFFFFF !important;
  font-family: var(--font-family) !important;
}
}
.nav__menu-item {
  display: flex;
  position: relative;
  &:hover {
    .nav__submenu {
      display: block;
      // padding:10px !important;
      background-color: #FFFFFF !important;
      border-radius: 0px !important;
      // color: #FFFFFF !important;
      margin-top: 0px !important;
      left: 55px;
      // bottom: -29px;
      margin-top: -21px !important;
      border: 1px solid #EAE9F2;
      box-shadow: 0px 6px 14px -6px rgba(90, 136, 193, 0.12), 0px 10px 32px -4px rgba(90, 136, 193, 0.12);
    }
  }
}
.modal-decline-offer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 570px !important;
  background-color: $bg-white;
  padding: 25px;
  border-radius: 8px;
  &:focus-visible {
    outline: none;
  }
}