/* Responsive */

@media screen and (min-width: 200px) and (max-width: 600px) {
  .chat-container {
    display: none !important;
  }
  .hourly-rate-res {
    justify-content: flex-start !important;
  }
  .modal-content,
  .modal-neuro,
  .modal-content-feedback,
  .modal-content-video {
    width: 360px !important;

    // margin-top:30px !important;
    .input-group {
      width: 99% !important;
    }
  }

  .modal-content {
    &.stepperview-modal {
      width: 340px !important;
    }
  }

  .modal-content.work-exclusivity-modal-content {
    width: 250px !important;
  }

  .modalStyle {
    width: 310px !important;
    max-height: 250px !important;
    padding: 10px !important;

    button {
      width: 45% !important;
    }
  }

  .skillIcon {
    font-size: 11px !important;
    margin: 0px !important;
  }

  .MuiDialog-root {
    .MuiDialog-scrollPaper {
      .MuiPaper-root {
        min-width: 315px !important;
      }
    }
  }

  .file-extension-image {
    width: 50px !important;
  }

  .jobProfile {
    img {
      height: 40px !important;
      width: 40px !important;
    }
  }

  .jobsFound {
    font-size: 15px !important;
  }

  .noJobsImage {
    img {
      width: 140px !important;
    }
  }

  .jobStatus {
    padding-left: 20px !important;
  }

  .myjobs {
    img {
      width: 55px;
      height: 55px;
    }
  }

  .file-upload {
    display: block !important;
  }

  .phoneEmail {
    padding: 30px 0px !important;
  }

  .workDocument {
    p {
      width: 150px !important;
    }
  }

  .noResult {
    width: 270px !important;
  }

  .apply-spacing {
    margin-bottom: 20px !important;
  }

  .responsive-tab {
    padding-left: 28px !important;
  }

  .responsive-layout {
    padding: 0 !important;
  }

  .recommendJob-detail {
    padding: 80px 0px 0px 0px !important;
  }

  .responsive-list {
    margin-top: 85px !important;
  }

  .responsive-no-results-image {
    img {
      width: 150px !important;
    }
  }

  .menu-list {
    top: 81px !important;
  }

  .contact-responsive {
    padding: 0px !important;
  }

  .notification-responsive {
    padding-top: 10px !important;
    padding-right: 0px !important;
    padding-left: 32px !important;
  }

  .logoResponsive {
    width: 200px !important;
  }

  .settingsInput {
    .form-control {
      &.input-group {
        /*width:61% !important;*/
      }
    }
  }

  .matching-tab .MuiTabs-root {
    .MuiTabs-flexContainer {
      display: grid;
    }
  }

  .matching-tab .MuiTabs-root .MuiTab-root {
    margin: 10px;
    padding: 10px !important;
  }

  .profileName {
    display: contents;
  }

  .notification-body {
    li {
      white-space: break-spaces;
    }
  }

  .responsive-chat-section {
    max-height: 11em !important;
    margin-bottom: 20px !important;
  }

  .analytics-report {
    .MuiPaper-root {
      left: -20px !important;

      .MuiList-root {
        position: fixed;
        left: 55px;
        top: 81px;
        background: #ffffff;
        box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
        // padding: 25px !important;
        height: 100vh;
        width: 170px !important;
      }
    }
  }

  .policy-responsive {
    width: 100% !important;
  }

  .iphone-issue {
    //margin-top: 115px !important;
  }

  .bg-privacy {
    width: 71%;
    padding: 25px;

    .display-flex {
      display: grid !important;
    }
  }

  .responsive-link {
    margin-right: 11em !important;
  }

  .responsive-checklist {
    padding-left: 0 !important;
  }

  .responsive-setting {
    padding: 0 0 0 0 !important;
  }

  .navbar-fixed {
    padding: 0;
  }

  .resolution-view {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .posted-date {
    margin: 0px !important;
  }

  .my-profile-dropdown {
    .MuiPaper-root.MuiPopover-paper {
      top: 265px !important;
      left: 190px !important;
    }
  }

  .sidebar-dropdown {
    .MuiPaper-root.MuiPopover-paper {
      min-height: 100vh !important;
    }
  }

  .uploadFile {
    &.profileSpacing {
      display: block !important;
    }
  }

  .profileSpacing {
    height: 320px !important;
    // padding-top: 7em !important;
  }

  .sticky-issue {
    top: 63px !important;
  }

  .contact-setting-align {
    width: auto !important;
  }

  .certificate-width-small {
    width: 150px !important;
  }

  .footer-view-align {
    //margin-bottom: 90px !important;
  }

  .find-jobs-sticky {
    position: static !important;
    // top:50px !important;
    padding-top: 40px !important;
  }

  .reset-res-align {
    position: relative;
    z-index: 1;
  }

  .job-detail-align {
    margin-top: 20px !important;
  }

  .apply-job-align {
    margin-top: 5px !important;
  }

  .posted-align-view {
    margin-top: 10px !important;
  }

  .search-filter-align {
    margin-top: 35px !important;
  }

  .btn-space-align {
    margin-left: 0px !important;
  }

  .detail-job-align {
    text-align: center !important;
  }

  .my-job-list-align {
    justify-content: end !important;
  }

  .joblist-deatail-align {
    height: auto !important;
  }

  .nav-video-img {
    .video-image {
      width: 42px !important;
      height: 42px !important;
    }
  }

  .publicProfile {
    background: rgba(255, 255, 255, 1) !important;
    top: 5px !important;
    right: 5px !important;
  }

  .signup-res-align {
    //margin-top:210px !important;
  }

  .onboard-modal-stepper {
    max-height: 480px !important;
  }

  .profile-tabs {
    .MuiTabs-flexContainer {
      width: max-content !important;
    }
  }

  .edit-refer {

    .profileImage,
    .video-image-cover {
      width: 78px !important;
      height: 78px !important;
      object-fit: cover;
    }
  }

  .profilePhotoContainerPublicProfile {
    width: 100px !important;
    height: 100px !important;
  }

  .posted-job-align {
    margin-bottom: 10px !important;
  }

  .nps-view-align {
    margin-left: 20px !important;
  }

  .hourly-rate-align {
    .zooming-issue {
      margin-top: 0px !important;
    }
  }

  .update-delete-profile {
    min-height: 37px !important;
  }

  .rating-percent-align {
    justify-content: end !important;
    display: flex !important;
  }

  .user-detail-align {
    padding-bottom: 15px !important;
  }

  .responsive-contents {
    display: contents !important;
  }

  .profile-name-align {
    margin-top: 100px !important;
  }

  .edit-profile-align {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }

  .view-as-public {
    margin-top: -15px !important;
  }

  .sideIcon-align {
    display: flex !important;

    svg {
      margin-top: 7px !important;
    }
  }

  .signup-align {
    margin-top: 100px !important;
  }

  .notification-value {
    line-height: 20px !important;
  }

  .no-messages {
    padding-bottom: 0px !important;

    .message-height {
      // height: 0px !important;
    }

    .noList {
      margin-left: 0px !important;
      margin-top: 10px !important;
    }

    img {
      width: 100% !important;
    }
  }

  .checklist-profile {
    align-items: flex-start !important;

    span svg {
      margin-top: 2px !important;
    }
  }

  // .location-shorten{
  //   .search-radius{
  //     border-radius: 8px !important;
  //   }
  // }
  // .search-radius{
  //   border-radius: 8px !important;
  // }
  // .search-bg{
  //   background: none !important;
  // }
  .border-res {
    border: none !important;
  }

  .joblist-drawer, .exercise-drawer {
    .MuiDrawer-paper {
      width: 97% !important;
    }
    .drawer-align{
      padding: 20px !important;
    }
  }

  .notification-drawer {
    .MuiDrawer-paper {
      width: 90% !important;
    }
  }

  .hire-faster {
    align-items: flex-start !important;
  }

  .personal-detail {
    padding-right: 0px !important;
  }

  .login-width {
    width: 90% !important;
    margin: 0px auto !important;
  }

  .login-responsive {
    align-items: flex-start !important;
  }

  .password-privacy {
    position: absolute !important;
    width: 100%;
    left: 0px;
  }

  .notifyAlign {
    justify-content: left !important;
  }

  .hc-carousel {
    .carousel-label {
      padding: 20px !important;

      .f-44 {
        font-size: var(--font-size-24) !important;
      }
    }
  }

  .responsive-settings {
    padding: 0 !important;
  }

  .settings-sidebar-fix {
    position: absolute;
    z-index: 0;
    width: 100% !important;

    .settings-sidebar {
      padding: 0 !important;
      // margin-right: 30px;
    }
  }

  .settings-body {
    margin-left: 0 !important;
    padding-top: 30em;
    border-left: 0;
    width: 100% !important;
    padding-right: 0;
  }

  .interview-btn {
    margin-right: 5px !important;
  }

  .interview-filters {
    position: static !important;
  }

  .Launch-page-pad {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .px-245 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .font-47 {
    font-size: 37px !important;
  }

  .section1 {
    padding: 4rem 0.9rem;
  }

  .pt-80 {
    padding-top: 0px !important;
  }

  .footer-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .res-social-login {
    width: 50% !important;
    margin: 0px auto !important;
  }

  .create-pass-align {
    margin-bottom: 60px !important;
  }

  .report-btn {
    padding-left: 6px !important;
  }

  .check-modal {
    .responsive-checklist-modal {
      #modal-description {
        max-height: 330px !important;
      }
    }
  }

  .modal-content.checklist-modal {
    width: 90% !important;
  }

  .my-search-collapse.show {
    padding: 10px !important;
  }

  .license-align {
    display: inline-flex !important;
  }

  .edit-delete-profile {
    margin-right: 10px !important;
  }

  .share-job-icon {
    .rating-percent-align {
      display: flex !important;
    }
  }

  .bg-color-res-none {
    border-radius: 8px !important;
    padding: 20px !important;

    button {
      width: 100% !important;
      padding: 5px !important;
    }
  }

  .create-video-justify {
    justify-content: center !important;
  }

  .record-img-height {
    height: 100% !important;
  }

  .video-url-width {
    width: 70px !important;
  }

  .hideScroll {
    .MuiDrawer-paper {
      overflow-y: auto !important;
    }
  }

  .onboard-height {
    max-height: fit-content !important;
  }

  .skill-checklist-autocomplete {
    .MuiAutocomplete-endAdornment {
      right: -15px !important;
    }
  }

  .horizontal-stepper-align {
    .MuiStepConnector-root {
      margin-left: 8px !important;
    }
  }

  .header-res {
    .responsive-checklist {
      padding-left: 0 !important;
    }
  }

  .last-used-align {
    padding-top: 0px !important;
  }

  .apply-job-res {
    .MuiStepLabel-root {
      align-items: flex-start !important;
    }
  }

  .rating-progress {
    width: 100% !important;
  }

  .neuro_switch {
    display: flex !important;
    margin-left: auto;
  }

  .mobile-column-reverse {
    flex-flow: column-reverse !important;
  }

  .dashboard-card {
    min-width: 5px;

    .responsive-img-offer {
      width: 25px;
      height: 25px;
    }

    .h3-text {
      font-size: var(--font-size-14) !important;
    }

    .supporting-text-large {
      font-size: 9px !important;
    }

    .galaxy-padding-top-20 {
      padding-top: 20px;
    }
  }

  .setting-margin {
    margin-top: 130px !important;
  }

  .my_jobs_infinity {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
  .upload-margin-sm{
    margin-right: 0px !important;
  }
  .d-initial-mobile{
    display: initial !important;
  }
  .flex-wrap-mob{
    flex-wrap: wrap !important;
  }
  .neuro-priv-logo{
    padding-left: 29px !important;
  }
  .privacy-logo-align{
    padding-left: 25px !important;
}
  .interview-align{
    width: 110px !important;
  }
  .job-title-align{
    width: 270px !important;
  }
  .qr-align-sm{
    width: 70%;
    margin: 0px auto;
  }
  .profilepx5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .cookie-view{
    margin-top: 55px !important;
  }
  .policy-res-align{
    padding-left: 10px !important;
  }
  .navBar ul{
    padding-left: 12px !important;
    li{
      padding-right: 14px !important;
      a{
        font-size: 12px !important;
      }
    }
  }
  .privacy-tabs{
    padding-left: 20px !important;
  }
  .res-min-max-w{
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    white-space: break-spaces !important;
  }
  .overlay-image img {
    position: absolute;
    top: 31px !important;
    left: 31px !important;
}
.application-tabs {
  button {
    height: 71px !important;
    .resume-label {
      padding: 2px 10px !important;
    }
    .additional-label {
      padding: 2px 5px 2px 20px !important;
    }
  }
}
.next-btn-align{
  margin-bottom: 60px !important;
}
.video-preview .video-job .videoSection video{
  width: 305px !important
}
}
// small device to large device resolution
@media screen and (min-width: 300px) and (max-width: 1024px) {
  .logoImg {
    width: 150px !important;
    height: 42px !important;
  }

  .navBar {
    overflow: hidden;
    padding-bottom: 4px;
  }

  .navLogo {
    justify-content: start !important;
    align-items: center !important;
    vertical-align: middle !important;
    display: flex !important;
  }

  .navBar ul li {
    padding-right: 25px;
  }

  .navBar ul li .active {
    padding-bottom: 6px !important;
  }

  .policyInfo {
    margin-left: 0 !important;
    padding: 0 !important;
    max-width: 480px !important;
  }

  .sub-info,
  .web-image {
    display: none;
  }

  // .left-side-section-image {
  //   display: none !important;
  // }

  .logo,
  .home-page button,
  .img-width {
    width: 50% !important;
  }

  // .pofileTitle{
  //   font-size: 12px !important;
  // }
  .infoFont {
    font-size: var(--font-size-14) !important;
  }

  .launchTitle {
    font-size: var(--font-size-16) !important;
  }

  .titleFont {
    font-size: 21px !important;
  }

  .aboutCandidate {
    padding-left: 0 !important;
  }

  // .eduIcon{
  //   padding:15px !important
  // }
  // .degreeName{
  //   padding-left:15px !important;
  // }

  .updateFile {
    justify-content: right !important;
  }

  .lunaImage {
    text-align: center !important;
  }

  .candidateTopmargin {
    margin: 0 !important;
  }

  .candidateSpace {
    padding: 10px 0 !important;
  }

  // .profilepx5 {
  //   padding-right: 5px !important;
  //   padding-left: 5px !important;
  // }

  .uploadImage {
    left: 22px !important;
  }

  // .eduEditIcon{
  //   margin-top: 30px !important;
  // }
  .responsive-singin-section {
    &.policyCandidate {
      // margin-top: 26em !important;
    }
  }

  .column-reverse {
    /*flex-direction: column-reverse !important;*/
    flex-flow: column-reverse !important;
  }

  .profileImage,
  .video-image-cover {
    width: 100px !important;
    height: 100px !important;
    object-fit: cover;
  }

  .input-otp input {
    margin-right: 13px !important;
  }

  .ellipseResponsive {
    width: 150px !important;
  }

  .updateImage {
    left: 40px !important;
    margin-top: 0 !important;
  }

  .updateProfile {
    top: 60px;
    left: 1px;
    height: 40px;
    width: 97%;
  }

  .profileName {
    padding-top: 25px;
    // margin-left:0 !important;
    // padding-left:15px !important;
  }

  /*  .profileBgImage{
      margin-top:58px !important;
    }*/
  // .modal-content{
  //   width: 340px !important;
  //   margin-top:60px !important;
  //   .input-group{
  //     width:99% !important;
  //   }
  //   .modal-content{
  //     width: 420px !important;
  //     margin-top:40px !important;
  //     .input-group{
  //       width:99% !important;
  //     }
  //   }
  // }
  .registrationBtn {
    padding-bottom: 46px !important;
  }

  /* .registrationTitle{
     padding-top:70px !important;
   }*/
  .loginSocial {
    padding-bottom: 5px;
  }

  .findJobs {
    padding: 20px 0 0 10px !important;
  }

  .dashboard-section {
    padding-bottom: 20px !important;

    .candidateTopmargin {
      padding-right: 10px;
      font-size: 13px !important;
    }
  }

  .w-300 {
    width: 200px !important;
  }

  .stepperUser {
    width: 94px;
    height: 40px;
  }

  // .infoFont{
  //   margin: 13px 2px;
  // }
  .skillImage {
    width: 50px !important;
  }

  .modalTitle {
    width: 230px !important;
  }

  .profilePhotoContainer {
    top: 175px !important;
  }

  .profilePhotoContainerPublicProfile {
    top: 110px !important;
  }

  #CircularProgressbarWithChildren__children {
    margin-top: 30%;
  }

  .profile-percentage {
    justify-content: center;
    display: flex;
  }

  .profile-btn {
    // margin-top: 38px !important;
  }

  .editprofile-btn {
    margin-top: 35px !important;
  }

  .public-profile {
    height: 270px !important;
  }

  .responsive-block {
    display: block !important;
  }

  .responsive-column-reverse {
    flex-direction: column-reverse !important;
  }

  .responsive-content {
    display: contents !important;
  }

  .responsive-filter {
    display: block !important;

    p {
      align-items: center;
      justify-content: center;
      display: flex;

      .chip-dropdown {
        padding: 5px 40px;
        font-size: var(--font-size-18);
      }
    }
  }

  .joblist-position {
    position: initial !important;
  }

  .margin-left-zero {
    margin-left: 0px !important;
  }

  .application-status-responsive {
    .MuiStepConnector-root {
      margin-left: 11px !important;

      .MuiStepConnector-line {
        width: 2px !important;
      }
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 1180px) {

  // .navLogo{
  //   img{
  //     height:30px !important
  //   }
  // }
  .btn {
    &.button-header {
      color: #667085;
      font-size: var(--font-size-16);
      padding: 5px 5px;
      text-transform: none;
      box-shadow: none;
      font-weight: var(--font-weight-500);
      border-radius: 6px;
      font-family: var(--font-family);
      line-height: 20px;
    }
  }

  .btn {
    &.activebutton-header {
      color: var(--primary-color);
      font-size: var(--font-size-16);
      padding: 5px 5px;
      text-transform: none;
      box-shadow: none;
      font-weight: var(--font-weight-500);
      border-radius: 6px;
      font-family: var(--font-family);
      line-height: 20px;
      background-color: var(--secondary-color) !important;
    }
  }

  .name-email {
    justify-content: normal !important;

    .textEllipse {
      // width:175px !important;
    }
  }
  .profilePhoto {
    // top: 12px !important;
  }

  .profileBgImage {
    .bg-image {
      height: 130px !important;
      margin-bottom: 11px !important;
    }

    .justify-end {
      height: 130px !important;
    }
  }
}

// Mobile to tablet view
@media screen and (min-width: 320px) and (max-width: 768px) {
  .responsive-share {
    padding: 10px;
    width: 100%;
  }
  .responsive-qr {
    margin-left: auto;
    margin-right: auto; 
  }
  .loginSocial {
    padding-bottom: 40px;
  }

  .responsive-tabs {
    display: block !important;
  }

  .navLogo {
    padding-left: 10px !important;
  }

  .responsive-display-none {
    display: none !important;
  }

  .search-btn-align {
    margin-left: auto !important;
  }

  .search-bg {
    background: none !important;
  }

  .location-shorten {
    .search-radius {
      border-radius: 8px !important;
    }
  }

  .search-radius {
    border-radius: 8px !important;
    padding-right: 0px !important;
  }

  .search-job {
    .MuiInputBase-root {
      .MuiInputBase-input {
        border-radius: 8px !important;
      }
    }
  }

  .video-image {
    // width: 300px !important;
    // height: 250px !important;
  }

  // .modal-content.checklist-modal {
  //   width: 90% !important;
  // }
  .menu-list {
    display: none !important;
  }

  .sidebar-dropdown {
    .MuiPaper-root.MuiPopover-paper {
      min-height: 100vh !important;
      width: 500px !important;
      left: auto !important;
    }
  }

  .public-view {
    padding-left: 0px !important;
  }

  .edit-public-view {
    margin-left: 0px !important;
  }

  .stepperview-modal {
    #modal-description {
      padding-right: 0px !important;
      margin-top: 20px !important;
    }
  }

  .stepper-tab-align {
    .MuiBox-root {
      padding-left: 0px !important;
    }
  }

  .stepper-data-align {
    padding-top: 20px !important;
  }

  .MuiStepConnector-root {
    .MuiStepConnector-line {
      height: 15px !important;
    }
  }

  .notify-list {
    padding-bottom: 0px !important;
  }

  .signin-title {
    padding-top: 20px !important;
  }

  .google-align {
    justify-content: center !important;
  }

  .portal-align {
    width: 100% !important;
    margin-top: 0px !important;
  }

  .left-side-section-image {
    display: none !important;
  }

  .neuro-diversity-background{
    background-image: none;
    .neurodiversity-outlet{
      width: 100% !important;
    }
  }
  .align-center-big-screen {
    .neuro-diversity-section {
      display: none !important;
    }
    .neuro-right {
      right: 0 !important;
    }
  }
  .create-file {
    .choose-file {
      width: 100% !important;
    }
  }

  .responsivegrid {
    width: 100% !important;
  }

  .skill-onboard-view {
    margin: 0px !important;
  }

  .onboard-skill-res {
    padding-right: 10px !important;
  }

  .Launch-page-mar {
    margin-top: 20px !important;
  }

  .px-245 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .content-right-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .content-left-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .footer-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
}

// iPhone 12 Pro responsive view
@media screen and (min-width: 390px) and (max-width: 414px) {
  .signup-align {
    margin-top: 0px !important;
  }

  .privacy-mobile {
    // position: absolute !important;
    // bottom:10px !important;
  }

  .certificate-width {
    width: 200px !important;
  }

  .responsive-checklist-modal {
    #modal-description {
      max-height: 570px !important;
    }
  }

  .modal-content.checklist-modal {
    width: 91% !important;
  }

  .setting-manage {
    margin-top: 20px !important;
  }

  .galaxy-share {
    width: 280px !important;
  }

  .popover-galaxy {
    .MuiPaper-root.MuiPopover-paper:before {
      left: 80px !important;
    }
  }

  .galaxy-global {
    width: 130px !important;
  }
  .referal-sort-align{
    width: max-content !important;
  }
  .nosave-align{
    margin-top: 0px !important;
  }
  .policy-res-align{
    padding-left: 25px !important;
  }
  .navBar ul{
    padding-left: 28px !important;
    li{
      padding-right: 25px !important;
      a{
        font-size: 14px !important;
      }
    }
  }
}

@media screen and (min-width: 425px) and (max-width: 516px) {
  .profilePhotoContainer {
    left: 20px !important;
  }
  .navBar ul li .active {
    padding-bottom: 18px !important;
  }

  .logoImg-header {
    height: 50px !important;
    padding: 0px !important;
  }
  .searchBarMargin {
    margin-left: 56px !important;
  }
}

@media screen and (min-width: 677px) and (max-width: 1024px) {
  .uploadResume {
    .file-extension-image {
      width: 7% !important;
    }
  }

  .overflow-auto {
    overflow: auto !important;
  }
  .searchBarMargin {
    margin-left: 40px !important;
  }

  /*.profilePhotoContainer {
    left: 25px !important;
  }*/
}

@media screen and (min-width: 688px) and (max-width: 768px) {
  .my-profile-dropdown {
    .MuiPaper-root.MuiPopover-paper {
      top: 314px !important;
      left: 440px !important;
    }
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        left: 440px;
        top: 128px;
      }
    }
  }

  .message-listAlign {
    height: auto !important;
    padding-right: 0px !important;
  }

  .user-logo-align {
    margin-left: 25px !important;
  }

  .responsive-tablet-view {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .publicProfile {
    background: rgba(255, 255, 255, 1) !important;
  }

  .modal-content {
    &.stepperview-modal {
      width: 730px !important;
    }
  }

  .onboard-modal-stepper {
    max-height: 450px !important;
  }

  .find-jobs-sticky {
    position: static !important;
    padding-top: 40px !important;
  }

  .reset-res-align {
    position: relative;
    z-index: 1;
  }

  .publicProfileView {
    align-items: center;
    display: flex;
    margin-top: 0px !important;
  }

  .signup-align {
    margin-top: 100px !important;
  }

  .resume-align {
    width: 100% !important;
  }

  .login-width {
    // width:70% !important;
  }

  .tablet-signin {
    height: auto !important;
  }

  .tablet-forgot {
    height: 100vh !important;
  }

  .password-privacy {
    position: absolute !important;
    left: 0px !important;
  }

  .onboard-tablet-view {
    margin-top: 20px !important;
  }

  .skill-delete-align {
    justify-content: end !important;
  }

  .justifyCenter {
    display: flex;
    justify-content: center;
  }

  .pt-80 {
    padding-top: 30px !important;
  }

  .applySpacing {
    margin-bottom: 20px !important;
  }
  .policy-align{
    min-width: 100% !important;
  }
}

@media screen and (min-width: 688px) and (max-width: 1024px) {
  .navBar ul li .active {
    padding-bottom: 18px !important;
  }

  .icon-min-width1 {
    min-width: 45px !important;
  }

  .icon-min-width3 {
    min-width: 35px !important;
  }

  .navBar ul a {
    font-size: var(--font-size-20) !important;
  }

  .logo {
    width: 22% !important;
  }

  // .modal-content{
  //   width:380px !important;
  // }
  .main-header-text {
    padding-top: 65px;
  }

  /* .bg-grey{
    padding-top: 200px !important;
  }*/
  .navBar ul li .active {
    padding-bottom: 18px !important;
  }

  .navBar ul a {
    font-size: var(--font-size-20) !important;
  }

  .logo {
    width: 22% !important;
  }
}

// Tablet view
@media screen and (min-width: 720px) and (max-width: 768px) {
  .google-btn-margin {
    margin-left: 16px;
    margin-right: 16px;
  }

  .neuro_switch {
    display: flex !important;
    margin-left: auto;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .google-btn-margin {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .profilePhoto {
    top: 12px !important;
  }

  .filter-list {
    margin: 0px auto !important;
  }

  .joblist-position {
    height: 570px !important;
  }

  .candidate-name-width {
    min-width: 30px !important;
    max-width: 55px;
  }

  .ipad-mini-align {
    width: auto !important;
  }

  .pl-mini {
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 1032px) and (max-width: 1440px) {
  .profilePhotoContainer {
    // left: 245px !important;
  }

  .overflow-auto {
    overflow: auto;
  }

  .payrate-padding {
    // padding-right: 10px;
  }

  // launch page
  .width-responsive {
    width: 640px !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1440px) {
  .joblist-position {
    height: 945px !important;
  }
}

@media screen and (min-width: 1860px) and (max-width: 2560px) {
  .uploadResume {
    .file-extension-image {
      width: 7% !important;
    }
  }

  .MuiContainer-root {
    max-width: 2400px !important;
  }

  .registration {
    padding-right: 320px !important;
  }
  .profilePhotoContainer {
    left: 450px !important;
  }
  .searchBarMargin {
    margin-left: 56px !important;
  }
}

@media screen and (min-width: 2033px) and (max-width: 2560px) {
  .joblist-position {
    height: 58vh !important;
  }
}

/*@media screen and (min-width: 1441px) and (max-width: 2560px) {
.responsive-footer{
  margin: 0 -325px !important;
}
}*/

/*
@media screen and (min-width: 1032px) and (max-width: 1440px) {
  .searchBarMargin{
    margin-left: 40px !important;
  }
}*/

// 200% - 400% zooming issue fixes //
@media screen and (min-width: 200px) and (max-width: 640px) {
  .modal-content {

    // width: 335px !important;
    // padding:10px 10px 5px 10px !important;
    form {
      // margin-top:1px !important;
    }
  }

  .modal-content {
    &.stepperview-modal {
      width: 335px !important;
    }
  }

  #modal-title {
    // font-size:16px !important;
  }

  #modal-description {
    // max-height: 121px !important;
    // margin:0px !important;
  }

  #modal-footer {
    // margin-top:5px !important;
  }

  .cityName input {
    margin-top: 0px !important;
    height: 27px !important;
    font-size: var(--font-size-14) !important;
  }

  .form-control.input-group {

    // margin-top:0px !important;
    // height:27px !important;
    // font-size:12px !important;
    .MuiInput-input {
      // height:3px !important;
      // font-size: 10px !important;
      // padding-left:5px !important;
    }
  }

  ::placeholder {
    // font-size:10px !important;
  }

  .mt-10,
  .mt-20 {
    // margin-top:3px !important;
  }

  .mt-15 {
    // margin-top:5px !important;
  }

  .small-text-font,
  .sub-text-font,
  .f-18 {
    // font-size:10px !important;
  }

  .f-20,
  .f-21,
  .sub-heading-font {
    // font-size:14px !important;
  }

  .f-24,
  .f-14 {
    // font-size:11px !important;
  }

  .pb-10 {
    // padding-bottom: 2px !important;
  }

  .pt-10 {
    // padding-top: 2px !important;
  }

  .mb-10 {
    // margin-bottom: 1px !important;
  }

  .btn {

    &.primary-button,
    &.secondary-button {
      //padding: 0px 25px !important;
      // font-size: 12px !important;
    }
  }

  .profileName {
    margin-left: 0px !important;

    .public-profile-resView {
      padding-top: 75px !important;
    }
  }

  .notification-align {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .job-manager-align {
    .form-control.input-group {
      height: auto !important;
    }
  }

  .contact-setting-align {
    width: auto !important;
  }

  .settings-align {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .password-eyeIcon {
    button {
      top: -3px !important;
      right: -3px !important;
    }
  }

  .bg-privacy {
    padding: 5px !important;
    width: 100% !important;

    .updated-policy {
      // display: flex !important;
    }
  }

  .privacy-font {
    font-size: var(--font-size-10) !important;
  }

  .onboard-align {
    // max-height: 100px !important;
    // overflow-y: auto !important;
  }

  .onboard-resume {
    //margin-top:260px !important;
  }

  .filter-job {
    .sticky-issue {
      position: static !important;
      padding-top: 40px !important;
    }
  }

  .find-jobs-sticky {
    position: static !important;
    padding-top: 40px !important;
  }

  .reset-res-align {
    position: relative;
    z-index: 1;
  }

  .nonsign-upload {
    // margin-top:20px !important;
  }

  .non-sign-footer {
    display: flex !important;
  }

  .input-otp {
    // margin-top:5px !important;
  }

  .otp-zoom {
    margin-top: 15px !important;
  }

  .onboard-modal-stepper {
    // max-height: 180px !important;
  }

  .modal-content {
    &.stepperview-modal {
      // width: 440px !important;
    }
  }

  .stepperview-modal {
    .non-signin-modal {
      #modal-description {
        max-height: max-content !important;
      }
    }
  }

  .password-icon {
    button {
      top: 2px !important;
    }
  }

  .profileName {
    padding-top: 90px;
  }

  .portfolio-zoom-align {
    margin-top: 5px !important;
  }

  .nonsignin-align {
    width: 100px !important;
  }

  .input-otp {
    input {
      width: 35px !important;
      padding: 0px !important;
    }
  }

  .nonsignin-otp-align {
    font-size: var(--font-size-10) !important;
  }

  .edit-skill-align {
    margin-top: 10px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 640px) {
  .policyCandidate {
    margin-top: 330px !important;
  }

  .social-login {
    padding-bottom: 24px !important;
  }

  .logoImg-header {
    height: 32px !important;
    padding: 0px !important;
  }

  .headerIcon {
    margin-left: 0px !important;
  }

  .message-header {
    margin-left: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    min-width: 20px !important;
  }

  .candidate-name-width {
    width: 50px !important;
    min-width: 65px !important;
    font-size: 9px !important;
  }

  .headerUser {
    justify-content: flex-start !important;
  }

  .search-border {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        // height: 0px !important;
      }
    }
  }

  .candidate-profile {
    width: 30px !important;
    height: 30px !important;
  }

  .menu-list {
    top: 60px !important;

    .MuiPaper-root {
      top: 60px !important;
    }

    .sideBar-icon {
      margin-top: 0px !important;
    }

    ul li {
      padding: 5px !important;
      line-height: 13px !important;

      svg {
        width: 13px !important;
        height: 13px !important;
      }

      label {
        font-size: 9px !important;
        margin-top: 0px !important;
      }
    }
  }

  .profile-btn {
    // margin-top: -32px !important;
    // font-size: 10px !important;
  }

  .setting-resolution {
    margin-bottom: 20px !important;
  }

  .profileSpacing {
    padding-top: 0em !important;
    height: 274px !important;
  }

  .modal-content {
    // width: 500px !important;
  }

  // #modal-description {
  //   max-height: 140px !important;
  // }

  .message-height {
    height: auto !important;
    margin-bottom: 20px !important;
  }

  .messagelist-resolution {
    width: auto !important;
  }

  .message-text {
    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .svmschat-btn-chat {
    bottom: 15px !important;
  }

  .global-search {
    ::placeholder {
      font-size: 8px !important;
    }
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        top: 60px !important;
        left: 160px !important;
      }
    }
  }

  .userDetails {
    display: block !important;
  }

  .jobTitle-align {
    margin-left: 60px !important;
  }

  .payrateSpace {
    margin-left: 20px !important;
  }

  .header-nav-align {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .education-modal-align {
    margin-top: 0px !important;
  }

  .header-side-align {
    margin-top: 10px !important;
  }

  .signin-zoom {
    //margin-top:140px !important;
  }

  .footer-view-align {
    //margin-bottom: 0px !important;
  }

  .profile-view-align {
    .public-profile-align {
      margin-top: -15px !important;
    }
  }

  .responsive-create-profile {
    display: contents !important;
  }

  .create-profile-container {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 140px) and (max-width: 512px) {
  .margin-top-label {
    margin-top: 20px !important;
  }

  .margin-right-header {
    margin-right: 20px !important;
  }

  .menu-list {
    top: 80px !important;
    display: none;
  }

  .navLogo {
    justify-content: start !important;
    padding-left: 2px;
    align-items: center !important;
    vertical-align: middle !important;
    display: flex !important;
  }

  .logoImg-header {
    width: 120px;
    height: 45px;
  }

  .zooming-issue {
    margin-top: 20px !important;
  }

  .sideBar-icon {
    margin-top: 0px !important;
  }

  .svmschat-btn-chat {
    bottom: 15px !important;
  }

  .zoom-notification {
    margin-top: 20px !important;
  }

  .modal-content {
    // width: 400px !important;
  }

  // #modal-description {
  //   max-height: 80px !important;
  // }

  .modalStyle {
    width: 270px !important;
    // max-height: 170px !important;
    overflow-y: auto !important;
  }

  .zoom-password {
    margin-top: 63px !important;
  }

  .message-text {
    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        // max-height: 70px !important;
        overflow-y: auto;
        top: 126px !important;
        left: 150px !important;
      }
    }
  }

  .nps-align {
    margin-right: 0px !important;
  }

  .jobTitle-align {
    margin-left: 60px !important;
  }

  .payrateFont {
    font-size: var(--font-size-12) !important;
  }

  .responsive-progress {
    left: 40px !important;
  }

  .zoomingIssue-cover {
    padding-top: 55px !important;
  }

  .profileSpacing {
    // height:270px !important;
  }

  .rating-zoomingIssue {
    // margin-bottom: 10px !important;
  }

  .percentage-zooming {
    // margin-left:12px !important;
    justify-content: center !important;
  }

  .zooming-sidebar {
    margin-left: 245px !important;
  }

  .zooming-auto {
    width: auto !important;
  }

  .overflow-hide {
    overflow: hidden !important;
  }

  .skills-modal-align {
    #modal-description {
      // max-height: 70px !important;
    }
  }

  .signin-zoom {
    //margin-top:230px !important;
  }

  .otp-zoom {
    margin-top: 80px !important;
  }

  .otp-btn-align {
    padding-bottom: 40px !important;
  }

  .forgotPassword-zoom {
    margin-top: 25px !important;
  }

  .responsive-create-profile {
    display: contents !important;
  }

  // .create-profile-container{
  //   padding-right: 0 !important;
  // }
}

@media screen and (max-height: 200px) {
  .policyCandidate {
    margin-top: 420px !important;
  }

  .pagenation-table {
    min-width: 100% !important;
  }

  .menu-list {
    overflow-y: auto !important;
    max-height: 100px !important;
    top: 54px !important;
  }

  .zooming-issue {
    margin-top: 5px !important;
  }

  .sideBar-icon {
    margin-top: 0px !important;
  }

  .navbar-fixed {
    padding: 0px !important;
  }

  .logoImg {
    // width: 140px !important;
    height: 50px !important;
  }

  .social-login {
    padding-bottom: 24px !important;
  }

  .svmschat-btn-chat {
    bottom: 0px !important;
    right: 27px !important;
  }

  .matching-tab .MuiTabs-root .MuiTabs-flexContainer {
    display: grid;
  }

  .modal-content,
  .modal-neuro {
    width: 300px !important;
  }

  .modal-content {
    &.stepperview-modal {
      width: 300px !important;
    }
  }

  .skills-modal-align {
    #modal-description {
      max-height: 70px !important;
    }
  }

  #modal-description {
    max-height: 90px !important;
  }

  .modalStyle {
    width: 270px !important;
    max-height: 130px !important;
    overflow-y: auto !important;
    padding: 10px !important;
  }

  .candidateTopmargin {
    .zoom-password {
      margin-top: 180px !important;
    }
  }

  .message-text {
    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .zoom-messages {
    font-size: var(--font-size-14) !important;
  }

  .analytics-report {
    .MuiPaper-root {
      .MuiList-root {
        max-height: 60px !important;
        overflow-y: auto;
        top: 54px !important;
      }
    }
  }

  .posted-align {
    display: block !important;
  }

  .payrateSpace {
    margin-left: 40px !important;
  }

  .zoomingIssue-cover {
    // padding-top:5px !important;
  }

  .profileSpacing {
    height: 340px !important;
  }

  .rating-zoomingIssue {
    margin-bottom: 0px !important;
  }

  .profile-btn {
    margin-top: -35px !important;
  }

  .address-align {
    width: auto !important;
  }

  .email-align {
    padding-left: 0px !important;
  }

  .edit-refer {
    margin-top: 20px !important;
  }

  .zooming-sidebar {
    // margin-left:235px !important;
  }

  .footer-view-align {
    margin-bottom: 0px !important;
  }

  .signin-zoom {
    margin-top: 300px !important;
  }

  .forgotPassword-zoom {
    margin-top: 75px !important;
  }

  .otp-zoom {
    margin-top: 150px !important;
  }

  .messageView-align {
    padding-top: 60px !important;
  }

  .input-otp input {
    margin-right: 5px !important;
  }

  .bg-privacy {
    padding: 3px !important;
    width: 100% !important;
  }

  .privacy-font {
    font-size: 5px !important;
  }

  .onboard-align {
    max-height: 80px !important;
    overflow-y: auto !important;
  }

  .onboard-resume {
    margin-top: 300px !important;
  }

  .stepper-tab-align .MuiBox-root {
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 412px) and (max-width: 915px) {
  .policyCandidate {
    margin-top: 80px !important;
  }

  .matching-tab .MuiTabs-root .MuiTabs-flexContainer {
    display: grid;
  }

  .matching-tab .MuiTabs-root .MuiTab-root {
    margin: 10px;
  }

  .menu-list .MuiPaper-root {
    max-height: 18em;
    overflow-y: auto !important;
  }

  .video-image {
    width: 30px !important;
    height: 30px !important;
  }

  .logoImg-header {
    height: 40px !important;
    padding: 0px !important;
  }

  .logoImg-header-nav {
    height: 40px !important;
  }
}

@media screen and (max-width: 480px) {
  .sideBar-icon {
    margin-top: 25px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1032px) {
  .menu-list {
    top: 110px !important;

    .MuiPaper-root {
      top: 110px !important;
    }
  }

  .sticky-issue {
    top: 112px !important;
  }

  .find-job-align {
    margin-top: 75px !important;
  }

  .message-icon-align {
    margin-left: 0px !important;
  }
}

// New media queries structure
@media screen and (min-width: 360px) and (max-width: 640px) {
  // .certificate-width-small {
  //   width: 160px !important;
  // }

  .responsive-settings {
    padding: 0 !important;
  }

  .settings-sidebar-fix {
    position: absolute;
    z-index: 0;
    width: 100% !important;

    .settings-sidebar {
      padding: 0 !important;
      // margin-right: 30px;
    }
  }

  .navLogo {
    padding-left: 10px !important;
  }

  .settings-body {
    margin-left: 0 !important;
    padding-top: 30em;
    border-left: 0;
    width: 100% !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 375px) and (max-width: 667px) {
  .responsive-settings {
    padding: 0 !important;
  }

  .settings-sidebar-fix {
    position: absolute;
    z-index: 0;
    width: 100% !important;

    .settings-sidebar {
      padding: 0 !important;
      // margin-right: 30px;
    }
  }

  .settings-body {
    margin-left: 0 !important;
    padding-top: 31em;
    border-left: 0;
    width: 100% !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 414px) and (max-width: 896px) {
  .responsive-settings {
    padding: 0 !important;
  }

  .settings-sidebar-fix {
    position: absolute;
    z-index: 0;
    width: 100% !important;

    .settings-sidebar {
      padding: 0 !important;
      // margin-right: 30px;
    }
  }

  .settings-body {
    margin-left: 0 !important;
    padding-top: 28em;
    border-left: 0;
    width: 100% !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1366px) {
  .create-file {
    .choose-file {
      width: 100% !important;
    }
  }

  .responsivegrid {
    // width: 100% !important;
  }
}

@media screen and (min-width: 864px) and (max-width: 1536px) {}

// @media screen and (min-width: 1080px) and (max-width: 1920px) {
// }

@media screen and (min-width: 404px) and (max-width: 1200px) {
  .responsive-settings {
    padding: 0 !important;
  }

  .settings-sidebar-fix {
    position: absolute;
    z-index: 0;
    width: 100% !important;

    .settings-sidebar {
      padding: 0 !important;
      // margin-right: 30px;
    }
  }

  .settings-body {
    margin-left: 0 !important;
    padding-top: 35em;
    border-left: 0;
    width: 100% !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 820px) and (max-width: 2560px) {
  .align-center-big-screen {
    align-items: center !important;
    justify-content: center !important;
    vertical-align: middle !important;
    display: flex !important;
  }
  .google-btn-margin {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.profileName {
  margin-left: 8em !important;
}

// Galaxy fold view
@media (max-width: 320px) {
  .galaxy-block {
    display: block !important;
  }
  .galaxy-center{
    display: flex;
    justify-content: center;
  }
  .responsive-column-reverse {
    flex-direction: column-reverse !important;
  }

  .find-job-search {
    border-radius: 8px !important;
    padding: 0px !important;
    margin-left: auto !important;
  }

  .find-job-input {
    padding: 0px !important;
  }

  .search-radius {
    border-radius: 8px !important;
    padding-right: 0px !important;
  }

  .location-shorten {
    .search-radius {
      border-radius: 8px !important;
    }
  }

  .left-side-section-image {
    display: none !important;
  }
  .neuro-diversity-background{
    background-image: none;
    .neurodiversity-outlet{
      width: 100% !important;
    }
  }

  .align-center-big-screen {
    .neuro-diversity-section {
      display: none !important;
    }
    .neuro-right {
      right: 0 !important;
    }
  }

  .galaxy-pb-50 {
    padding-bottom: 50px !important;
  }

  .galaxy-width {
    width: 100% !important;
  }

  .create-file {
    .galaxy-width {
      width: 100% !important;
    }
  }

  .fill-data-align {
    margin-left: 0px !important;
  }

  .onboard-skill-res {
    padding-right: 10px !important;
  }

  .detail-galaxy {
    margin-top: 10px !important;
  }
  .findJob-galaxy {
    margin-top: 30px !important;
  }
  .settings-body {
    padding-top: 37em !important;
  }

  .setting-mail-align {
    margin-top: 70px !important;
  }

  .setting-galaxy {
    padding-bottom: 25px !important;
  }

  // messages screen
  .resolution-view-galaxy {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  // myProfile screen
  .width-in-galaxy {
    width: 200px;
  }

  .sm-width-in-galaxy {
    width: 100px !important;
  }

  .galaxy-upload-resume {
    width: auto !important;
    margin-right: 4px !important;
  }

  .galaxy-margin-fix {
    margin-left: 0px !important;
  }

  .galaxy-padding-fix {
    padding-left: 0px !important;
  }

  .galaxy-padding-top {
    padding-top: 48px !important;
  }

  .galaxy-navbar-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .galaxy-justify {
    justify-content: center !important;
  }

  .responsive-checklist-modal {
    #modal-description {
      max-height: 390px !important;
      padding: 0px !important;
    }
  }

  .modal-content.checklist-modal {
    width: 90% !important;
  }

  .galaxy-margin {
    margin-bottom: 20px !important;
  }

  .pad-Leftgalaxy {
    padding-left: 6px !important;
  }

  .navBar ul {
    li {
      padding-right: 20px !important;
    }
  }

  .privacy-gal-pb {
    padding-bottom: 5px !important;
  }

  .input-otp {
    input {
      width: 30px !important;
      padding: 0px !important;
      margin-right: 15px !important;
    }
  }

  .MuiDialog-root {
    .MuiDialog-scrollPaper {
      .MuiPaper-root {
        min-width: 270px !important;
      }
    }
  }

  .MuiPickersBasePicker-pickerView {
    min-width: 250px !important;
  }

  .MuiPickersMonthSelection-container {
    width: 270px !important;
  }

  .galaxy-global {
    width: 130px !important;
  }

  .galaxy-share {
    width: 260px !important;
    margin-right: -15px !important;
  }

  .modal-content,
  .modal-neuro {
    width: 275px !important;
  }

  .galaxy-rating {
    margin-top: 2px !important;
  }

  .interview-list-galaxy {
    width: 245px !important;
  }

  .popover-galaxy {
    .MuiPaper-root.MuiPopover-paper:before {
      left: 80px !important;
    }
  }

  .dashboard-card {
    min-width: 5px;

    .responsive-img-offer {
      width: 25px;
      height: 25px;
    }

    .h3-text {
      font-size: var(--font-size-14) !important;
    }

    .supporting-text-large {
      font-size: 8px !important;
    }

    .galaxy-padding-top-20 {
      padding-top: 20px;
    }
  }
  .share-align{
    padding: 20px !important;
  }
  .job-title-align{
    width: 170px !important;
  }
  .apply-details{
    margin-left: 5px !important;
  }
  .galaxy-grid{
    display: grid;
  }
  .gal-word-break{
    word-break: break-word;
  }
  .share-job-align {
    .MuiInputBase-input {
      width: 175px !important;
    }
  }
  .search-view-align{
    padding-left: 30px !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 420px) {
  .mobile-sm-block{
    display: block !important;
  }
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .contrasts {
    filter: invert(0);
  }

  .inputcontrasts {
    border: white 1px solid !important;
  }

  .logocontrasts {
    filter: invert(1);
  }

  .socialLogin {
    filter: invert(10%);
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    border: 1px solid white !important;
    // border-bottom: 1px solid white !important;
  }

  .menu-list {
    top: 80px !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;

    .MuiPaper-root {
      top: 80px !important;
    }
  }

  .navbar-fixed {
    border-bottom: 1px solid white !important;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .contrasts {
    filter: invert(10%);
  }

  .inputcontrasts {
    border: black 1px solid !important;
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    border: 1px solid black !important;
    // border-bottom: 1px solid white !important;
  }

  .menu-list {
    top: 80px !important;
    border-right: 1px solid black !important;

    .MuiPaper-root {
      top: 80px !important;
    }
  }

  .navbar-fixed {
    border-bottom: 1px solid black !important;
  }
}
// 80%
@media screen and (min-width: 1423px) and (max-width: 1710px) {
  .greeting-apply{
    padding-bottom: 150px !important;
  }
  .referral-filter{
    max-height: 530px !important;
  }
  .question-view{
    height:550px !important;
  }
}
// iPad Air view
@media screen and (min-width: 820px) and (max-width: 850px) {
  .left-side-section-image {
    // display: none !important;
    position: fixed !important;
    height: 100vh !important;
    width: 40% !important;
  }
  .onboard-right-section{
    width: 60% !important;
  }
  .align-center-big-screen {
    .neuro-diversity-section {
      display: none !important;
    }
    .neuro-right {
      right: 0 !important;
    }
  }
  .neuro-diversity-background{
    background-image: none;
    .neurodiversity-outlet{
      width: 100% !important;
    }
  }
  body {
    padding-bottom: 8em !important;
  }
  .ipad-view {
    width: 100% !important;
  }
  .find-job-search {
    border-radius: 8px !important;
    padding: 0px !important;
    margin-left: auto !important;
  }
  .w-450 {
    width: auto;
  }
  .pl-35-r {
    padding-left: 35px !important;
  }
  .notification-drawer .MuiDrawer-paper {
    width: 50% !important;
  }
  .pl-pr-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .ml-n8 {
    margin-left: -8px !important;
  }
  // .pl-24ipad {
  //   padding-left: 24px !important;
  // }
  .pl-0ipad {
    padding-left: 0px !important;
  }
  .p-relative-ipad {
    position: relative;
  }
  .onboard-view{
      display: flex !important;
  }
  .pr-0ipad{
    padding-right: 0px !important;
  }
  .p-10ipad{
    padding: 10px !important;
  }
  .m-0ipad{
    margin: 0px !important;
  }
  .mt-10ipad{
    margin-top: 10px !important;
  }
  .justify-endipad{
    justify-content: end !important;
  }
  .dblock-ipad{
    display: block !important;
  }
}

// iPad Mini
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .left-side-section-image {
    display: block !important;
    position: fixed !important;
    height: 100vh !important;
    width: 40% !important;
    padding: 30px 10px !important;
  }
  .onboard-right-section{
    width: 60% !important;
    padding: 30px 20px !important;
  }
  .create-stepper{
    .MuiStepConnector-root{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .ml-10ipadm{
    margin-left: 10px !important;
  }
  .w-60ipad{
    width: 60% !important;
  }
  .pr-0ipad{
    padding-right: 0px !important;
  }
  .google-btn-margin {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .profilePhoto {
    top: 12px !important;
  }
  .filter-list {
    margin: 0px auto !important;
  }
  // .joblist-position {
  //   height: 570px !important;
  // }
  .candidate-name-width {
    min-width: 30px !important;
    max-width: 55px;
  }
  .ipad-mini-align {
    width: auto !important;
  }
  .pl-0mini {
    padding-left: 0px !important;
  }
  .ipad-job-filter{
    max-width: 35% !important;
  }
  .ipad-job-detail{
    max-width: 65% !important;
  }
  .pl-20mini {
    padding-left: 20px !important;
  }
  .justify-centeripad{
    justify-content: center !important;
  }
  .ipad-fixwidth{
    width: 250px !important;
  }
  .modal-content.checklist-modal {
    width: 720px !important;
  }
  .ipad-mb40{
    margin-bottom: 40px !important;
  }
}

// iPad Pro
@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .user-greet{
    width: 300px !important;
  }
  .ipad-drawer-title{
    width: 330px !important;
  }
  .ipad-nav-align{
    min-width: 50px !important;
  }
  .ipad-search-align{
    min-width: 34px !important;
  }
  .ipad-flex{
    display: flex !important;
  }
  .ipad-block{
    display: block !important;
  }
  .ipad-w-100{
    width: 100% !important;
  }
  .ipad-min-w-100{
    min-width: 100% !important;
  }
  .refer-align{
      display: flex !important;
      justify-content: end !important;
  }
  .ipad-h-100{
    height: 100vh !important;
  }
  .login-onboard-bg{
    position: fixed !important;
    width: 45%!important;
    height: auto !important;
  }
  .onboard-section{
    width: 55% !important;
  }
  .align-flex-start{
    align-items: flex-start !important;
  }
  .skill-sec-align{
    margin: 0px !important;
  }
  .job-scroll-align{
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .myjob-new-tab .profile-tabs button{
    margin-right: 30px !important;
  }
  .notification-drawer .MuiDrawer-paper{
    width: 55% !important;
  }
  .pending-action{
    margin-top: 10px !important;
  }
  .nav-menu{
    padding: 0px !important;
  }
  .public-profile-photo{
    top: 80px !important;
  }
  .settings-sidebar-fix{
    position: fixed !important;
    width: 40% !important;
    z-index: 2 !important;
  }
  .settings-body{
    width: 60% !important;
    padding-top: 0px !important;
    padding-right: 20px !important;
  }
}
// 100%
@media screen and (min-width: 600px) and (max-width: 1281px) {
  .question-view{
    height:405px !important;
  }
}
// 90%
@media screen and (min-width: 1281px) and (max-width: 1423px) {
    .greeting-apply{
      padding-bottom: 150px !important;
    }
    .my-custom-popper {
      width: 31.6% !important;
    }
    .question-view{
      height:475px !important;
    }
    .messages-chat-align {
      padding-right: 415px !important;
    }
    .messages-chat-popper-align {
      padding-right: 405px !important;
    }
}
// 80%
@media screen and (min-width: 1423px) and (max-width: 1705px) {
  .referral-filter{
    max-height: 530px !important;
  }
    .my-custom-popper {
      margin-top: 6px;
      width: 31.7% !important;
    }
    .question-view{
      height:550px !important;
    }
    .messages-chat-align {
      padding-right: 460px !important;
    }
    .messages-chat-popper-align {
      padding-right: 420px !important;
    }
}

// 75%
@media screen and (min-width: 1706px) and (max-width: 1720px) {
  .messages-chat-align {
    padding-right: 490px !important;
  }
  .messages-chat-popper-align {
    padding-right: 425px !important;
  }
}
