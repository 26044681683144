.content-editable {
  box-shadow: 0 1px 1px 0 rgb(60 64 67 / 8%), 0 1px 3px 1px rgb(60 64 67 / 16%);
  float: right;
  background-color: #FFFFFF;
  min-width: 350px;
  border-radius: 8px;
  padding: 20px;
  /*position: fixed; !* Stay in place *!*/
  z-index: 1; /* Sit on top */
  top: 0;
  overflow: auto;
  right: 0;
  /* dragging-css*/
  width: 100px;
  cursor: move;
  p{
    margin: 0;
  }
}
.editable-option-list{
  font-size: var(--font-size-20);
  margin: 5px 18px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  display: flex;
  color: #262626;
}



.content-editable-footer{
  border-top: 1px solid #262626;
  span{
    padding: 20px;
  }
}

.slider-editor {
  color: var(--primary-color) !important;
  height: 10px !important;
  & .MuiSlider-track {
    border: none;
  }

  & .MuiSlider-thumb {
    height: 24px !important;
    width: 24px !important;
    background-color: #fff !important;
    border: 4px solid currentColor;

    &:focus, &:hover, &.Mui-active, &.Mui-focusVisible {
      box-shadow: inherit;
    }

    &:before {
      display: none;
    }
  }

  & .MuiSlider-valueLabel {
    line-height: 1.2;
    font-size: var(--font-size-12);
    padding: 0;
    width: 32px;
    height: 3px;
    border-radius: 50% 50% 50% 0;
    background-color: var(--primary-color);
    transform-origin: bottom left;
    transform: translate(50%, -100%) rotate(-45deg) scale(0);
    &:before {
      display: none;
    }
    &.MuiSlider-valueLabelOpen {
      transform: translate(50%, -100%) rotate(-45deg) scale(1);
    }
    /* & > *: {
       transform: rotate(45deg);
     }*/
  }
}

.editor-font-section{
  padding: 0 10px;
  margin: 0 !important;
}

.editor-input {
  font-size: var(--font-size-20) !important;
  margin-left: 12px !important;
  &:before{
    border-bottom: none !important;
    outline: none !important;
  }
}

/*editable sections*/
.editable-header-section{
  .content-editable{
    margin: 25%;
  }
}
.editable-navbar-section{
  .content-editable{
    margin: 17%;
    top: -109px;
  }
}
.editable-card-section{
  .content-editable{
    margin: 30%;
    top: -109px;
  }
}

.editable-testimonial-section{
  .content-editable{
    margin: 30%;
    top: -217px;
  }
}

.css-1oqqzyl-MuiContainer-root{
  padding: 0 !important;
}

.upload-image{
  border: 1px solid #262626;
 /* background-color: #EAE9F2;*/
  border-radius: 25px;
  padding: 8px;
  font-size: var(--font-size-14);
  position: absolute;
  margin: -7em 6em;
  color: #262626;
  svg{
    margin-bottom: -6px;
    color: #262626;
    font-size: 19px;
  }
}


.draggable-section{
  &:hover {
    border: 2px dashed var(--hover-button-colour);
    /*padding: 0 !important;*/
    .draggable-button {
      display: block;
      height: 2px;
      margin-bottom: 0;
      z-index: 9;
    }
  }
}

  .image-editor :hover{
    border: 1px solid #262626;
  }

  .popover_class{
    font-family: var(--font-family) !important;
  }

.draggable-button{
  border: 1px solid #262626;
  color: #262626;
  background-color: #FFFFFF;
  font-size: var(--font-size-14);
  padding: 5px 12px;
  margin: 10px 0;
  text-transform: none;
  box-shadow: none;
  font-weight: bold;
  border-radius: 8px;
  font-family: var(--font-family);
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  display: flex;
}
.dragDrop-section + .draggable-button{
    display: none;
    &:hover{
      display: block;
    }
}

  .dragDrop-section{
      &:hover{
        border: 2px dashed var(--hover-button-colour);
    }
  }

@media screen and (min-width: 300px) and (max-width: 767px){
  .radio-button-class .MuiRadio-root-11 {
    margin-right: -15px;
  }
  .radio-button-class .MuiFormControlLabel-label-10 {
    margin: 24px 6px;
    img{
      width: 300px;
    }
  }
}
